<template>
<div class="bg-white rounded-md w-full shadow-md mb-5">
    <div class="cardHead grid grid-cols-10 p-4">
        <div class="icon text-left">
            <svg class=" w-4 pt-0 "  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g data-name="Layer 2">
                    <g data-name="person">
                        <rect width="24" height="24" opacity="0"/>
                        <path fill="url(#table-gradient)" d="M12 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2z"/>
                        <path fill="url(#table-gradient)" d="M12 13a7 7 0 0 0-7 7 1 1 0 0 0 2 0 5 5 0 0 1 10 0 1 1 0 0 0 2 0 7 7 0 0 0-7-7z"/>
                    </g>
                </g>
            </svg>   
        </div>
        <div v-if="!myTeam" class="name text-left col-span-8 font-bold">{{ name }}</div>
        <div v-if="myTeam" class="name text-left col-span-8 font-bold primary-gradient clip-text">{{ name }}</div>
        <div class="position text-right grid grid-cols-2 gap-1">
            <div v-if="shield && live && !notStarted" class="shield">
                <svg class="w-4 pt-0 m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <g data-name="Layer 2">
                        <g data-name="shield">
                            <rect width="24" height="24" opacity="0"/>
                            <path fill="url(#table-gradient)" d="M12 21.85a2 2 0 0 1-1-.25l-.3-.17A15.17 15.17 0 0 1 3 8.23v-.14a2 2 0 0 1 1-1.75l7-3.94a2 2 0 0 1 2 0l7 3.94a2 2 0 0 1 1 1.75v.14a15.17 15.17 0 0 1-7.72 13.2l-.3.17a2 2 0 0 1-.98.25zm0-17.7L5 8.09v.14a13.15 13.15 0 0 0 6.7 11.45l.3.17.3-.17A13.15 13.15 0 0 0 19 8.23v-.14z"/>
                        </g>
                    </g>
                </svg>
            </div>
            <span v-if="live && !notStarted">{{ position }}<sup v-if="live && !notStarted">{{ sup }}</sup></span>
            
        </div>
    </div>
    
    <div class="players grid grid-cols-6 gap-2 pl-5 mt-3">
        <team-player-list-card v-if="myTeam || showCard1 || teamStatus == 'withdrawn' || teamStatus == 'missedCut'" @playerClick="playerClick" :image="player1img" :playerId="player1Id" :team="true" :live="live" :lastRoundBegun="lastRoundBegun" :teamStatus="teamStatus" />
        <player-list-card-back v-if="!myTeam && !showCard1 && teamStatus != 'withdrawn' && teamStatus != 'missedCut'"/>
        <team-player-list-card v-if="myTeam || showCard2 || teamStatus == 'withdrawn' || teamStatus == 'missedCut'" @playerClick="playerClick" :image="player2img" :playerId="player2Id" :team="true" :live="live" :lastRoundBegun="lastRoundBegun" :teamStatus="teamStatus" />
        <player-list-card-back v-if="!myTeam && !showCard2 && teamStatus != 'withdrawn' && teamStatus != 'missedCut'"/>
        <team-player-list-card v-if="myTeam || showCard3 || teamStatus == 'withdrawn' || teamStatus == 'missedCut'" @playerClick="playerClick" :image="player3img" :playerId="player3Id" :team="true" :live="live" :lastRoundBegun="lastRoundBegun" :teamStatus="teamStatus" />
        <player-list-card-back v-if="!myTeam && !showCard3 && teamStatus != 'withdrawn' && teamStatus != 'missedCut'"/>
        <team-player-list-card v-if="myTeam || showCard4 || teamStatus == 'withdrawn' || teamStatus == 'missedCut'" @playerClick="playerClick" :image="player4img" :playerId="player4Id" :team="true" :live="live" :lastRoundBegun="lastRoundBegun" :teamStatus="teamStatus" />
        <player-list-card-back v-if="!myTeam && !showCard4 && teamStatus != 'withdrawn' && teamStatus != 'missedCut'"/>
    </div>

    <div class="cardFooter grid grid-cols-2 pl-5 pr-3 mt-5 pb-3">
        <div class="text-left text-xs ">
            <!-- Depricated - where Rank Buster team comparison figure was presented  -->
            <!-- <span v-if="live && (myTeam || !notStarted) && !isScoreBusterTable" >Rank Buster +{{ owgr }} </span> -->
            <span v-if="myTeam && notStarted" @Click="editTeamClick" class="text-right font-normal text-xs primary-gradient clip-text">Edit Team</span>
        </div>
        <div v-if="live && (myTeam || !notStarted)" class="text-right text-xs ">
            TOTAL 
            <span v-if="total > 0"> +{{ total }} </span>
            <span v-if="!total || total == 0"> E </span>
            <span v-if="total < 0"> {{ total }} </span>
        </div>
        

    </div>

</div>
</template>

<script>


import TeamPlayerListCard from '../../Base/TeamPlayerListCard.vue'
import { mapState } from 'vuex'
import PlayerListCardBack from '../../Base/PlayerListCardBack.vue';


export default {
    name: "TeamListCard",
    props: {
        name: String,
        userId: Number,
        position: Number,
        shield: Boolean,
        sup: String,
        owgr: Number,
        total: Number,
        player1img: String,
        player1Id: Number,
        player2img: String,
        player2Id: Number,
        player3img: String,
        player3Id: Number,
        player4img: String,
        player4Id: Number,
        live: Boolean,
        notStarted: Boolean,
        teamStatus: String

    },

    emits: ["playerClick", "editTeamClick"],

    computed: {
        ...mapState({
            me_id: state => state.auth.user.id,
            eventStatus: state => state.eventStatus,
            currentTableId: state => state.currentTableId,
            tables: state => state.tables,
            event: state => state.event
        }),

        myTeam () {
            if(this.userId == this.me_id){
                return true;
            }
            else {
                return false;
            } 
        },

        showCard1(){
            // If R1 table - start of second round - last round begun >= 1 
            // If R2 table - start of second round - last round begun >= 2 
            // If R3 table - start of third round - last round begun >= 3 
            // If R4 table - start of forth round - last round begun >= 4 
            if(this.currentTableStartingRound == 1){
                if(this.lastRoundBegun >= 1 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 2){
                if(this.lastRoundBegun >= 2 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 3){
                if( this.lastRoundBegun >= 3 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 4){
                if( this.lastRoundBegun >= 4 ){ return true; }
                else { return false; }
            }
        },

        showCard2(){
            // If R1 table - start of second round - last round begun >= 2 
            // If R2 table - start of second round - last round begun >= 2 
            // If R3 table - start of third round - last round begun >= 3 
            // If R4 table - start of forth round - last round begun >= 4 
            if(this.currentTableStartingRound == 1){
                if(this.lastRoundBegun >= 2 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 2){
                if(this.lastRoundBegun >= 2 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 3){
                if( this.lastRoundBegun >= 3 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 4){
                if( this.lastRoundBegun >= 4 ){ return true; }
                else { return false; }
            }
        },
        


        showCard3(){
            // If R1 table - start of third round - last round begun >= 3 
            // If R2 table - start of third round - last round begun >= 3 
            // If R3 table - start of third round - last round begun >= 3 
            // If R4 table - start of forth round - last round begun >= 4 
            if(this.currentTableStartingRound == 1){
                if( this.lastRoundBegun >= 3 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 2){
                if( this.lastRoundBegun >= 3 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 3){
                if( this.lastRoundBegun >= 3 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 4){
                if( this.lastRoundBegun >= 4 ){ return true; }
                else { return false; }
            }
        },

        showCard4(){
            // If R1 table - start of forth round - last round begun == 4  
            // If R2 table - start of forth round - last round begun == 4  
            // If R3 table - start of forth round - last round begun == 4  
            // If R4 table - start of forth round - last round begun == 4 
            if(this.currentTableStartingRound == 1){
                if( this.lastRoundBegun >= 4 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 2){
                if( this.lastRoundBegun >= 4 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 3){
                if( this.lastRoundBegun >= 4 ){ return true; }
                else { return false; }
            }
            if(this.currentTableStartingRound == 4){
                if( this.lastRoundBegun >= 4 ){ return true; }
                else { return false; }
            }
        },

        countTables(){
            return Object.keys(this.tables).length;
        },

        currentTableStartingRound(){
            let tableStartingRound = 1;
            this.tables.forEach(item => {
            if(item.id == this.currentTableId){
                tableStartingRound = item.starting_round;
            }
            });
            return tableStartingRound;
        },

        lastRoundBegun() {
            if(this.isPastStart(this.event.round4_start)){ return 4; }
            else if(this.isPastStart(this.event.round3_start)){ return 3; }
            else if(this.isPastStart(this.event.round2_start)){ return 2; }
            else if(this.isPastStart(this.event.round1_start)){ return 1; }
            else { return null; }
        },

        isScoreBusterTable(){
            // Get Table by currentTableId
            let tableStartingRound = '';
            this.tables.forEach(item => {
            if(item.id == this.currentTableId){
                tableStartingRound = item.starting_round;
            }
            });
            if(tableStartingRound == 1){
            return false;
            }
            else{
            return true;
            }
        },
    },

    components: {
        TeamPlayerListCard,
        PlayerListCardBack,
    },
    methods: {
        playerClick: function(playerId){
            this.$emit('playerClick', playerId);
            console.log(this.eventStatus);
        },

        editTeamClick: function(){
            this.$emit('editTeamClick');
        },

        haveTableStartingXRound: function(round){
            // as there is 1 table for each starting round, we can use countTables
            let tableCount = Number(this.countTables);
            if(tableCount >= round ){
                return true;
            }
            else {
                return false;
            }
        },

        isPastStart: function(dateTimeString){
            if(dateTimeString) { // make sure we have a start time from Sports Data
                let today = new Date();
                let dateTime = this.makeNewDate(dateTimeString);
                if (dateTime < today){ return true; }
                else { return false; }
            }
            else {
                return false;
            }
        },

        makeNewDate: function(dateTimeString) {
            // *** Fix Safari Date() compatability ***
            // recieved format 2021-11-18 14:30:00
            // this.makeNewDate format yyyy, mm-1, dd, hh, mm, ss
            // returns newDate from string
            if(dateTimeString){
                if (typeof dateTimeString === 'string' || dateTimeString instanceof String){
                    if(dateTimeString.includes("-")){
                        // process date
                    
                        const dateArray = dateTimeString.split("-");
                        const timeDateArray = dateArray[2].split(" ");
                        const timeArray = timeDateArray[1].split(":");
                        
                        let yyyy = dateArray[0];
                        let month = dateArray[1];
                        let monthLessOne = parseInt(month) -1;
                        let dd = timeDateArray[0];
                        let hh = timeArray[0];
                        let mm = timeArray[1];
                        let ss = timeArray[2];

                        
                        return new Date(yyyy, monthLessOne, dd, hh, mm, ss);
                    }
                    else {
                        // return now
                        return new Date();
                    }
                }
                else {
                    // return now
                    return new Date();
                }
            }
            else {
                // return now
                return new Date();
            }

        }
    }
}
</script>

<style>

</style>