<template>
    <main-header backShow="true" accountShow="true" @backEvent="backClick" @myAccountEvent="myAccountClick"/>
  <div class="bg-white rounded-md w-full shadow-md " >
      <div class="grid grid-cols-2 p-3">
            <div class="w-full p-2 pr-5 pb-5">
                <img class="rounded-md" :src="player_photo" alt="">
            </div>
            <div class="w-full text-left p-3 pl-1">
                <h2 class="font-bold mb-3 primary-gradient clip-text">{{ player_name }}</h2>
                <p class="mb-2 text-xs"><strong>Born: </strong> {{ player_born}}</p>
                <p class="mb-2 text-xs"><strong>Age: </strong> {{ player_age }}</p>
                <p class="mb-2 text-xs"><strong>Nationality: </strong>{{ player_country }}</p>
                <p class="mb-2 text-xs"><strong>OWGR: </strong>{{ player_owgr }}</p>
            </div>
      </div>

      <div class="grid grid-cols-4 w-full p-5 pt-0">
          
          <div v-if="roundScore < 0 && thruProcessed != '--'" class="text-left"><p>{{ roundScore }}</p></div>
          <div v-if="roundScore > 0 && thruProcessed != '--'" class="text-left"><p>+{{ roundScore }}</p></div>
          <div v-if="roundScore == 0 && thruProcessed != '--'" class="text-left"><p>E</p></div>
          <div v-if="thruProcessed == '--'" class="text-left"><p> -- </p></div>
          <div v-if="thruProcessed != '--'" class="text-centre"><p>{{ thruProcessed }}</p></div>
          <div v-if="thruProcessed == '--'" class="text-centre"><p>{{ teetime }}</p></div>
          <div v-if="newTotalScore < 0" class="text-center"><p>{{ newTotalScore }}</p></div>
          <div v-if="newTotalScore > 0" class="text-center"><p>+{{ newTotalScore }}</p></div>
          <div v-if="newTotalScore == 0" class="text-center"><p>E</p></div>
          <div class="text-right font-bold"><p>{{ newPlayerPosition }}<sup class="font-normal">{{ newPlayerSup }}</sup></p></div>
          
          <div class="text-left mt-1"><p class=" text-xs">R{{lastRoundBegun}}</p></div>
          <div class="text-center mt-1">
            <p v-if="thruProcessed != '--'" class=" text-xs">THRU</p>
            <p v-if="thruProcessed == '--'" class=" text-xs">TEE</p>
          </div>
          <div class="text-center mt-1"><p class=" text-xs">TOTAL</p></div>
          <div class="text-right mt-1"><p class=" text-xs">POSITION</p></div>
      </div>

      <div class="grid grid-cols-4 text-left p-5 pt-2 gap-2">
          <div @click="round1Click" :class="{activeRound: round1Show}" class="inactiveRound w-full p-3 pt-4 pb-4">
              <p class="font-bold text-xs">1<sup class="font-normal">st</sup></p>
              <p class="text-xs mt-1">Round</p>
          </div>
          <div @click="round2Click" :class="{activeRound: round2Show}" class="inactiveRound w-full p-3 pt-4 pb-4">
              <p class="font-bold text-xs">2<sup class="font-normal">nd</sup></p>
              <p class="text-xs mt-1">Round</p>
          </div>
          <div @click="round3Click" :class="{activeRound: round3Show}" class=" inactiveRound w-full  p-3 pt-4 pb-4">
              <p class="font-bold text-xs">3<sup class="font-normal">rd</sup></p>
              <p class="text-xs mt-1">Round</p>
          </div>
          <div @click="round4Click" :class="{activeRound: round4Show}" class=" inactiveRound w-full p-3 pt-4 pb-4">
              <p class="font-bold text-xs">4<sup class="font-normal">th</sup></p>
              <p class="text-xs mt-1">Round</p>
          </div>
      </div>

      <div class="w-full p-5">
          <div class="w-full bg-gray-100 grid grid-cols-3 p-5 gap-1 text-center mb-10">
              <div class="bg-white border-solid border-white border "><p class="text-xs">HOLE</p></div>
              <div class="bg-white border-solid border-white border "><p class="text-xs">PAR</p></div>
              <div class="bg-white border-solid border-white border "><p class="text-xs">SCORE</p></div>
              <player-performance-score :hole="1" :par=hole_1_par :score=hole_1_score />
              <player-performance-score :hole="2" :par=hole_2_par :score=hole_2_score />
              <player-performance-score :hole="3" :par=hole_3_par :score=hole_3_score />
              <player-performance-score :hole="4" :par=hole_4_par :score=hole_4_score />
              <player-performance-score :hole="5" :par=hole_5_par :score=hole_5_score />
              <player-performance-score :hole="6" :par=hole_6_par :score=hole_6_score />
              <player-performance-score :hole="7" :par=hole_7_par :score=hole_7_score />
              <player-performance-score :hole="8" :par=hole_8_par :score=hole_8_score />
              <player-performance-score :hole="9" :par=hole_9_par :score=hole_9_score />

              <div class="bg-white border-solid border-white border "><p class="text-xs"></p></div>
              <div class="bg-white border-solid border-white border "><p class="text-xs">OUT</p></div>
              <div class="bg-white border-solid border-white border "><p class="text-xs">{{ round_out }}</p></div>
            
              <player-performance-score :hole="10" :par=hole_10_par :score=hole_10_score />
              <player-performance-score :hole="11" :par=hole_11_par :score=hole_11_score />
              <player-performance-score :hole="12" :par=hole_12_par :score=hole_12_score />
              <player-performance-score :hole="13" :par=hole_13_par :score=hole_13_score />
              <player-performance-score :hole="14" :par=hole_14_par :score=hole_14_score />
              <player-performance-score :hole="15" :par=hole_15_par :score=hole_15_score />
              <player-performance-score :hole="16" :par=hole_16_par :score=hole_16_score />
              <player-performance-score :hole="17" :par=hole_17_par :score=hole_17_score />
              <player-performance-score :hole="18" :par=hole_18_par :score=hole_18_score />

              <div ></div>
              <div class="bg-white border-solid border-white border "><p class="text-xs">IN</p></div>
              <div class="bg-white border-solid border-white border "><p class="text-xs">{{ round_in }}</p></div>
              <div ></div>
              <div class="bg-white border-solid border-white border "><p class="text-xs">TOTAL</p></div>
              <div class="bg-white border-solid border-white border "><p class="text-xs">{{ round_total }}</p></div>
          </div>
      </div>


  </div>
</template>

<script>
import PlayerPerformanceScore from './Elements/PlayerPerformanceScore.vue';
import MainHeader from '../Base/MainHeader.vue';
import { mapGetters, mapActions, mapState } from 'vuex';

export default {
    components: { MainHeader, PlayerPerformanceScore },
    name: "PlayerPerformanceCard",
    props: {
        backItem: String,
        playerId: Number, 
        player_name: String,
        player_photo: String,
        player_birth_date: String,
        player_country: String,
        player_owgr: Number,
        player_position: Number,
        player_sup: String,
        player_total_score: Number,
        player_total_thru: Number,
    },
    emits: ["backEvent", "myAccountEvent"],
    data () {
        return {
            round1Show: true,
            round2Show: false,
            round3Show: false,
            round4Show: false,

            hole_1_par: null,
            hole_2_par: null,
            hole_3_par: null,
            hole_4_par: null,
            hole_5_par: null,
            hole_6_par: null,
            hole_7_par: null,
            hole_8_par: null,
            hole_9_par: null,
            hole_10_par: null,
            hole_11_par: null,
            hole_12_par: null,
            hole_13_par: null,
            hole_14_par: null,
            hole_15_par: null,
            hole_16_par: null,
            hole_17_par: null,
            hole_18_par: null,

            hole_1_score: null,
            hole_2_score: null,
            hole_3_score: null,
            hole_4_score: null,
            hole_5_score: null,
            hole_6_score: null,
            hole_7_score: null,
            hole_8_score: null,
            hole_9_score: null,
            hole_10_score: null,
            hole_11_score: null,
            hole_12_score: null,
            hole_13_score: null,
            hole_14_score: null,
            hole_15_score: null,
            hole_16_score: null,
            hole_17_score: null,
            hole_18_score: null,

            round_in: null,
            round_out: null,
            round_total: null,

            activeRound: 1
        }
    },
    mounted (){
        let currentRound = this.lastRoundBegun;
        if(currentRound == 1){this.round1Show = true; this.round2Show = false; this.round3Show = false; this.round4Show = false;}
        if(currentRound == 2){this.round1Show = false; this.round2Show = true; this.round3Show = false; this.round4Show = false;}
        if(currentRound == 3){this.round1Show = false; this.round2Show = false; this.round3Show = true; this.round4Show = false;}
        if(currentRound == 4){this.round1Show = false; this.round2Show = false; this.round3Show = false; this.round4Show = true;}
        this.scoreCard(currentRound);
    },

    computed: {

        ...mapState([
            'event'
        ]),

        player_born(){
            var birthDate = new Date(this.player_birth_date);
            var month = birthDate.toLocaleString('default', { month: 'short' })
            var year = birthDate.getFullYear();
            
            return month+' '+year;
        },

        player_age(){
            var today = new Date();
            var birthDate = new Date(this.player_birth_date);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },

        thruProcessed(){
            // If finished round - performances exist with NULL scores if not happend 
            let performedThisRound = false;
            this.performances.forEach(item => {
                if (item['score']){
                    performedThisRound = true;
                }
            });
            if(performedThisRound && this.newTotalThru < 1){
                return 'F';
            }
            else if (this.newTotalThru > 0 ){
                return this.newTotalThru;
            }
            // else if (this.newTotalThru == null){
            //     return "null";
            // }
            else {
                return '--';
            }
        },

        teetime(){
            let teetime =  this.GET_TEETIME_BY_PLAYER_AND_ROUND()(this.playerId)(this.lastRoundBegun);
            const d = this.makeNewDate(teetime[0].teetime);
            let hh = d.getHours();
            let mm = d.getMinutes();
            if(mm < 10 ){ mm = '0'+mm; }

            return hh+":"+mm;
        },

        performances(){
            return this.GET_PERFORMANCES_BY_PLAYER_AND_ROUND()(this.playerId)(this.lastRoundBegun);
        },

        roundScore(){
            let total = 0;
            this.performances.forEach(item => {

                if (item['score']){
                    total += (item['score'] - item['par']);
                }
            });

            return total;
        },

        newTotalScore(){
            let player = this.GET_LEADERBOARD_BY_PLAYER()(this.playerId);
            return player[0]['total_score'];
        },

        newTotalThru(){
            let player = this.GET_LEADERBOARD_BY_PLAYER()(this.playerId);
            return player[0]['total_thru'];
        },

        newPlayerPosition(){
            let player = this.GET_LEADERBOARD_BY_PLAYER()(this.playerId);
            return player[0]['position'];
        },

        newPlayerSup(){
            let player = this.GET_LEADERBOARD_BY_PLAYER()(this.playerId);
            return player[0]['sup'];
        },


        lastRoundBegun() {
            if(this.isPastStart(this.event.round4_start)){ return 4; }
            else if(this.isPastStart(this.event.round3_start)){ return 3; }
            else if(this.isPastStart(this.event.round2_start)){ return 2; }
            else if(this.isPastStart(this.event.round1_start)){ return 1; }
            else { return null; }
        },
    },

    watch: {
        // whenever performances changes, this function will run
        performances(newP, oldP) {
        this.scoreCard(this.lastRoundBegun);
        }
    },

    methods: {
        ...mapGetters([
            'GET_PERFORMANCES_BY_PLAYER_AND_ROUND',
            'GET_TEETIME_BY_PLAYER_AND_ROUND',
            'GET_LEADERBOARD_BY_PLAYER'
        ]),

        isPastStart: function(dateTimeString){
            if(dateTimeString) { // make sure we have a start time from Sports Data
                let today = new Date();
                let dateTime = this.makeNewDate(dateTimeString);
                if (dateTime < today){ return true; }
                else { return false; }
            }
            else {
                return false;
            }
        },

        scoreCard: function(round){
            console.log('round : '+round);
            let _player_id = this.playerId;
            let performances = this.GET_PERFORMANCES_BY_PLAYER_AND_ROUND()(_player_id)(round);

            let r_in = 0;
            let r_out = 0;
            let r_total = 0;

            // clear score card before loading data 
            this.clearScoreCard();

            // for each performance write par and score for that round
            performances.forEach(item => {
                if(item['hole'] == 1) { this.hole_1_par = item['par']; this.hole_1_score = item['score']; r_out += item['score']; r_total += item['score'];}
                else if(item['hole'] == 2) { this.hole_2_par = item['par']; this.hole_2_score = item['score']; r_out += item['score']; r_total += item['score'];}
                else if(item['hole'] == 3) { this.hole_3_par = item['par']; this.hole_3_score = item['score']; r_out += item['score']; r_total += item['score'];}
                else if(item['hole'] == 4) { this.hole_4_par = item['par']; this.hole_4_score = item['score']; r_out += item['score']; r_total += item['score'];}
                else if(item['hole'] == 5) { this.hole_5_par = item['par']; this.hole_5_score = item['score']; r_out += item['score']; r_total += item['score'];}
                else if(item['hole'] == 6) { this.hole_6_par = item['par']; this.hole_6_score = item['score']; r_out += item['score']; r_total += item['score'];}
                else if(item['hole'] == 7) { this.hole_7_par = item['par']; this.hole_7_score = item['score']; r_out += item['score']; r_total += item['score'];}
                else if(item['hole'] == 8) { this.hole_8_par = item['par']; this.hole_8_score = item['score']; r_out += item['score']; r_total += item['score'];}
                else if(item['hole'] == 9) { this.hole_9_par = item['par']; this.hole_9_score = item['score']; r_out += item['score']; r_total += item['score'];}
                else if(item['hole'] == 10) { this.hole_10_par = item['par']; this.hole_10_score = item['score']; r_in += item['score']; r_total += item['score'];}
                else if(item['hole'] == 11) { this.hole_11_par = item['par']; this.hole_11_score = item['score']; r_in += item['score']; r_total += item['score'];}
                else if(item['hole'] == 12) { this.hole_12_par = item['par']; this.hole_12_score = item['score']; r_in += item['score']; r_total += item['score'];}
                else if(item['hole'] == 13) { this.hole_13_par = item['par']; this.hole_13_score = item['score']; r_in += item['score']; r_total += item['score'];}
                else if(item['hole'] == 14) { this.hole_14_par = item['par']; this.hole_14_score = item['score']; r_in += item['score']; r_total += item['score'];}
                else if(item['hole'] == 15) { this.hole_15_par = item['par']; this.hole_15_score = item['score']; r_in += item['score']; r_total += item['score'];}
                else if(item['hole'] == 16) { this.hole_16_par = item['par']; this.hole_16_score = item['score']; r_in += item['score']; r_total += item['score'];}
                else if(item['hole'] == 17) { this.hole_17_par = item['par']; this.hole_17_score = item['score']; r_in += item['score']; r_total += item['score'];}
                else if(item['hole'] == 18) { this.hole_18_par = item['par']; this.hole_18_score = item['score']; r_in += item['score']; r_total += item['score'];}
            }); 

            this.round_in = r_in;
            this.round_out = r_out;
            this.round_total = r_total;
        },

        backClick: function() {
            let originator = this.backItem;
            this.$emit('backEvent', originator);
        },

        myAccountClick: function(){
          let originator = this.backItem;
          this.$emit("myAccountEvent", originator);
        },

        round1Click: function(){
            this.round1Show = true;
            this.round2Show = false;
            this.round3Show = false;
            this.round4Show = false;

            this.scoreCard(1);
        },
        round2Click: function(){
            this.round1Show = false;
            this.round2Show = true;
            this.round3Show = false;
            this.round4Show = false;

            this.scoreCard(2);
        },
        round3Click: function(){
            this.round1Show = false;
            this.round2Show = false;
            this.round3Show = true;
            this.round4Show = false;

            this.scoreCard(3);
        },
        round4Click: function(){
            this.round1Show = false;
            this.round2Show = false;
            this.round3Show = false;
            this.round4Show = true;

            this.scoreCard(4);
        },

        clearScoreCard: function(){
            this.hole_1_score = null;
            this.hole_2_score = null;
            this.hole_3_score = null;
            this.hole_4_score = null;
            this.hole_5_score = null;
            this.hole_6_score = null;
            this.hole_7_score = null;
            this.hole_8_score = null;
            this.hole_9_score = null;
            this.hole_10_score = null;
            this.hole_11_score = null;
            this.hole_12_score = null;
            this.hole_13_score = null;
            this.hole_14_score = null;
            this.hole_15_score = null;
            this.hole_16_score = null;
            this.hole_17_score = null;
            this.hole_18_score = null;


        },

        makeNewDate: function(dateTimeString) {
            // *** Fix Safari Date() compatability ***
            // recieved format 2021-11-18 14:30:00
            // this.makeNewDate format yyyy, mm-1, dd, hh, mm, ss
            // returns newDate from string
            if(dateTimeString){
                if (typeof dateTimeString === 'string' || dateTimeString instanceof String){
                    if(dateTimeString.includes("-")){
                        // process date
                    
                        const dateArray = dateTimeString.split("-");
                        const timeDateArray = dateArray[2].split(" ");
                        const timeArray = timeDateArray[1].split(":");
                        
                        let yyyy = dateArray[0];
                        let month = dateArray[1];
                        let monthLessOne = parseInt(month) -1;
                        let dd = timeDateArray[0];
                        let hh = timeArray[0];
                        let mm = timeArray[1];
                        let ss = timeArray[2];

                        
                        return new Date(yyyy, monthLessOne, dd, hh, mm, ss);
                    }
                    else {
                        // return now
                        return new Date();
                    }
                }
                else {
                    // return now
                    return new Date();
                }
            }
            else {
                // return now
                return new Date();
            }

        }
    }

}
</script>

<style scoped>

    img {

            /* heavy blue  */
            /* filter: grayscale(1.0) brightness(1) sepia(1) hue-rotate(170deg) saturate(2); */

            /* gentle blue  */
            /* filter: grayscale(1.0) brightness(1.1) sepia(1) hue-rotate(170deg) saturate(.8); */

            /* grey */
            filter: grayscale(1.0) brightness(1) ;

            /* faded colour */
            filter: brightness(1) ;

            /* Low saturation */
            /* filter: saturate(.8);  */

            opacity: .5;
        }

        .birdie {
            /* background-image: url(/assets/images/circle.svg); */
            background-image: url('data:image/svg+xml,<svg height="40" width="40"><circle cx="20" cy="20" r="8" stroke="black" stroke-width="1" fill="none"/></svg>');
            background-position: centre;
            background-repeat: no-repeat;
            background-color: lightcoral;
        }

</style>