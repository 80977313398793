<template>
<main-header accountShow="true" @myAccountEvent="myAccountClick"/>
    <h1>{{ event }}</h1>
    <event-progress />
    
    <player-leader-card v-for="item in board"  :load="log(item)"
          :key="item.id" 
          :player_name="item.name" 
          :position="item.position" 
          :shield="0" 
          :owgr="item.owgr" 
          :birth_date="item.birth_date" 
          :country="item.country" 
          :sup="item.sup " 
          :total="item.total_score" 
          :thru="item.total_thru" 
          :playerImg="item.player_img" 
          :playerId="item.player_id" 
          
          @playerClick="playerClick"
    />
    
    <div class="mb-20"></div>
</template>

<script>
import PlayerLeaderCard from './Elements/PlayerLeaderCard.vue';
import PlayerListCard from '../Base/PlayerListCard.vue';
import MainHeader from '../Base/MainHeader.vue';
import EventProgress from '../Base/EventProgress.vue';

import { mapActions, mapState} from 'vuex'

export default {
    components: { MainHeader, PlayerListCard, PlayerLeaderCard, EventProgress },
    name: "TableLeaderBoard",
    props:{
      event: String
    },
    emits: ["myAccountEvent", "playerEvent"],
    data(){
      return {
        limit: 5
      }
    },
    mounted(){
      
      setTimeout(() => { this.limit = null; }, 10);
      
    },

    computed: {
      ...mapState([
        'leaderBoard',
        'eventStatus'
      ]),

      board: function(){
        return this.limit ? this.leaderBoard.slice(0,this.limit) : this.leaderBoard;
      },

      noLeaderboard: function(){
        if (Object.keys( this.leaderBoard ).length == 0){
          return true;
        }
        else {
          return false;
        }
      }
    },

    methods: {
      log: function(item){
        // console.log('Item - '+item.country);
        
      },

      playerClick: function(playerId){
          let originator = "leaderBoard";
          this.$emit("playerEvent", {playerId, originator });
        },

      myAccountClick: function(){
        this.$emit("myAccountEvent", "leaderBoard");
      }
    },

    
}
</script>

<style>

</style>