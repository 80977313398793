
<template>
<div>
<!-- <h1>Welcome</h1> -->
    <p>Please enter your 4 digit pin code to complete your login. </p>
    
    <div class="grid grid-cols-4 gap-2">
        <input type="text" class="mini-input" id="loginPin1" v-model="code_1" placeholder=""/>
        <input type="text" class="mini-input" id="loginPin2" v-model="code_2" placeholder=""/>
        <input type="text" class="mini-input" id="loginPin3" v-model="code_3" placeholder=""/>
        <input type="text" class="mini-input" id="loginPin4" v-model="code_4" placeholder=""/>
    </div>
    <form action="" @submit.prevent="login">
        <Button type="submit" btnText="SUBMIT" class="mt-10"/>
    </form>
</div>
</template>

<script>
    import Button from '@/components/Base/Button.vue'

    export default {
        name: 'SignIn',
        components: {
            Button,
        },
        computed: {
            code_1:{
                set(newValue){
                    return this.$store.dispatch("auth/set_credentials_code_1", newValue);
                },
                get(){
                    return this.$store.getters["auth/credentials_code_1"];
                }
            },

            code_2:{
                set(newValue){
                    return this.$store.dispatch("auth/set_credentials_code_2", newValue);
                },
                get(){
                    return this.$store.getters["auth/credentials_code_2"];
                }
            },

            code_3:{
                set(newValue){
                    return this.$store.dispatch("auth/set_credentials_code_3", newValue);
                },
                get(){
                    return this.$store.getters["auth/credentials_code_3"];
                }
            },

            code_4:{
                set(newValue){
                    return this.$store.dispatch("auth/set_credentials_code_4", newValue);
                },
                get(){
                    return this.$store.getters["auth/credentials_code_4"];
                }
            },

        },

        methods: {
            login: function(){

                // make credentials
                let code_1 = this.$store.getters["auth/credentials_code_1"];
                let code_2 = this.$store.getters["auth/credentials_code_2"];
                let code_3 = this.$store.getters["auth/credentials_code_3"];
                let code_4 = this.$store.getters["auth/credentials_code_4"];
                let phone = this.$store.getters["auth/credentials_phone"];

                let password = `${code_1}${code_2}${code_3}${code_4}`;
                console.log(`creds - phone = ${phone} - pass = ${password}`);

                let credentials = {
                    phone: phone,
                    password: password,
                };
                
                // attempt login
                this.$store.dispatch("auth/manualLogin", credentials);
                

                
                
            }    
        } 
}
</script>

<style>

</style>