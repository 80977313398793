<template>
  <div class="container">
    <div class="p-12 text-center " >
      <svg id="Logo-big" data-name="Logo-big" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 604.09 109.23">
            <path class="logo-path" d="M2.45,303.77V234l37.69-.13c0,3,0,6,0,9.08l-31.46.47q2.64-2.58,5.3-5.1-.22,13.07-.44,26.15l-5.18-1.25q14.25-1.19,28.52-2.25c0,3,0,5.92,0,8.88q-14.25,1.33-28.48,2.83c1.72-.84,3.45-1.66,5.18-2.48q.23,15.76.45,31.49Q8.19,302.7,2.45,303.77Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M65.65,293.3q.28-13.74.58-27.46-.09-16-.19-32.08l11.7,0q-.26,12.79-.49,25.61l.06,32.21Q71.47,292.4,65.65,293.3Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M108.35,287.51l.49-53.86,15.2,0c8.33,13.91,16.66,27.07,25,39.66l.4,0q-.49-19.78-1-39.68l11,0L159,282.22q-7.56.67-15.12,1.42c-8.34-12.56-16.67-25.7-25-39.59h-.4q.45,21.13.9,42.17Q113.84,286.86,108.35,287.51Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M180.29,280.47c6.53-16.14,13.05-31.76,19.55-47h15.5q9.38,22.35,18.81,43.8-6,.25-11.94.56-2.66-6.7-5.31-13.47l6.2,2.5q-15.75.6-31.48,1.42c2-1.06,4.09-2.1,6.14-3.13q-2.77,7.17-5.54,14.46Q186.27,280,180.29,280.47Zm17.37-15.58-3.88-3.25q13.58-.57,27.16-1L217,264.1q-5.2-13.4-10.39-27.13l6.37,3.25-10.9.11c2.14-1.14,4.29-2.26,6.43-3.37Q203.1,250.73,197.66,264.89Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M255.74,276.49q.09-21.53.2-43.06h11.6q-.33,20-.65,39.95l-5.2-3.32q15.6-.42,31.21-.57c0,2.11,0,4.21,0,6.32Q274.32,275.93,255.74,276.49Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M315.09,275.8l.48-19.91q-.17-11.23-.32-22.47l3.5-.21c1.79-.1,3.89-.2,6.29-.28s4.85-.15,7.35-.21,4.79-.09,6.85-.09A54.49,54.49,0,0,1,352.05,234a16.71,16.71,0,0,1,8.32,4.39,11.38,11.38,0,0,1,2.95,8.15,10.36,10.36,0,0,1-3.66,8.45c-2.46,2.07-6.06,3.46-10.8,4.2v.25q7.8,8.61,15.64,17.47-6.58-.24-13.16-.43-6.7-8.35-13.41-16.53l-11.13-.3q0,8.16,0,16.32C322.92,275.86,319,275.83,315.09,275.8ZM340,254.18c4.54,0,7.55-.55,9-1.8s2.19-3.26,2.18-6a11.33,11.33,0,0,0-.93-5.15,4.54,4.54,0,0,0-2.41-2.26,13.72,13.72,0,0,0-3.05-.59,43.06,43.06,0,0,0-4.66-.23c-2.73,0-5.27.05-7.6.16s-4.27.24-5.8.36q0,7.46-.07,14.91c2.21.14,4.46.27,6.77.39S338,254.15,340,254.18Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M398,279.33a56.65,56.65,0,0,1-10.64-1.6,27.71,27.71,0,0,1-8.91-3.92,17.89,17.89,0,0,1-6.06-7.19,26.82,26.82,0,0,1-2.23-11.48,25.57,25.57,0,0,1,2.08-11.26,16.71,16.71,0,0,1,5.91-6.87,24.69,24.69,0,0,1,8.83-3.46,56,56,0,0,1,10.64-1,52.87,52.87,0,0,1,10.66,1,23.66,23.66,0,0,1,8.79,3.65,17.94,17.94,0,0,1,6,7.38,29.83,29.83,0,0,1,2.26,12.13c.05,4.95-.63,9-2,12.09a15.6,15.6,0,0,1-5.9,7,22.25,22.25,0,0,1-8.74,3.09A52.26,52.26,0,0,1,398,279.33Zm0-6.63a32.61,32.61,0,0,0,6.86-.27,9.37,9.37,0,0,0,4.74-2.09,9.82,9.82,0,0,0,2.81-5,33.52,33.52,0,0,0,.88-8.93,36.39,36.39,0,0,0-1-8.93,11.25,11.25,0,0,0-2.8-5.2,9.86,9.86,0,0,0-4.82-2.43,40.51,40.51,0,0,0-14.06-.13,9.67,9.67,0,0,0-4.74,2.28A9.87,9.87,0,0,0,383.2,247a34.48,34.48,0,0,0-.79,8.62,33.2,33.2,0,0,0,1,8.64,9.68,9.68,0,0,0,7.62,7.52A37.46,37.46,0,0,0,398,272.7Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M458.75,284a43.84,43.84,0,0,1-13.28-3.15,17.59,17.59,0,0,1-8.38-7.33c-1.94-3.3-2.93-7.72-3-13.33q-.14-13.35-.26-26.68l11.6,0q.12,12.66.25,25.34a54.59,54.59,0,0,0,.69,9,11.79,11.79,0,0,0,2.05,5.2,7.58,7.58,0,0,0,3.88,2.56,30.88,30.88,0,0,0,6.36,1.07,28.18,28.18,0,0,0,6.45-.06,6.2,6.2,0,0,0,3.93-2,10.22,10.22,0,0,0,1.9-5.1,63.27,63.27,0,0,0,.45-9.37q-.12-13.29-.26-26.59l11.5,0q.14,14.28.28,28.55c.06,6.4-.84,11.29-2.71,14.61a13.2,13.2,0,0,1-8.18,6.51A36.47,36.47,0,0,1,458.75,284Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M495.24,286.88q-.24-26.62-.49-53.24l15.2,0c8.59,14.72,17.18,30.27,25.73,46.83l.4,0q-.87-23.48-1.73-46.8l11,0q.18,29.89.38,59.8-7.53-1.11-15.05-2.16c-8.56-16.6-17.14-32.21-25.73-47h-.4q.84,21.82,1.68,43.79Q500.74,287.51,495.24,286.88Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M579.15,300.19c-1.78-.31-3.79-.7-6-1.17s-4.39-.95-6.51-1.44-4-.95-5.62-1.36l-3.34-.83q-.17-30.84-.32-61.68c.6,0,1.71-.05,3.35-.16l5.79-.4c2.24-.14,4.55-.28,7-.4s4.57-.18,6.5-.18c5.53,0,10,.89,13.56,2.75a20,20,0,0,1,8.1,7.71,33.13,33.13,0,0,1,3.89,11.38,84.26,84.26,0,0,1,1,13.56c0,9.27-1.18,16.49-3.52,21.52s-5.57,8.3-9.67,9.84S584.52,301.12,579.15,300.19Zm-.32-9.36a26.51,26.51,0,0,0,6.85.37,8.53,8.53,0,0,0,3.48-1.1,9.83,9.83,0,0,0,2.43-3.48,28,28,0,0,0,1.87-7.32,88.08,88.08,0,0,0,.73-12.94A78.82,78.82,0,0,0,593,252q-1.15-6.09-3.76-8.07a7.55,7.55,0,0,0-2.85-1.37,26.77,26.77,0,0,0-6.24-.67c-2.93-.07-5.43,0-7.49.1s-3.87.27-5.4.41q.84-2.41,1.68-4.86l.24,55.86q-1-2.66-1.92-5.26,2.49.61,5.08,1.35A51.57,51.57,0,0,0,578.83,290.83Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M160.34,341.07V304.78h6l-.15,17.16L163.51,320h19.24l-2.65,1.93-.16-17.16h6v36.29h-6l.16-17.83,2.65,2H163.51l2.71-2,.15,17.83Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M227.73,341.8a18.1,18.1,0,0,1-5.51-.83,11.11,11.11,0,0,1-4.6-2.89,13.7,13.7,0,0,1-3.12-5.77,33.46,33.46,0,0,1-1.12-9.49,32.75,32.75,0,0,1,1.12-9.39,14.06,14.06,0,0,1,3.09-5.72,10.64,10.64,0,0,1,4.61-2.86,19,19,0,0,1,5.53-.8,18.64,18.64,0,0,1,5.54.8,10.68,10.68,0,0,1,4.55,2.86,14.07,14.07,0,0,1,3.1,5.72,33.15,33.15,0,0,1,1.11,9.39,33.86,33.86,0,0,1-1.11,9.49,14,14,0,0,1-3.1,5.77,11,11,0,0,1-4.55,2.89A18,18,0,0,1,227.73,341.8Zm0-5.36a11.25,11.25,0,0,0,3.56-.49,4.92,4.92,0,0,0,2.48-1.87,10.36,10.36,0,0,0,1.48-4.06,40.68,40.68,0,0,0,.49-7.1,42.63,42.63,0,0,0-.47-7,10.42,10.42,0,0,0-1.43-4.08,4.7,4.7,0,0,0-2.49-1.9,12.06,12.06,0,0,0-3.67-.5,11.77,11.77,0,0,0-3.64.5,4.73,4.73,0,0,0-2.47,1.9,10.13,10.13,0,0,0-1.4,4.08,44.77,44.77,0,0,0-.44,7.1,42.86,42.86,0,0,0,.46,7.07,10.2,10.2,0,0,0,1.43,4,4.77,4.77,0,0,0,2.5,1.87A11.81,11.81,0,0,0,227.73,336.44Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M269.44,341.07V304.78h6l-.26,33.9-2.71-2.91,16.18-.11v5.41Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M324.4,341.8c-.94,0-2,0-3.15-.08s-2.29-.12-3.4-.21-2.09-.17-2.94-.26l-1.74-.18V304.72c.31,0,.89,0,1.74-.1l3-.23c1.16-.09,2.36-.17,3.61-.24s2.37-.1,3.38-.1a14.69,14.69,0,0,1,7.05,1.48,10.62,10.62,0,0,1,4.21,4,17.19,17.19,0,0,1,2,5.88,43.66,43.66,0,0,1,.54,7c0,4.81-.61,8.64-1.84,11.46a12.31,12.31,0,0,1-5.07,6.06A14.91,14.91,0,0,1,324.4,341.8Zm-.16-5.2a13.33,13.33,0,0,0,3.59-.36,5.22,5.22,0,0,0,1.82-.89,6.6,6.6,0,0,0,1.28-2.08,17.63,17.63,0,0,0,1-4.08,50.49,50.49,0,0,0,.39-7,43.73,43.73,0,0,0-.59-7.67q-.6-3.25-1.95-4.29a3.83,3.83,0,0,0-1.49-.71,13.25,13.25,0,0,0-3.25-.28q-2.28,0-3.9.15c-1.07.11-2,.21-2.8.32l.88-2.81v32l-1-2.86c.87.1,1.75.22,2.65.36A22.62,22.62,0,0,0,324.24,336.6Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M366.16,341.07V304.78h19.6V310l-16.28-.21,2.81-2.65-.41,15.28-2.66-2.28h15.24v5H369.22l2.66-2.44.41,16-2.81-2.7,16.28-.11v5.2Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M412.64,341.07l1.3-36.29h8.11l7.28,22.36h.32L437,304.78h8.06l1.25,36.29h-6l-.11-29.85h-.36l-7,21.74h-6.76l-7-21.74h-.31l-.16,29.85Z" transform="translate(-2.45 -232.57)"/>
        </svg>
      <Main v-if="mainLoad" @loginClickEvent="login" @registerClickEvent="register"/>
      <NoPlay />
      <Register v-if="registerLoad" @registerSubmitEvent="registered"/>
      <RegisterThankYou v-if="registerThankYouLoad"/>
      <RegisterThankYouKnown v-if="registerThankYouKnownLoad" event="The Masters" @viewTableEvent="viewTable"/>
      <SignIn v-if="loginLoad"/>

    </div>
  </div>
</template>

<script>
import Main from '@/components/Welcome/Main.vue'
import NoPlay from '@/components/Welcome/NoPlay.vue'
import Register from '@/components/Welcome/Register.vue'
import RegisterThankYou from '@/components/Welcome/RegisterThankYou.vue'
import RegisterThankYouKnown from '@/components/Welcome/RegisterThankYouKnown.vue'
import SignIn from '@/components/Welcome/SignIn.vue'


export default {
  components: {
    Main,
    NoPlay,
    Register,
    RegisterThankYou,
    RegisterThankYouKnown,
    SignIn
  },

  data () {
    return {
      registerClicked: false,
      registerSubmitClicked: false,
      loginClicked: false,
      registerKnown: true
    }
  },

  computed: {
    mainLoad() {
      let register = this.registerClicked;
      let login = this.loginClicked;

      if(register === false && login === false) {
        return true;
      }
      else {
        return false;
      }
    },

    registerLoad(){
      let register = this.registerClicked;
      let registered = this.registerSubmitClicked;
      if(register === true && registered === false){
        return true;
      }
      else {
        return false;
      }
    },

    loginLoad(){
      let login = this.loginClicked;
      if(login === true){
        return true;
      }
      else {
        return false;
      }
    },

    registerThankYouLoad() {
      let registered = this.registerSubmitClicked;
      let known = this.registerKnown;
      if(registered === true && known === false){
        return true;
      }
      else {
        return false;
      }
    },

    registerThankYouKnownLoad() {
      let registered = this.registerSubmitClicked;
      let known = this.registerKnown;
      if(registered === true && known === true){
        return true;
      }
      else {
        return false;
      }
    }

  },

  methods: {
    login: function() {
      this.loginClicked = true;
      console.log('login clicked');
    },

    register: function() {
      this.registerClicked = true;
      console.log('register clicked');
    },

    registered: function() {
      this.registerSubmitClicked = true;
      console.log('register submit clicked');
    },

    viewTable: function() {
      this.$router.push('table');
    }
  }
}
</script>

<style scoped>
  #Logo-big{
    margin-top:50px;
    margin-bottom: 100px;
  }

</style>
