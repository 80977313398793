<template> 
  <router-view/>
</template>

<script>
import { mapActions, mapState} from 'vuex'

export default {
  components: {
  },
  created() {
    this.start();
  },

  computed: mapState({
        authenticatedState: state => state.auth.authenticated
  }),

  methods: {
    ...mapActions({
          loginAction: 'auth/login'
      }),

    start: function(){
      console.log('app.vue - on start method call');
    }
  }
}
</script>


<style src="./assets/styles/app.css" />
