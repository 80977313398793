<template>
<div>
  <div class="readyOrInProgressEvent">
      <h1 v-if="winner" class="mt-10">Winner: {{ winner }}</h1>
      <!-- Rank Buster -->
      <div class="bg-white rounded-md w-full mb-4 mt-0">
        <div class="cardHead grid grid-cols-9 p-4">
            <div class="icon col-span-2">
                <svg class="staticIcon w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <g data-name="Layer 2">
                    <g data-name="trending-up">
                      <rect width="24" height="24" transform="rotate(-90 12 12)" opacity="0"/>
                      <path fill="url(#table-gradient)" d="M21 7a.78.78 0 0 0 0-.21.64.64 0 0 0-.05-.17 1.1 1.1 0 0 0-.09-.14.75.75 0 0 0-.14-.17l-.12-.07a.69.69 0 0 0-.19-.1h-.2A.7.7 0 0 0 20 6h-5a1 1 0 0 0 0 2h2.83l-4 4.71-4.32-2.57a1 1 0 0 0-1.28.22l-5 6a1 1 0 0 0 .13 1.41A1 1 0 0 0 4 18a1 1 0 0 0 .77-.36l4.45-5.34 4.27 2.56a1 1 0 0 0 1.27-.21L19 9.7V12a1 1 0 0 0 2 0V7z"/></g></g></svg>
            </div>
            <div class="col-span-6 ">
                <p class="text-left font-normal">{{ buster }}</p>
                <p class="text-left"><span v-if="starting_round == 1">RANK</span><span v-if="starting_round != 1">SCORE</span> BUSTER</p>
            </div>
            <div @click="infoClick" class="icon col-span-1 pt-2">
              <svg class="staticIcon w-6 rotate" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g data-name="Layer 2">
                  <g data-name="alert-circle">
                    <rect width="24" height="24" opacity="0"/>
                    <path fill="url(#table-gradient)" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
                    <circle fill="url(#table-gradient)" cx="12" cy="16" r="1"/>
                    <path fill="url(#table-gradient)" d="M12 7a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1z"/></g></g></svg>
            </div>
        </div>
      </div>

      <transition name="fade">
        <div v-if="showRankBusterText" class="transition w-full text-center mb-4" >
          <p v-if="starting_round == 1" class="text-xs">Each team of 4 players must have a combined OWGR above the Rank Buster.</p>
          <p v-if="starting_round != 1 && starting_round != 4" class="text-xs">Each team of 4 players must have a combined score at the start of round {{ starting_round }} below the Score Buster.</p>
          <p v-if="starting_round == 4" class="text-xs">Each team of 4 players must have a combined score at the start of the final round below the Score Buster.</p>
        </div>
      </transition>

      <!-- Entry Fee` -->
      <div class="bg-white rounded-md w-full mb-4">
        <div class="cardHead grid grid-cols-9 p-4">
            <div class="icon col-span-2 pt-1">
                <svg class="staticIcon w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <g data-name="Layer 2">
                    <g data-name="checkmark-circle">
                      <rect width="24" height="24" opacity="0"/>
                      <path fill="url(#table-gradient)" d="M9.71 11.29a1 1 0 0 0-1.42 1.42l3 3A1 1 0 0 0 12 16a1 1 0 0 0 .72-.34l7-8a1 1 0 0 0-1.5-1.32L12 13.54z"/>
                      <path fill="url(#table-gradient)" d="M21 11a1 1 0 0 0-1 1 8 8 0 0 1-8 8A8 8 0 0 1 6.33 6.36 7.93 7.93 0 0 1 12 4a8.79 8.79 0 0 1 1.9.22 1 1 0 1 0 .47-1.94A10.54 10.54 0 0 0 12 2a10 10 0 0 0-7 17.09A9.93 9.93 0 0 0 12 22a10 10 0 0 0 10-10 1 1 0 0 0-1-1z"/></g></g></svg>
            </div>
            <div class="col-span-7 ">
                <p class="text-left font-normal">£{{ entry_fee }}</p>
                <p class="text-left">ENTRY FEE</p>
            </div>
            
        </div>
      </div>

      <!-- Pot` -->
      <div class="bg-white rounded-md w-full mb-10">
        <div class="cardHead grid grid-cols-9 p-4">
            <div class="icon col-span-2 pt-1">
                <svg class="staticIcon w-8" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <g data-name="Layer 2">
                    <g data-name="trash">
                      <rect fill="url(#table-gradient)" width="24" height="24" opacity="0"/>
                      <path fill="url(#table-gradient)" d="M21 6h-5V4.33A2.42 2.42 0 0 0 13.5 2h-3A2.42 2.42 0 0 0 8 4.33V6H3a1 1 0 0 0 0 2h1v11a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V8h1a1 1 0 0 0 0-2zM10 4.33c0-.16.21-.33.5-.33h3c.29 0 .5.17.5.33V6h-4zM18 19a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V8h12z"/></g></g></svg>
            </div>
            <div class="col-span-7 ">
                <p class="text-left font-normal">£{{ pot }}</p>
                <p class="text-left">POT</p>
            </div>
            
        </div>
      </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "TableEventContent",
  props:{
    winner: String,
    pot: Number,
    buster: Number,
    entry_fee: Number,
    starting_round: Number
  },

  data (){
      return {
        showRankBusterText: false
      }
  },

  computed: {

    ...mapState({
        tables: state => state.tables
    }),

    multiTable(){
      if( Object.keys(this.tables).length > 1){
        return true;
      }
      else {
        return false;
      }
      
    }
  },

  methods: {
      infoClick: function(){
        this.showRankBusterText = !this.showRankBusterText;
      },
  }
}
</script>

<style scoped>

  .rotate {
    transform: rotate(180deg);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s ease;
  }

  .fade-enter-from,
  .fade-leave-to {
    opacity: 0;
  }
</style>