<template>
<div class="bg-white rounded-md w-full shadow-md mb-5">
    <div class="cardHead grid grid-cols-10 p-4">
        <div class="icon text-left">
            <svg class=" w-4 pt-0 "  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g data-name="Layer 2">
                    <g data-name="person">
                        <rect width="24" height="24" opacity="0"/>
                        <path fill="url(#table-gradient)" d="M12 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2z"/>
                        <path fill="url(#table-gradient)" d="M12 13a7 7 0 0 0-7 7 1 1 0 0 0 2 0 5 5 0 0 1 10 0 1 1 0 0 0 2 0 7 7 0 0 0-7-7z"/>
                    </g>
                </g>
            </svg>   
        </div>
        <div class="name text-left col-span-9">{{ name }}</div>
    </div>

    <div  class="players grid grid-cols-6 gap-2 pl-5 mt-3">
        <div v-for="item in players" :key="item.id">
            <player-summary-card @playerClick="playerClick" :selected="true" :image="item.photo" :playerId="item.id" :owgr="item.owgr" :isScoreBusterTable="isScoreBusterTable"/>
       </div>
    </div>

    <div class="cardFooter grid grid-cols-1 pl-5 pr-3 mt-5 pb-3">
        <div class="text-right text-xs">
            <span v-if="!isScoreBusterTable">OWGR TOTAL </span>
            <span v-if="isScoreBusterTable">TOTAL </span>
            {{ teamRankOrScoreTotal }} </div>

    </div>

</div>
</template>

<script>


import { mapState, mapGetters } from 'vuex'
import PlayerSummaryCard from '@/components/ClaimSeat/PlayerSummaryCard.vue';


export default {
    name: "SummaryTeamCard",

    emits: ["playerClick", "editTeamClick"],

    computed: {
      ...mapState({
        me_id: state => state.auth.user.id,
        name: state => state.auth.user.name,
        eventStatus: state => state.eventStatus,
        tables: state=> state.tables,
        leaderBoard: state => state.leaderBoard,
        currentTableId: state=> state.currentTableId
      }),
      
      ...mapGetters([
        'GET_ENTRYLIST_BY_SELECTED'
       ]),

       tableBuster(){
        // Get Table by currentTableId
        let tableBuster = '';
        this.tables.forEach(item => {
          if(item.id == this.currentTableId){
            tableBuster = item.buster;
          }
        });
            return tableBuster;
        },

        isScoreBusterTable(){
            // Get Table by currentTableId
            let tableStartingRound = '';
            this.tables.forEach(item => {
            if(item.id == this.currentTableId){
                tableStartingRound = item.starting_round;
            }
            });
            if(tableStartingRound == 1){
            return false;
            }
            else{
            return true;
            }
        },

       players: function(){
           return this.GET_ENTRYLIST_BY_SELECTED;
       },

       teamRankOrScoreTotal: function(){
            let teamRankOrScoreTotal = 0;
            let selected = this.GET_ENTRYLIST_BY_SELECTED;
            let isScoreBuster = this.isScoreBusterTable;
            let leaderBoard = this.leaderBoard;

            if(selected.length > 0){
                selected.forEach(item => {
                    console.log('SELECTED - '+JSON.stringify(item.id));
                    
                    if(isScoreBuster){
                        let playerLeaderBoard = leaderBoard.find(x => x.player_id === item.id);
                        if(playerLeaderBoard){
                            let score = playerLeaderBoard.total_score;
                            teamRankOrScoreTotal += parseInt(score);
                        }
                    }
                    else {
                        teamRankOrScoreTotal += parseInt(item.owgr);
                    }
                });
            }
            
            return teamRankOrScoreTotal;
       }
    },

    components: {
        PlayerSummaryCard
    },
    methods: {
        playerClick: function(playerId){
            this.$emit('playerClick', playerId);
            console.log(this.eventStatus);
        },

        editTeamClick: function(){
            this.$emit('editTeamClick');
        }
    }
}
</script>

<style>

</style>