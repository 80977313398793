<template>
    <input id="{{ id }}" name="{{ id }}" type="text" placeholder= "{{ placeholder }}" class="rounded focus:outline-none focus:ring focus:border-blue-2 text-blue-3 placeholder-blue-3 w-full mt-6 p-2 text-sm border border-blue-2 border-solid">
    
</template>

<script>
export default {
    name: 'InputField',
    model: {
        prop: [
            'id',
            'placeholder',
        ]
    }
}
</script>

<style scoped>
    input::placeholder {
        text-align: right;
        margin-right: 5px;
        font-size: 12px;
    }

</style>