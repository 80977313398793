<template>
  <div class="container text-center">
    <div class="linear-gradient-holder">
        <svg class="" xmlns="http://www.w3.org/2000/svg" width="0" height="0" viewBox="0 0 0 0">
            <linearGradient x1="0" y1="0" x2="100%" y2="100%" id="table-gradient">
                <stop class="primary--gradient-stop1" offset="0"/>
                <stop class="primary--gradient-stop1" offset="0.2"/>
                <stop class="primary--gradient-stop2" offset="0.5"/>
                <stop class="primary--gradient-stop3" offset="1"/>
            </linearGradient>     
        </svg>
    </div>
    <player-selector v-if="PlayerSelectorIsActive" @playerEvent="playerView" @confirmTeamEvent="confirmTeamCompute" :isScoreBusterTable="isScoreBusterTable" :tableBuster="tableBuster" :event="currentEvent" :players="tablePlayers" :hasSeat="hasSeat" />
    <player-details-card v-if="PlayerDetailsCardIsActive" @playerFromTeamEvent="removeFromTeamCompute" @playerToTeamEvent="addToTeamCompute" @back="playerSelectorView" :event="currentEvent" :playerData="currentPlayer" />
    <same-team-as-other-player-at-your-table v-if="SameTeamAsOtherPlayerAtYourTableIsActive" @confirmTeamEvent="teamConfirmed" @changeTeamEvent="playerSelectorView" :event="currentEvent"/>
    <summary-team-confirm v-if="SummaryTeamConfirmIsActive" :event="currentEvent" @confirmTeamEvent="teamConfirmed"/>
    <transition name="fade" >
      <toast v-if="ToastActive" :toastText="toastText" /> 
      <!-- v-if="ToastActive" -->
    </transition>
  </div>

</template>

<script>
import Toast from '../components/Base/Toast.vue'

import PlayerDetailsCard from '../components/ClaimSeat/PlayerDetailsCard.vue'
import PlayerSelector from '../components/ClaimSeat/PlayerSelector.vue'
import SameTeamAsOtherPlayerAtYourTable from '../components/ClaimSeat/SameTeamAsOtherPlayerAtYourTable.vue'
import SummaryTeamConfirm from '../components/ClaimSeat/SummaryTeamConfirm.vue'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'

export default {
  components: {
    PlayerDetailsCard,
    PlayerSelector,
    SameTeamAsOtherPlayerAtYourTable,
    SummaryTeamConfirm,
    Toast,
  },

  computed: {
    ...mapState({
        players: state => state.entrylist,
        teams: state => state.teams,
        currentEvent: state => state.event.name,
        tables: state => state.tables,
        user_id: state => state.auth.user.id,
        currentTableId: state => state.currentTableId,
        leaderBoard: state => state.leaderBoard
    }),

    ...mapGetters([
        'GET_HASSEAT_BY_TABLE'
    ]),

    hasSeat(){
       return this.GET_HASSEAT_BY_TABLE(this.currentTableId);
    },

    tablePlayers(){
      return this.players.filter(item => item.tableId == this.currentTableId)
    },

    tableTeams(){
        // filter by current Table Id
        let currentTable = this.teams.filter((item) => {
            if(item.table_id == this.currentTableId){
              return true;
            } 
            else {
              return false;
            }
         });

        // Get first of filtered
         return currentTable[0].teams;
      },

    tableBuster(){
        // Get Table by currentTableId
        let tableBuster = '';
        this.tables.forEach(item => {
          if(item.id == this.currentTableId){
            tableBuster = item.buster;
          }
        });
        return tableBuster;
    },

    isScoreBusterTable(){
        // Get Table by currentTableId
        let tableStartingRound = '';
        this.tables.forEach(item => {
          if(item.id == this.currentTableId){
            tableStartingRound = item.starting_round;
          }
        });
        if(tableStartingRound == 1){
          return false;
        }
        else{
          return true;
        }
    }
  },

  methods: {
    ...mapMutations([
      'SET_HASSEAT',
      'CLEAR_TEAM_PLAYERS',
      'WRITE_TEAM_PLAYER'
    ]),
    ...mapActions({
      teamsAction: 'teams',
      newTeamAction: 'newTeam'
    }),
    
    playerView: function(playerId){
      // update current player data from playerId
      this.currentPlayer.id = playerId,
      this.currentPlayer.name = this.tablePlayers.find(x => x.id === playerId).name;
      this.currentPlayer.birth_date = this.tablePlayers.find(x => x.id === playerId).birth_date;
      this.currentPlayer.country = this.tablePlayers.find(x => x.id === playerId).country;
      this.currentPlayer.owgr = this.tablePlayers.find(x => x.id === playerId).owgr;
      this.currentPlayer.photo = this.tablePlayers.find(x => x.id === playerId).photo;
      this.currentPlayer.selected = this.tablePlayers.find(x => x.id === playerId).selected;

      if(this.leaderBoard.length > 0) {
        let playerLeaderBoard = this.leaderBoard.find(x => x.player_id === this.currentPlayer.id);
        if(playerLeaderBoard){
            let score = playerLeaderBoard.total_score;
            this.currentPlayer.total_score = score;
        }
        else { this.currentPlayer.total_score = 0; }
      }
      

      this.makeActive('PlayerDetailsCardIsActive');
    },

    addToTeamCompute: function(){
      // find array item where Id = playerId and update selected property to true
      let playerId = this.currentPlayer.id;
      this.tablePlayers.find(x => x.id === playerId).selected = true;
      this.makeActive('PlayerSelectorIsActive');

    },

    removeFromTeamCompute: function(){
      let playerId = this.currentPlayer.id;
      this.tablePlayers.find(x => x.id === playerId).selected = false;
      this.makeActive('PlayerSelectorIsActive');

    },

    confirmTeamCompute: function(){
      // If 4x players selected && total OWGR is not less than Rank Buster - show TeamConfirmed
      
      let buster = this.tableBuster;
      let teamRankOrScoreTotal = 0;
      let playersInTeam = 0;
      let isScoreBuster = this.isScoreBusterTable;
      let leaderBoard = this.leaderBoard;

      this.tablePlayers.forEach(function(item){
        if(item.selected === true){
          if(isScoreBuster){
             let playerLeaderBoard = leaderBoard.find(x => x.player_id === item.id);
              if(playerLeaderBoard){
                  let score = playerLeaderBoard.total_score;
                  teamRankOrScoreTotal += parseInt(score);
              }
          }
          else {
            teamRankOrScoreTotal += parseInt(item.owgr);
          }
          
          playersInTeam++; 
        }
      });

      if(playersInTeam < 4) {
        
          let playersNeeded = 4 - playersInTeam;
          let noun = 'players';
          if(playersNeeded === 1) {noun = 'player'};
          let toastText = "You need "+ playersNeeded +" more "+ noun +" to play, add them to your team first."; 
          console.log(toastText);
          
          this.callToast(toastText);
      }

      else if(playersInTeam > 4) {
          let playersExtra = playersInTeam - 4;
          let toastText = "You have "+ playersExtra +" to many players in your team, remove them from your team first."; 
          this.callToast(toastText);
      }

      else if(teamRankOrScoreTotal > buster){ // passes rankBuster requirement 
        // check for same team...

        // make array of selected team
        const selectedPlayerIds = new Array;
        this.players.forEach(function(item){
          if(item.selected === true){
            selectedPlayerIds.push(item.id);
          }
        });
        
        let match = false;
        let usersTeam = false;
        let that = this;
        // make array of player ids for each team in teams
        this.teams.forEach(function(item){
          const teamPlayerIds = new Array;
          teamPlayerIds.push(item.player1Id);
          teamPlayerIds.push(item.player2Id);
          teamPlayerIds.push(item.player3Id);
          teamPlayerIds.push(item.player4Id);

          const teamHasAllSelectedPlayers = teamPlayerIds.every(i => selectedPlayerIds.includes(i));
          if(teamHasAllSelectedPlayers){
            match = true;
          }
          if(item.user_id == that.user_id){
            usersTeam = true;
          }
          
        });

        if(match && !usersTeam){
          this.makeActive('SameTeamAsOtherPlayerAtYourTableIsActive');
        }
        else {
          this.makeActive('SummaryTeamConfirmIsActive');
        }
        
          
      }

      else {
          let under = buster - teamRankOrScoreTotal;
          let toastText = 'something went wrong';
          let busterMonika = 'Rank';
          if(this.isScoreBusterTable){
            busterMonika = 'Score';
          }
          if(under == 0){
            toastText = "Your team is the same as the " + buster + " "+busterMonika+" Buster. You must exceed the "+busterMonika+" Buster. Please change your team.";
          }
          else {
            toastText = "Your team is " + under + " points under the " + buster + " "+busterMonika+" Buster. Please change your team."; 
          }
          this.callToast(toastText);
      }

    },

    teamConfirmed: function(){
      // this.makeActive('TeamConfirmedIsActive');
      this.writeNewTeamPlayers();
      this.routerPush('Table');
    },


    playerSelectorView: function(){
      this.makeActive('PlayerSelectorIsActive');
    },

    secureSeat: function(){
      this.makeActive('PaymentTakeIsActive');
    },

    payCompute: function(result){
      if(result == 'SUCCESS'){
        this.SET_HASSEAT(true); // Set hasSeat
        this.teamsAction(); // Update Teams - so created team appears
        this.makeActive('PaymentConfirmedIsActive');
      }
      else {
        console.log('payCompute not SUCCESS - '+result);
        
        this.callToast(result);
      }
    },

    writeNewTeamPlayers: function(){
      // Get all selected players from entrylist
      let newPlayers = this.tablePlayers.filter(x => x.selected === true);
      console.log('Updated Team - '+JSON.stringify(newPlayers));
      

      // Get users team
      let user = this.user_id;
      let team = this.tableTeams.find(x => x.user_id === user);
      console.log("USER TEAM - "+JSON.stringify(team.team_id));

      // Delete players from users team
      this.CLEAR_TEAM_PLAYERS(team.team_id);

      // For each player slot add from selected
      let that = this;
      newPlayers.forEach(function(player) {
        let payload = { 'team_id': team.team_id, 'player_id': player.id, 'player_img' : player.photo }
        that.WRITE_TEAM_PLAYER(payload);
      }); 

      // Write newly written team to the server
      this.newTeamAction();
      

    },

    tableView: function(){
      this.routerPush('Table');
    },

    makeActive: function(active){
      this.scrollToTop();
      if(active === 'PlayerSelectorIsActive') {this.PlayerSelectorIsActive = true; } else { this.PlayerSelectorIsActive = false;}
      if(active === 'PlayerDetailsCardIsActive') {this.PlayerDetailsCardIsActive = true; } else { this.PlayerDetailsCardIsActive = false;}
      if(active === 'SameTeamAsOtherPlayerAtYourTableIsActive') {this.SameTeamAsOtherPlayerAtYourTableIsActive = true; } else { this.SameTeamAsOtherPlayerAtYourTableIsActive = false;}
      if(active === 'SummaryTeamConfirmIsActive') {this.SummaryTeamConfirmIsActive = true; } else { this.SummaryTeamConfirmIsActive = false;}
      if(active === 'PaymentConfirmedIsActive') {this.PaymentConfirmedIsActive = true; } else { this.PaymentConfirmedIsActive = false;}
      if(active === 'PaymentTakeIsActive') {this.PaymentTakeIsActive = true; } else { this.PaymentTakeIsActive = false;}
    },

    routerPush: function(link){
      this.scrollToTop();
      this.$router.push(link);
    },

    callToast(toastText){
      this.toastText = toastText;
      this.ToastActive = true;
      setTimeout(() => { this.ToastActive = false }, 5000)
      
    },

    scrollToTop() {
        window.scroll(0,0);
        // return { x: 0, y: 0 };
    }

  },

  data ()
   {
    return {
      // UI
      PlayerSelectorIsActive: true,
      PlayerDetailsCardIsActive: false,
      SameTeamAsOtherPlayerAtYourTableIsActive: false,
      SummaryTeamConfirmIsActive: false,

      ToastActive: false,
      toastText: false,


      currentPlayer: {
        id: false,
        name: false,
        birth_date: false,
        country: false,
        owgr: false,
        photo: false,
        total_score: false,
        selected: false
      },
      
    }
  }

}





</script>

<style scoped>

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

</style>
