<template>
    <main-header />
    <div class="text-center mb-5">
            <h1>{{ event }}</h1>
            <p class="text-xs"></p>
    </div>  
    <div class="container">
        <div class="bg-white rounded-md w-full shadow-md text-blue-4 p-3 text-center" >
            <p>Someone else at the table has already selected the same team as you. If you continue, and your team wins, the pot will be shared.</p>      
            <Button btn-text="CHANGE TEAM" @click="changeTeam"/>
            <Button btn-text="CONTINUE" @click="confirmTeamClick"/>
        </div>
    </div>
</template>

<script>

import Button from '../Base/Button.vue';
import MainHeader from '../Base/MainHeader.vue';

export default {
    components: { MainHeader, Button },
    name: "SameTeamAsOtherPlayerAtYourTable",
    props: {
        event: String,
    },
    emits: ["changeTeamEvent", "confirmTeamEvent"],
    methods: {
        changeTeam: function(){
            this.$emit("changeTeamEvent");
        },

        confirmTeamClick: function(){
            this.$emit("confirmTeamEvent");
        }
    }
}
</script>

<style scoped >
    p {
        margin-top: 10px;
        margin-bottom: 20px;
    }


</style>