<template>
<div class="relative p-0">
  <Badge v-if="isScoreBusterTable && badge" :badgeValue=score />  
  <Badge v-if="!isScoreBusterTable && badge" :badgeValue=owgr />
  <img @Click="playerClick" class="w-full rounded PlayerListCardImg z-0 relative mt-1 unselected" :class="{'selectedPlayer': selected }" :src="image" :id="'playerListCardId'+ playerId + ''" alt="">
</div> 
   
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Badge from './Badge.vue';

export default {
    name: "PlayerListCard",
    // emits: ["playerClick"],
    components: {
        Badge
    },
    props: {
        image: String,
        playerId: Number,
        owgr: Number,
        lastRoundBegun: Number,
        selected: Boolean,
        badge: Boolean,
        team: Boolean,
        isScoreBusterTable: Boolean
    },
    mounted(){
        if(this.playerId == 2360){
            console.log("Matt Kuchar playerList Card - mounted");
            console.log("Matt Kuchar playerList Card - image - "+this.image);
            console.log("Matt Kuchar playerList Card - owgr - "+this.owgr);
            console.log("Matt Kuchar playerList Card - selected - "+this.selected);
            console.log("Matt Kuchar playerList Card - badge - "+this.badge);
            console.log("Matt Kuchar playerList Card - team - "+this.team);
        }
    },
    computed: {
        ...mapState([
            'leaderBoard'
        ]),

        ...mapGetters([
            'GET_LEADERBOARD_BY_PLAYER'
        ]),

        score(){
            let board = this.GET_LEADERBOARD_BY_PLAYER(this.playerId);
            if(!board[0]){
                return "E";
            }
            let score =  board[0].total_score;
            if(score > 0) { return "+" + score;}
            if(score == 0 ) { return "E";}
            if(score < 0) { return score;}
            
        },

        thruProcessed(){

            let board = this.GET_LEADERBOARD_BY_PLAYER(this.playerId);
            return board[0].total_thru;
            if(!board[0]){
                return false;
            }
            let thru = board[0].total_thru;
            
            // If finished round - performances exist with NULL scores if not happend 
            let performedThisRound = false;
            this.performances.forEach(item => {
                if (item['score']){
                    performedThisRound = true;
                }
            });
            if(performedThisRound && thru < 1){
                return 'F';
            }
            else if (thru > 0 ){
                return thru;
            }
            else {
                return 0;
            }
        },

        performances(){
            return this.GET_PERFORMANCES_BY_PLAYER_AND_ROUND()(this.playerId)(this.lastRoundBegun);
        },
    },

    methods: {
        ...mapGetters([
            'GET_PERFORMANCES_BY_PLAYER_AND_ROUND',
            'GET_TEETIME_BY_PLAYER_AND_ROUND'
        ]),

        playerClick: function(){
            this.$emit('playerClick', this.playerId);
            
        },

        
    },
    
}
</script>

<style scoped>

    /* .myProgress{
        width: 40px;
        margin-top: -3px;
        background-color: rgba(255, 255, 255, 0.5);
    }
    .myBar{
        height: 3px;
        background: rgb(255,140,79);
        background: linear-gradient(132deg, rgba(255,140,79,1) 15%, rgba(255,86,245,1) 100%);
        border: 2px solid linear-gradient(132deg, rgba(255,140,79,1) 15%, rgba(255,86,245,1) 100%);
        border-top-left-radius: 0px;
        border-bottom-left-radius: 2px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 2px;
    } */

    img {

        /* heavy blue  */
        /* filter: grayscale(1.0) brightness(1) sepia(1) hue-rotate(170deg) saturate(2); */

        /* gentle blue  */
        /* filter: grayscale(1.0) brightness(1.3) sepia(1) hue-rotate(170deg) saturate(.8); */

        /* grey */
        filter: brightness(1) ;

        opacity: 0.7;
        object-fit: cover;

        /* width: 40px; */
    }

    img.unselected{
        filter: grayscale(1.0) brightness(1) ;
        opacity: .5;
    }

    img.notLive{
        filter: grayscale(1.0) brightness(1) ;
        opacity: .5;
    }

    img.selectedPlayer{
              /* gentle blue  */
        /* filter: grayscale(1.0) brightness(1) sepia(1) hue-rotate(170deg) saturate(3); */
        filter: brightness(1);
        opacity: 0.7;

    }

</style>