<template>
    <h1>Thank You</h1>
    <p>We will send you an alert in time for you to participate in the next event.</p>
    <p>You can still follow the action on the table during {{ event }}.</p>
    <Button @click="viewTableEvent" btnText="VIEW TABLE" class="mt-10"/>
</template>

<script>
    import Button from '@/components/Base/Button.vue'

    export default {
        name: 'RegisterThankYouKnown',
        components: {
            Button,
        },
        props:["event"],
        emits:["viewTableEvent"],
        methods: {
            viewTableEvent: function (){
                this.$emit('viewTableEvent');
            },
        } 
    }
</script>

<style>

</style>