<template>
<div>
    <div v-if="eventStatus == 'inProgress'">
        <p v-if="!inPlay && haveRoundStart">
            <span><span class="font-bold">{{ nextRound }}{{ suffix }} Round</span> starts in </span>
            <span v-if="days > 0">{{ days }} day<span v-if="days > 1">s</span>&nbsp;</span> 
            <span v-if="hours > 0"> {{ hours }} hr<span v-if="hours > 1">s </span>&nbsp;</span> 
            <span v-if="hours < 12 && days < 1"> {{ minutes }} min<span v-if="minutes > 1">s </span></span>
        </p>
        <p v-if="!inPlay && !haveRoundStart">
            <span><span class="font-bold">{{ nextRound }}{{ suffix }} Round</span> starts soon</span>
        </p>
        <p v-if="inPlay">
            <span><span class="font-bold">{{ thisRound }}{{ suffix }} Round</span> in progress</span>
        </p>
    </div>

    <div v-if="eventStatus == 'ready'">
        <p  v-if="!inPlay  && haveRoundStart" class="mb-5">
            <span>Starts in </span>
            <span v-if="days > 0">{{ days }} day<span v-if="days > 1">s </span>&nbsp;</span> 
            <span v-if="hours > 0">{{ hours }} hr<span v-if="hours > 1">s </span>&nbsp;</span> 
            <span v-if="hours < 12 && days < 1 && minutes > 1"> {{ minutes }} mins</span>
            <span v-if="days == 0 && hours == 0 && minutes <= 1"> 1 min</span>
        </p>

        <p  v-if="!inPlay  && !haveRoundStart" class="mb-5">
            <span>Starting soon </span>
        </p>

        <p v-if="inPlay">
            <span>{{ thisRound }}{{ suffix }} round in progress</span>
        </p>
    </div>

    <div v-if="eventStatus == 'over'">
        <p class="mb-5">This event has now finished.</p>
    </div>
    
    
</div>
</template>

<script>
import router from '../.././router';
import { mapState, mapActions, mapGetters } from 'vuex';
export default {

    name: "EventProgress",
    data () {
        return {
            days: 1,
            hours: 1,
            minutes: 1,
            suffix: "",
            inPlay: null,
            thisRound: null,
            nextRound: null,
            nextRoundDay: "",
            haveRoundStart: false,
            startTime: '',
            timeinterval: undefined
        }
    },
    computed: {
        ...mapState([
        'event',
        'tables',
        'eventStatus'
      ]),

      lastRoundBegun() {
            if(this.isPastStart(this.event.round4_start)){ return 4; }
            else if(this.isPastStart(this.event.round3_start)){ return 3; }
            else if(this.isPastStart(this.event.round2_start)){ return 2; }
            else if(this.isPastStart(this.event.round1_start)){ return 1; }
            else { return null; }
        },


      todayRound() {
          console.log('todayRound called');
          console.log('round1_day : '+this.event.round1_day);
          console.log('round2_day : '+this.event.round2_day);
          console.log('round3_day : '+this.event.round3_day);
          console.log('round4_day : '+this.event.round4_day);
          
          
          if(this.isToday(this.event.round1_day)){ return 1; }
          if(this.isToday(this.event.round2_day)){ return 2; }
          if(this.isToday(this.event.round3_day)){ return 3; }
          if(this.isToday(this.event.round4_day)){ return 4; }
          else { return null; }
      },

      

    },

    methods: {

        ...mapActions({
          leaderBoardAction: 'leaderBoard',
          performancesAction: 'performances',
          teetimes: 'teetimes',
          entryListAction: 'entrylist',
          teamsAction: 'teams',
          tablesAction: 'tables',
          eventAction: 'event',

      }),

      ...mapGetters([
            'GET_LEADERBOARD_BY_PLAYER'
        ]),


        /* *** Runs - on created - and every minute *** */
        init: function(){
            
            // if event has not started
            if(this.eventStatus == 'ready'){ 
                this.inPlay = false;
                this.nextRound = 1;
                this.writeSuffix(this.nextRound);
                let start = this.makeNewDate(this.event.round1_start);
                this.startTime = start;
            }

            // if event has started
            if (this.eventStatus == 'inProgress'){ 
                // is current round over?
                let over = this.haveTableStartingNextRound();
                
                if(over){ 
                    this.inPlay = false;
                    this.writeNextRoundCountDown();
                }
                else{
                    this.inPlay = true;
                    this.thisRound = this.lastRoundBegun;
                    this.writeSuffix(this.thisRound);
                }

                // this.pollAPI();
            }

            // event is over is handled by eventStatus

            // console.log( 
            //     "Days : "+this.days +"\n"+
            //     "Hours : "+this.hours +"\n"+
            //     "Minutes : "+this.minutes +"\n"+
            //     "Suffix : "+this.suffix +"\n"+
            //     "inPlay : "+this.inPlay +"\n"+
            //     "thisRound : "+this.thisRound +"\n"+
            //     "nextRound : "+this.nextRound +"\n"+
            //     "nextRoundDay : "+this.nextRoundDay +"\n"+
            //     "haveRoundStart : "+this.haveRoundStart +"\n"+
            //     "startTime : "+this.startTime +"\n"+
            //     "timeinterval : "+this.timeinterval
            // );   
            
            // Setup/update timer 
            this.updateTimer();
            this.pollForData();
        },

        pollForData: function(){
          this.leaderBoardAction();
          this.performancesAction();
          this.teetimes();
          this.entryListAction();
          this.teamsAction();
        //   this.tablesAction();
          this.eventAction();
          console.log('pollForData ran');
          let player = this.GET_LEADERBOARD_BY_PLAYER()(710);
          console.log('pollForData ran - Greg Chalmers position = ' + player[0]['position']);
          
        },

        updateTimer: function() {
            console.log('updateTimer');
            
            if (
                this.days > 0 ||
                this.hours > 0 ||
                this.minutes > 0 
            ) {
                this.getTimeRemaining();
            } else {
                // clearTimeout(this.timeinterval);
                // fake reactivity - reload the app
                router.go();
            }
        },

        getTimeRemaining: function() {
            let s = this.startTime;
            let start = Date.parse(s);
            let n = new Date();
            let now = Date.parse(n);
            let t = start - now;
            console.log('this.startTime - '+this.startTime);
            
            console.log('getTimeRemaining - start - '+JSON.stringify(start)+' - now - '+JSON.stringify(now)+' - t -'+JSON.stringify(t));
            
            if(t >= 0){
                // this.seconds = Math.floor(t / 1000 % 60); //seconds
                this.minutes = Math.floor(t / 1000 / 60 % 60); //minutes
                this.hours = Math.floor(t / (1000 * 60 * 60) % 24); //hours
                this.days = Math.floor(t / (1000 * 60 * 60 * 24)); //days
            } else {
                // this.days = this.hours = this.minutes = 0;
                // fake reactivity - reload the app
                // router.go();
            }
        },

        haveTableStartingNextRound: function (){
            let thisRound = this.lastRoundBegun;
            let nextRound = thisRound + 1;
            // does a table exist with starting round == nextRound
            // as there is 1 table for each starting round, we can use countTables
            let tableCount = this.countTables();
            // console.log('ROUND OVER? - this round ='+thisRound );
            // console.log('ROUND OVER? - next round ='+nextRound );
            // console.log('ROUND OVER? - table count ='+tableCount );
            if(tableCount >= nextRound && thisRound){
                return true;
            }
            else {
                return false;
            }
        },

        countTables: function(){
            console.log('this.tables = '+JSON.stringify(this.tables));
            
            return Object.keys(this.tables).length; // cannot convert null to object
        },

        writeNextRoundCountDown: function(){
            this.nextRound = this.lastRoundBegun + 1;
            this.writeSuffix(this.nextRound);
            // Get next round start time
            let next_round_start = eval("this.event.round"+this.nextRound+"_start");
            // Do we have a start time?
            if(next_round_start){
                this.haveRoundStart = true;
                let start = this.makeNewDate(next_round_start);
                this.startTime = start;
            }
            else {
                this.haveRoundStart = false;
                let next_round_day = eval("this.event.round"+this.nextRound+"_day");
                this.makeNextRoundDay(next_round_day);
            }
        },

        isToday: function(dateTimeString){
            console.log('isToday called');
            
            let today = new Date();
            let dateTime = this.makeNewDate(dateTimeString);

            console.log('today Day - '+today.getDate());
            console.log('start Day - '+dateTime.getDate());
            
            if (
                dateTime.getDate() == today.getDate()
                && dateTime.getMonth() == today.getMonth()
                && dateTime.getFullYear() == today.getFullYear()
            ){
                return true;
            }
            else {
                return false;
            }
        },

        isPastStart: function(dateTimeString){
            if(dateTimeString) { // make sure we have a start time from Sports Data
                let today = new Date();
                let dateTime = this.makeNewDate(dateTimeString);
                if (dateTime < today){ return true; }
                else { return false; }
            }
            else {
                return false;
            }
        },

        writeSuffix: function(round){
            if(round == 1){ this.suffix = 'st'; }
            if(round == 2){ this.suffix = 'nd'; }
            if(round == 3){ this.suffix = 'rd'; }
            if(round == 4){ this.suffix = 'th'; }
        },

        makeNextRoundDay: function(next_round_day){
            let now = new Date();
            let nextRoundDate = this.makeNewDate(next_round_day);
            let dayGap = this.days_between(nextRoundDate, now);
            console.log('Day gap : '+dayGap);
            

            if (dayGap < 1) {this.nextRoundDay = "tomorrow"; }
            else if (dayGap < 2) {this.nextRoundDay = "the day after tomorrow"; }
            else if (dayGap < 3) {this.nextRoundDay = "in 3 days"; }
            else if (dayGap < 4) {this.nextRoundDay = "in 4 days"; }
            else if (dayGap < 5) {this.nextRoundDay = "in 5 days"; }
            else if (dayGap < 6) {this.nextRoundDay = "in 6 days"; }
            else if (dayGap < 7) {this.nextRoundDay = "in 1 week"; }
            else { this.nextRoundDay = "soon";}
        },

        days_between: function(date1, date2) {

            // The number of milliseconds in one day
            const ONE_DAY = 1000 * 60 * 60 * 24;

            // Calculate the difference in milliseconds
            const differenceMs = Math.abs(date1 - date2);

            // Convert back to days and return
            return differenceMs / ONE_DAY;
        },

        makeNewDate: function(dateTimeString) {
            // *** Fix Safari Date() compatability ***
            // recieved format 2021-11-18 14:30:00
            // this.makeNewDate format yyyy, mm-1, dd, hh, mm, ss
            // returns newDate from string
            if(dateTimeString){
                if (typeof dateTimeString === 'string' || dateTimeString instanceof String){
                    if(dateTimeString.includes("-")){
                        // process date
                        console.log('dateTimeString sent to makeNewDate - '+dateTimeString);
                    
                        const dateArray = dateTimeString.split("-");
                        const timeDateArray = dateArray[2].split(" ");
                        const timeArray = timeDateArray[1].split(":");
                        
                        let yyyy = dateArray[0];
                        let month = dateArray[1];
                        let monthLessOne = parseInt(month) -1;
                        let dd = timeDateArray[0];
                        let hh = timeArray[0];
                        let mm = timeArray[1];
                        let ss = timeArray[2];

                        console.log('Sent to new Date - ln 364 - '+yyyy+','+monthLessOne+','+dd+','+hh+','+mm+','+ss);
                        
                        return new Date(yyyy, monthLessOne, dd, hh, mm, ss);
                    }
                    else {
                        // return now
                        return new Date();
                    }
                }
                else {
                    // return now
                    return new Date();
                }
            }
            else {
                // return now
                return new Date();
            }
        },
    },  

    created: function() {
        this.init();
        this.timeinterval = setInterval(this.init, 30000);
    }
}

</script>

<style>
    

</style>