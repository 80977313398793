<template>
<div class="bg-white rounded-md w-full shadow-sm mb-1" @click="playerClick">
    
    <div class="cardHead grid grid-cols-9 p-4">
        <div class="icon col-span-2">
            <player-list-card class="w-12 h-12" :image="playerImg" :playerId="playerId" :team="false" :selected="true" :badge="false" :lastRoundBegun="lastRoundBegun"/>
        </div>
        <div class="col-span-7 grid grid-cols-6 gap-2">
            <div class="text-left col-span-5 font-bold">{{ player_name }}</div>
            <div class="position text-right grid grid-cols-2 gap-1">
                <div v-if="shield" class="shield ">
                    <svg class=" float-right w-4 pt-0 m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <g data-name="Layer 2">
                            <g data-name="shield">
                                <rect width="24" height="24" opacity="0"/>
                                <path fill="url(#table-gradient)" d="M12 21.85a2 2 0 0 1-1-.25l-.3-.17A15.17 15.17 0 0 1 3 8.23v-.14a2 2 0 0 1 1-1.75l7-3.94a2 2 0 0 1 2 0l7 3.94a2 2 0 0 1 1 1.75v.14a15.17 15.17 0 0 1-7.72 13.2l-.3.17a2 2 0 0 1-.98.25zm0-17.7L5 8.09v.14a13.15 13.15 0 0 0 6.7 11.45l.3.17.3-.17A13.15 13.15 0 0 0 19 8.23v-.14z"/>
                            </g>
                        </g>
                    </svg>
                </div>
                <span>{{ position }}<sup>{{ sup }}</sup></span>
            </div>
            
            <div v-if="thruProcessed != 0" class="text-left text-xs col-span-2">R{{ lastRoundBegun }} {{ roundScore }}</div>
            <div v-if="thruProcessed != 0" class="text-center text-xs col-span-2 ">THRU {{ thruProcessed }}</div>
            <div v-if="thruProcessed != 0" class="text-center text-xs col-span-2 ">TOTAL {{ totalScore }}</div>
            
            <div v-if="thruProcessed == 0" class="text-left text-xs col-span-2 ">R{{ lastRoundBegun }}</div>
            <div v-if="thruProcessed == 0" class="text-left text-xs col-span-2 ">{{ teetime }}</div>
            <div v-if="thruProcessed == 0" class="text-center text-xs col-span-2 ">TOTAL {{ totalScore }}</div>
            
            
        </div>
        

    </div>



</div>
</template>

<script>


import PlayerListCard from '../../Base/PlayerListCard.vue'
import { mapGetters, mapState } from 'vuex'

export default {
    name: "PlayerLeaderCard",
    props: {
        player_name: String,
        position: Number,
        shield: Number,
        sup: String,
        birth_date: String,
        country: String,
        owgr: Number,
        total: Number,
        thru: Number,
        playerImg: String,
        playerId: Number,
        
    },
    components: {
        PlayerListCard,
    },

    mounted(){
         
    },

    computed: {
        ...mapState([
            'event'
        ]),

        ...mapGetters([
            'GET_PERFORMANCES_BY_PLAYER_AND_ROUND',
            'GET_TEETIME_BY_PLAYER_AND_ROUND'
        ]),

        thruProcessed(){
            // If finished round - performances exist with NULL scores if not happend 
            let performedThisRound = false;
            this.performances.forEach(item => {
                if (item['score']){
                    performedThisRound = true;
                }
            });
            if(performedThisRound && this.thru < 1){
                return 'F';
            }
            else if (this.thru > 0 ){
                return this.thru;
            }
            else {
                return 0;
            }
        },

        teetime(){
            let teetime =  this.GET_TEETIME_BY_PLAYER_AND_ROUND(this.playerId)(this.lastRoundBegun);
            let d = this.makeNewDate(teetime[0].teetime);
            let hh = d.getHours();
            let mm = d.getMinutes();
            if(mm < 10 ){ mm = '0'+mm; }

            return hh+":"+mm;
        },

        performances(){
            let performances =  this.GET_PERFORMANCES_BY_PLAYER_AND_ROUND(this.playerId)(this.lastRoundBegun);
            return performances;
            
        },

        roundScore(){
            
            let total = 0;
            this.performances.forEach(item => {

                if (item['score']){
                    total += (item['score'] - item['par']);
                }
            });

            if(total > 0 ){ return "+"+total; }
            else if(total == 0 ){ return "E"; }
            else { return total;}
        },

        totalScore(){
        
            if(this.total > 0 ){ return "+"+this.total; }
            else if(this.total == 0 ){ return "E"; }
            else { return this.total;}
        },

        lastRoundBegun() {
            if(this.isPastStart(this.event.round4_start)){ return 4; }
            else if(this.isPastStart(this.event.round3_start)){ return 3; }
            else if(this.isPastStart(this.event.round2_start)){ return 2; }
            else if(this.isPastStart(this.event.round1_start)){ return 1; }
            else { return null; }
        },
    },
    methods: {

        isPastStart: function(dateTimeString){
            if(dateTimeString) { // make sure we have a start time from Sports Data
                let today = new Date();
                let dateTime = this.makeNewDate(dateTimeString);
                if (dateTime < today){ return true; }
                else { return false; }
            }
            else {
                return false;
            }
        },

        playerClick: function(){
            let playerId = this.playerId;
            this.$emit('playerClick', playerId );
        },

        makeNewDate: function(dateTimeString) {
            // *** Fix Safari Date() compatability ***
            // recieved format 2021-11-18 14:30:00
            // this.makeNewDate format yyyy, mm-1, dd, hh, mm, ss
            // returns newDate from string
            if(dateTimeString){
                if (typeof dateTimeString === 'string' || dateTimeString instanceof String){
                    if(dateTimeString.includes("-")){
                        // process date
                    
                        const dateArray = dateTimeString.split("-");
                        const timeDateArray = dateArray[2].split(" ");
                        const timeArray = timeDateArray[1].split(":");
                        
                        let yyyy = dateArray[0];
                        let month = dateArray[1];
                        let monthLessOne = parseInt(month) -1;
                        let dd = timeDateArray[0];
                        let hh = timeArray[0];
                        let mm = timeArray[1];
                        let ss = timeArray[2];

                        
                        return new Date(yyyy, monthLessOne, dd, hh, mm, ss);
                    }
                    else {
                        // return now
                        return new Date();
                    }
                }
                else {
                    // return now
                    return new Date();
                }
            }
            else {
                // return now
                return new Date();
            }

        }
    }
}
</script>

<style>

</style>