import { createApp, Vue } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// axios.defaults.baseURL = 'http://localhost:8000'
console.log('.env API endpoint url - '+ process.env.VUE_APP_API_ENDPOINT);

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
axios.defaults.withCredentials = true;


store.dispatch('auth/me').then(() =>{   // build app... 
    let auth = store.state.auth.authenticated;
    console.log('main.js authenticated = '+auth);

    if(auth == false){
        
        // launch app
        createApp(App).use(store).use(router).mount('#app') 
        
        // login view
        router.push({ path: 'welcome' });
    }
    else { // auth is true
        // log activity - against auth user
        store.dispatch('metric', 'Open');
        
        // check for event
        store.dispatch('eventStatus').then(() =>{
            let status = store.state.eventStatus;
            console.log('main.js - eventStatus : '+status);
            
            
    
            if(status == 'inProgress' || status == 'ready' || status == 'over'){
                
                // load  data
                store.dispatch('event');
                store.dispatch('tables');
                store.dispatch('leaderBoard');
                store.dispatch('teetimes');
                store.dispatch('performances');

                // get entryList - for team selection, and edit team process
                store.dispatch('entrylist').then(() =>{ 
                    store.dispatch('teams').then(() =>{
                        // *** Build edit team - pre selection *** //
                        // Get user id from auth
                        let user_id = store.state.auth.user.id;

                        // for each table which has teams
                        let tableTeams = store.state.teams.forEach(table => {
                            console.log('TABLE TEAMS - '+JSON.stringify(table.teams));
                            // Find users current team - if exists
                            let users_team = table.teams.filter(team => team.user_id == user_id);  
                            if(users_team.length > 0){ // user has a team
                                // Get Ids of users players for their current team - for each table
                                let p1 = users_team[0]['player1Id'];
                                let p2 = users_team[0]['player2Id'];
                                let p3 = users_team[0]['player3Id'];
                                let p4 = users_team[0]['player4Id'];
                                let teamPlayers = [p1,p2,p3,p4];
    
                                // Write these players as "selected" on entrylist where table 
                                console.log('entrylist'+ JSON.stringify(store.state.entrylist));
                                teamPlayers.forEach(id => {
                                    let player = store.state.entrylist.find(x => x.id === id && x.tableId == table.table_id);
                                    if(player){
                                        player.selected = true;
                                    }
                                });
                                
                            }                          
                          });

                        // launch app
                        createApp(App).use(store).use(router).mount('#app');

                        // table view
                        router.push({ path: 'table' });
                    });

                });
            }

            
            
            // Event -  soon - no entrylist
            if(status == 'soon'){

                // launch app
                createApp(App).use(store).use(router).mount('#app');

                // event over view
                router.push({ path: 'eventsoon' });
            }
            
            // Event -  none - no past, present or future approved events
            if(status == 'none'){
                // launch app
                createApp(App).use(store).use(router).mount('#app');

                // event over view
                router.push({ path: 'eventnone' });
            }
            
        
        });

    }
    

    
})

