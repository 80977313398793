<template>
<div class="relative">
  <Badge :badgeValue=totalScore />
  <img @Click="playerClick" class="w-14 rounded PlayerListCardImg z-0 relative mt-1" :class="{'selectedPlayer': selected, 'notLive': !live }" :src="image" :id="'playerListCardId'+ playerId + ''" alt="">
  <span class="infoTag">{{infoTag}}</span>   
</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Badge from './Badge.vue';

export default {
    name: "PlayerListCard",
    emits: ["playerClick"],
    components: {
        Badge
    },
    props: {
        image: String,
        playerId: Number,
        owgr: Number,
        selected: Boolean,
        team: Boolean,
        live: Boolean,
        lastRoundBegun: Number,
        teamStatus: String,

    },
    computed: {
        ...mapState([
            'leaderBoard'
        ]),

        ...mapGetters([
            'GET_LEADERBOARD_BY_PLAYER',
        ]),

        totalScore(){
            let board = this.GET_LEADERBOARD_BY_PLAYER(this.playerId);
            if(!board[0]){
                return false;
            }
            let score =  board[0].total_score;
            if(score > 0) { return "+" + score;}
            if(score == 0 || score === null) { return "E";}
            // if(score == 0 && this.thruProcessed != 0) { return "E";}
            // if(score == 0 && this.thruProcessed == 0) { return false;}
            if(score < 0) { return score;}

        },

        thruProcessed(){

            let board = this.GET_LEADERBOARD_BY_PLAYER(this.playerId);
            if(!board[0]){
                return false;
            }
            let thru = board[0].total_thru;
            
            // If finished round - performances exist with NULL scores if not happend 
            let performedThisRound = false;
            this.performances.forEach(item => {
                if (item['score']){
                    performedThisRound = true;
                }
            });
            if(performedThisRound && thru < 1){
                return 'F';
            }
            else if (thru > 0 ){
                return thru;
            }
            else {
                return 0;
            }
        },

        performances(){
            return this.GET_PERFORMANCES_BY_PLAYER_AND_ROUND()(this.playerId)(this.lastRoundBegun);
        },

        teetime(){
            let teetime = this.GET_TEETIME_BY_PLAYER_AND_ROUND()(this.playerId)(this.lastRoundBegun);
            if(teetime.length > 0) { // have a teetime
                const d = this.makeNewDate(teetime[0].teetime);
                let hh = d.getHours();
                let mm = d.getMinutes();
                if(mm < 10 ){ mm = '0'+mm; }

                return hh+":"+mm;
            }
            else {
                return '';
            }
            
        },

        roundScore(){
            let total = 0;
            this.performances.forEach(item => {

                if (item['score']){
                    total += (item['score'] - item['par']);
                }
            });

            return total;
        },

        roundInfo(){
            let score = this.roundScore;
            if(score > 0 ){ score = "+"+score;}
            else if (score == 0 ){ score = 'E';}
            return score+" ("+this.thruProcessed+")";
        }, 

        infoTag(){
            
            let playerInLeaderboard = this.GET_LEADERBOARD_BY_PLAYER(this.playerId);
            let keys = Object.keys(playerInLeaderboard).length;
            if(keys < 1 && this.teamStatus == "withdrawn"){
                return "OUT";
            }
            else if(keys < 1 && this.teamStatus == "missedCut"){
                return "OUT";
            }
            else if(this.thruProcessed == 0){ return this.teetime;}
            else { return this.roundInfo;}
        }
    },

    methods: {

        ...mapGetters([
            'GET_PERFORMANCES_BY_PLAYER_AND_ROUND',
            'GET_TEETIME_BY_PLAYER_AND_ROUND'
        ]),

        playerClick: function(){
            this.$emit('playerClick', this.playerId);
        },

        makeNewDate: function(dateTimeString) {
            // *** Fix Safari Date() compatability ***
            // recieved format 2021-11-18 14:30:00
            // this.makeNewDate format yyyy, mm-1, dd, hh, mm, ss
            // returns newDate from string
            if(dateTimeString){
                if (typeof dateTimeString === 'string' || dateTimeString instanceof String){
                    if(dateTimeString.includes("-")){
                        // process date
                    
                        const dateArray = dateTimeString.split("-");
                        const timeDateArray = dateArray[2].split(" ");
                        const timeArray = timeDateArray[1].split(":");
                        
                        let yyyy = dateArray[0];
                        let month = dateArray[1];
                        let monthLessOne = parseInt(month) -1;
                        let dd = timeDateArray[0];
                        let hh = timeArray[0];
                        let mm = timeArray[1];
                        let ss = timeArray[2];

                        
                        return new Date(yyyy, monthLessOne, dd, hh, mm, ss);
                    }
                    else {
                        // return now
                        return new Date();
                    }
                }
                else {
                    // return now
                    return new Date();
                }
            }
            else {
                // return now
                return new Date();
            }

        }
        
    },
    
}
</script>

<style scoped>

    /* .myProgress{
        width: 40px;
        margin-top: -3px;
        background-color: rgba(255, 255, 255, 0.5);
    }
    .myBar{
        height: 3px;
        background: rgb(255,140,79);
        background: linear-gradient(132deg, rgba(255,140,79,1) 15%, rgba(255,86,245,1) 100%);
        border: 2px solid linear-gradient(132deg, rgba(255,140,79,1) 15%, rgba(255,86,245,1) 100%);
        border-top-left-radius: 0px;
        border-bottom-left-radius: 2px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 2px;
    } */

    .infoTag{
        font-size: 10px;
        /* color: slategray; */
        /* margin-left: -28px; */
    }

   

    img {

        /* heavy blue  */
        /* filter: grayscale(1.0) brightness(1) sepia(1) hue-rotate(170deg) saturate(2); */

        /* gentle blue  */
        /* filter: grayscale(1.0) brightness(1.3) sepia(1) hue-rotate(170deg) saturate(.8); */

        /* grey */
        filter: brightness(1) ;

        opacity: 0.7;
        object-fit: cover;

        /* width: 40px; */
    }

    img.unselected{
        filter: grayscale(1.0) brightness(1) ;
        opacity: .5;
    }

    img.notLive{
        filter: grayscale(1.0) brightness(1) ;
        opacity: .5;
    }

    img.selectedPlayer{
              /* gentle blue  */
        /* filter: grayscale(1.0) brightness(1) sepia(1) hue-rotate(170deg) saturate(3); */
        filter: brightness(1);
        opacity: 0.7;

    }

</style>