<template>
    
  <div class="styleBlur grid grid-cols-3 w-full bg-opacity-50 fixed bottom-0 z-50">
      <div class="p-3" @click="leaderBoardClick">
          <svg :class="{ activeIcon: leaderBoardActive }" class="w-8 m-auto inactiveIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            
            <g data-name="Layer 2">
                <g data-name="leader_board">
                    <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/>
                    <circle cx="4" cy="12" r="1"/>
                    <rect x="7" y="11" width="14" height="2" rx=".94" ry=".94"/>
                    <rect x="3" y="16" width="18" height="2" rx=".94" ry=".94"/>
                    <rect x="3" y="6" width="18" height="2" rx=".94" ry=".94"/>
                </g>
            </g>
          </svg>
      </div>

      <div class="p-3" @click="teamBoardClick">
          <svg :class="{ activeIcon: teamBoardActive }" class="w-8 m-auto inactiveIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            
            <g data-name="Layer 2">
                <g data-name="team_board">
                    <rect width="24" height="24" opacity="0"/>
                    <path d="M9 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2z"/>
                    <path d="M17 13a3 3 0 1 0-3-3 3 3 0 0 0 3 3zm0-4a1 1 0 1 1-1 1 1 1 0 0 1 1-1z"/>
                    <path d="M17 14a5 5 0 0 0-3.06 1.05A7 7 0 0 0 2 20a1 1 0 0 0 2 0 5 5 0 0 1 10 0 1 1 0 0 0 2 0 6.9 6.9 0 0 0-.86-3.35A3 3 0 0 1 20 19a1 1 0 0 0 2 0 5 5 0 0 0-5-5z"/>
                </g>
            </g>
          </svg>
      </div>

      <div class="p-3" @click="eventInfoClick">
          <svg :class="{ activeIcon: eventInfoActive }" class="w-8 m-auto inactiveIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g data-name="Layer 2">
                <g data-name="event_info">
                    <rect width="24" height="24" transform="rotate(180 12 12)" opacity="0"/>
                    <path d="M12.94 22h-1.89a1.68 1.68 0 0 1-1.68-1.68v-1.09a.34.34 0 0 0-.22-.29.38.38 0 0 0-.41 0l-.74.8a1.67 1.67 0 0 1-2.37 0L4.26 18.4a1.66 1.66 0 0 1-.5-1.19 1.72 1.72 0 0 1 .5-1.21l.74-.74a.34.34 0 0 0 0-.37c-.06-.15-.16-.26-.3-.26H3.68A1.69 1.69 0 0 1 2 12.94v-1.89a1.68 1.68 0 0 1 1.68-1.68h1.09a.34.34 0 0 0 .29-.22.38.38 0 0 0 0-.41L4.26 8a1.67 1.67 0 0 1 0-2.37L5.6 4.26a1.65 1.65 0 0 1 1.18-.5 1.72 1.72 0 0 1 1.22.5l.74.74a.34.34 0 0 0 .37 0c.15-.06.26-.16.26-.3V3.68A1.69 1.69 0 0 1 11.06 2H13a1.68 1.68 0 0 1 1.68 1.68v1.09a.34.34 0 0 0 .22.29.38.38 0 0 0 .41 0l.69-.8a1.67 1.67 0 0 1 2.37 0l1.37 1.34a1.67 1.67 0 0 1 .5 1.19 1.63 1.63 0 0 1-.5 1.21l-.74.74a.34.34 0 0 0 0 .37c.06.15.16.26.3.26h1.09A1.69 1.69 0 0 1 22 11.06V13a1.68 1.68 0 0 1-1.68 1.68h-1.09a.34.34 0 0 0-.29.22.34.34 0 0 0 0 .37l.77.77a1.67 1.67 0 0 1 0 2.37l-1.31 1.33a1.65 1.65 0 0 1-1.18.5 1.72 1.72 0 0 1-1.19-.5l-.77-.74a.34.34 0 0 0-.37 0c-.15.06-.26.16-.26.3v1.09A1.69 1.69 0 0 1 12.94 22zm-1.57-2h1.26v-.77a2.33 2.33 0 0 1 1.46-2.14 2.36 2.36 0 0 1 2.59.47l.54.54.88-.88-.54-.55a2.34 2.34 0 0 1-.48-2.56 2.33 2.33 0 0 1 2.14-1.45H20v-1.29h-.77a2.33 2.33 0 0 1-2.14-1.46 2.36 2.36 0 0 1 .47-2.59l.54-.54-.88-.88-.55.54a2.39 2.39 0 0 1-4-1.67V4h-1.3v.77a2.33 2.33 0 0 1-1.46 2.14 2.36 2.36 0 0 1-2.59-.47l-.54-.54-.88.88.54.55a2.39 2.39 0 0 1-1.67 4H4v1.26h.77a2.33 2.33 0 0 1 2.14 1.46 2.36 2.36 0 0 1-.47 2.59l-.54.54.88.88.55-.54a2.39 2.39 0 0 1 4 1.67z" data-name="&lt;Group&gt;"/>
                    <path d="M12 15.5a3.5 3.5 0 1 1 3.5-3.5 3.5 3.5 0 0 1-3.5 3.5zm0-5a1.5 1.5 0 1 0 1.5 1.5 1.5 1.5 0 0 0-1.5-1.5z"/>
                </g>
            </g>
           </svg>
      </div>

  </div>
</template>

<script>
export default {
    name:"TableBar",
    props: {
        teamBoardActive: Boolean,
        leaderBoardActive: Boolean,
        eventInfoActive: Boolean,
    },
    
    emits: ["teamBoardEvent", "leaderBoardEvent", "eventInfoEvent"],
    methods: {
        teamBoardClick: function() {

            this.$emit('teamBoardEvent');

        },

        leaderBoardClick: function() {

            this.$emit('leaderBoardEvent');
        },

        eventInfoClick: function() {

            this.$emit('eventInfoEvent');
        }

    },

}
</script>

<style lang="postcss">
    .styleBlur{
        backdrop-filter: blur(5px); 
        background-color: rgba(299, 299, 299, 0.8);
    }

    svg.activeIcon rect, 
    svg.activeIcon circle,
    svg.activeIcon path
    {
        fill:url(#table-gradient);
    }
    

</style>