<template>
<div class="grid grid-cols-8 w-full mt-10 mb-10">
    <div @click="backClick">
        <svg v-if="backShow !== false" class=" w-5 pt-0 m-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <linearGradient x1="0" y1="0" x2="100%" y2="100%" id="back-gradient">
                <stop class="primary--gradient-stop1" offset="0"/>
                <stop class="primary--gradient-stop1" offset="0.2"/>
                <stop class="primary--gradient-stop2" offset="0.5"/>
                <stop class="primary--gradient-stop3" offset="1"/>
            </linearGradient>
            <g data-name="Layer 2">
                <g data-name="arrow-ios-back">
                    <rect width="24" height="24" transform="rotate(90 12 12)" opacity="0"/>
                    <path fill="url(#back-gradient)" d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"/>
                </g>
            </g>
        </svg>
    </div>
    <div class="col-span-6 text-center">
        <svg id="Logo" data-name="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 604.09 109.23">
            <path class="logo-path" d="M2.45,303.77V234l37.69-.13c0,3,0,6,0,9.08l-31.46.47q2.64-2.58,5.3-5.1-.22,13.07-.44,26.15l-5.18-1.25q14.25-1.19,28.52-2.25c0,3,0,5.92,0,8.88q-14.25,1.33-28.48,2.83c1.72-.84,3.45-1.66,5.18-2.48q.23,15.76.45,31.49Q8.19,302.7,2.45,303.77Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M65.65,293.3q.28-13.74.58-27.46-.09-16-.19-32.08l11.7,0q-.26,12.79-.49,25.61l.06,32.21Q71.47,292.4,65.65,293.3Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M108.35,287.51l.49-53.86,15.2,0c8.33,13.91,16.66,27.07,25,39.66l.4,0q-.49-19.78-1-39.68l11,0L159,282.22q-7.56.67-15.12,1.42c-8.34-12.56-16.67-25.7-25-39.59h-.4q.45,21.13.9,42.17Q113.84,286.86,108.35,287.51Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M180.29,280.47c6.53-16.14,13.05-31.76,19.55-47h15.5q9.38,22.35,18.81,43.8-6,.25-11.94.56-2.66-6.7-5.31-13.47l6.2,2.5q-15.75.6-31.48,1.42c2-1.06,4.09-2.1,6.14-3.13q-2.77,7.17-5.54,14.46Q186.27,280,180.29,280.47Zm17.37-15.58-3.88-3.25q13.58-.57,27.16-1L217,264.1q-5.2-13.4-10.39-27.13l6.37,3.25-10.9.11c2.14-1.14,4.29-2.26,6.43-3.37Q203.1,250.73,197.66,264.89Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M255.74,276.49q.09-21.53.2-43.06h11.6q-.33,20-.65,39.95l-5.2-3.32q15.6-.42,31.21-.57c0,2.11,0,4.21,0,6.32Q274.32,275.93,255.74,276.49Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M315.09,275.8l.48-19.91q-.17-11.23-.32-22.47l3.5-.21c1.79-.1,3.89-.2,6.29-.28s4.85-.15,7.35-.21,4.79-.09,6.85-.09A54.49,54.49,0,0,1,352.05,234a16.71,16.71,0,0,1,8.32,4.39,11.38,11.38,0,0,1,2.95,8.15,10.36,10.36,0,0,1-3.66,8.45c-2.46,2.07-6.06,3.46-10.8,4.2v.25q7.8,8.61,15.64,17.47-6.58-.24-13.16-.43-6.7-8.35-13.41-16.53l-11.13-.3q0,8.16,0,16.32C322.92,275.86,319,275.83,315.09,275.8ZM340,254.18c4.54,0,7.55-.55,9-1.8s2.19-3.26,2.18-6a11.33,11.33,0,0,0-.93-5.15,4.54,4.54,0,0,0-2.41-2.26,13.72,13.72,0,0,0-3.05-.59,43.06,43.06,0,0,0-4.66-.23c-2.73,0-5.27.05-7.6.16s-4.27.24-5.8.36q0,7.46-.07,14.91c2.21.14,4.46.27,6.77.39S338,254.15,340,254.18Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M398,279.33a56.65,56.65,0,0,1-10.64-1.6,27.71,27.71,0,0,1-8.91-3.92,17.89,17.89,0,0,1-6.06-7.19,26.82,26.82,0,0,1-2.23-11.48,25.57,25.57,0,0,1,2.08-11.26,16.71,16.71,0,0,1,5.91-6.87,24.69,24.69,0,0,1,8.83-3.46,56,56,0,0,1,10.64-1,52.87,52.87,0,0,1,10.66,1,23.66,23.66,0,0,1,8.79,3.65,17.94,17.94,0,0,1,6,7.38,29.83,29.83,0,0,1,2.26,12.13c.05,4.95-.63,9-2,12.09a15.6,15.6,0,0,1-5.9,7,22.25,22.25,0,0,1-8.74,3.09A52.26,52.26,0,0,1,398,279.33Zm0-6.63a32.61,32.61,0,0,0,6.86-.27,9.37,9.37,0,0,0,4.74-2.09,9.82,9.82,0,0,0,2.81-5,33.52,33.52,0,0,0,.88-8.93,36.39,36.39,0,0,0-1-8.93,11.25,11.25,0,0,0-2.8-5.2,9.86,9.86,0,0,0-4.82-2.43,40.51,40.51,0,0,0-14.06-.13,9.67,9.67,0,0,0-4.74,2.28A9.87,9.87,0,0,0,383.2,247a34.48,34.48,0,0,0-.79,8.62,33.2,33.2,0,0,0,1,8.64,9.68,9.68,0,0,0,7.62,7.52A37.46,37.46,0,0,0,398,272.7Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M458.75,284a43.84,43.84,0,0,1-13.28-3.15,17.59,17.59,0,0,1-8.38-7.33c-1.94-3.3-2.93-7.72-3-13.33q-.14-13.35-.26-26.68l11.6,0q.12,12.66.25,25.34a54.59,54.59,0,0,0,.69,9,11.79,11.79,0,0,0,2.05,5.2,7.58,7.58,0,0,0,3.88,2.56,30.88,30.88,0,0,0,6.36,1.07,28.18,28.18,0,0,0,6.45-.06,6.2,6.2,0,0,0,3.93-2,10.22,10.22,0,0,0,1.9-5.1,63.27,63.27,0,0,0,.45-9.37q-.12-13.29-.26-26.59l11.5,0q.14,14.28.28,28.55c.06,6.4-.84,11.29-2.71,14.61a13.2,13.2,0,0,1-8.18,6.51A36.47,36.47,0,0,1,458.75,284Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M495.24,286.88q-.24-26.62-.49-53.24l15.2,0c8.59,14.72,17.18,30.27,25.73,46.83l.4,0q-.87-23.48-1.73-46.8l11,0q.18,29.89.38,59.8-7.53-1.11-15.05-2.16c-8.56-16.6-17.14-32.21-25.73-47h-.4q.84,21.82,1.68,43.79Q500.74,287.51,495.24,286.88Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M579.15,300.19c-1.78-.31-3.79-.7-6-1.17s-4.39-.95-6.51-1.44-4-.95-5.62-1.36l-3.34-.83q-.17-30.84-.32-61.68c.6,0,1.71-.05,3.35-.16l5.79-.4c2.24-.14,4.55-.28,7-.4s4.57-.18,6.5-.18c5.53,0,10,.89,13.56,2.75a20,20,0,0,1,8.1,7.71,33.13,33.13,0,0,1,3.89,11.38,84.26,84.26,0,0,1,1,13.56c0,9.27-1.18,16.49-3.52,21.52s-5.57,8.3-9.67,9.84S584.52,301.12,579.15,300.19Zm-.32-9.36a26.51,26.51,0,0,0,6.85.37,8.53,8.53,0,0,0,3.48-1.1,9.83,9.83,0,0,0,2.43-3.48,28,28,0,0,0,1.87-7.32,88.08,88.08,0,0,0,.73-12.94A78.82,78.82,0,0,0,593,252q-1.15-6.09-3.76-8.07a7.55,7.55,0,0,0-2.85-1.37,26.77,26.77,0,0,0-6.24-.67c-2.93-.07-5.43,0-7.49.1s-3.87.27-5.4.41q.84-2.41,1.68-4.86l.24,55.86q-1-2.66-1.92-5.26,2.49.61,5.08,1.35A51.57,51.57,0,0,0,578.83,290.83Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M160.34,341.07V304.78h6l-.15,17.16L163.51,320h19.24l-2.65,1.93-.16-17.16h6v36.29h-6l.16-17.83,2.65,2H163.51l2.71-2,.15,17.83Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M227.73,341.8a18.1,18.1,0,0,1-5.51-.83,11.11,11.11,0,0,1-4.6-2.89,13.7,13.7,0,0,1-3.12-5.77,33.46,33.46,0,0,1-1.12-9.49,32.75,32.75,0,0,1,1.12-9.39,14.06,14.06,0,0,1,3.09-5.72,10.64,10.64,0,0,1,4.61-2.86,19,19,0,0,1,5.53-.8,18.64,18.64,0,0,1,5.54.8,10.68,10.68,0,0,1,4.55,2.86,14.07,14.07,0,0,1,3.1,5.72,33.15,33.15,0,0,1,1.11,9.39,33.86,33.86,0,0,1-1.11,9.49,14,14,0,0,1-3.1,5.77,11,11,0,0,1-4.55,2.89A18,18,0,0,1,227.73,341.8Zm0-5.36a11.25,11.25,0,0,0,3.56-.49,4.92,4.92,0,0,0,2.48-1.87,10.36,10.36,0,0,0,1.48-4.06,40.68,40.68,0,0,0,.49-7.1,42.63,42.63,0,0,0-.47-7,10.42,10.42,0,0,0-1.43-4.08,4.7,4.7,0,0,0-2.49-1.9,12.06,12.06,0,0,0-3.67-.5,11.77,11.77,0,0,0-3.64.5,4.73,4.73,0,0,0-2.47,1.9,10.13,10.13,0,0,0-1.4,4.08,44.77,44.77,0,0,0-.44,7.1,42.86,42.86,0,0,0,.46,7.07,10.2,10.2,0,0,0,1.43,4,4.77,4.77,0,0,0,2.5,1.87A11.81,11.81,0,0,0,227.73,336.44Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M269.44,341.07V304.78h6l-.26,33.9-2.71-2.91,16.18-.11v5.41Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M324.4,341.8c-.94,0-2,0-3.15-.08s-2.29-.12-3.4-.21-2.09-.17-2.94-.26l-1.74-.18V304.72c.31,0,.89,0,1.74-.1l3-.23c1.16-.09,2.36-.17,3.61-.24s2.37-.1,3.38-.1a14.69,14.69,0,0,1,7.05,1.48,10.62,10.62,0,0,1,4.21,4,17.19,17.19,0,0,1,2,5.88,43.66,43.66,0,0,1,.54,7c0,4.81-.61,8.64-1.84,11.46a12.31,12.31,0,0,1-5.07,6.06A14.91,14.91,0,0,1,324.4,341.8Zm-.16-5.2a13.33,13.33,0,0,0,3.59-.36,5.22,5.22,0,0,0,1.82-.89,6.6,6.6,0,0,0,1.28-2.08,17.63,17.63,0,0,0,1-4.08,50.49,50.49,0,0,0,.39-7,43.73,43.73,0,0,0-.59-7.67q-.6-3.25-1.95-4.29a3.83,3.83,0,0,0-1.49-.71,13.25,13.25,0,0,0-3.25-.28q-2.28,0-3.9.15c-1.07.11-2,.21-2.8.32l.88-2.81v32l-1-2.86c.87.1,1.75.22,2.65.36A22.62,22.62,0,0,0,324.24,336.6Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M366.16,341.07V304.78h19.6V310l-16.28-.21,2.81-2.65-.41,15.28-2.66-2.28h15.24v5H369.22l2.66-2.44.41,16-2.81-2.7,16.28-.11v5.2Z" transform="translate(-2.45 -232.57)"/>
            <path class="logo-path" d="M412.64,341.07l1.3-36.29h8.11l7.28,22.36h.32L437,304.78h8.06l1.25,36.29h-6l-.11-29.85h-.36l-7,21.74h-6.76l-7-21.74h-.31l-.16,29.85Z" transform="translate(-2.45 -232.57)"/>
        </svg>

        <!-- <svg id="Logo" data-name="Logo" version="1.0" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 604.09 109.23"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,116.000000) scale(0.100000,-0.100000)"
            fill="#000000" stroke="none">
            <path class="logo-path" d="M57 1033 l-57 -4 2 -287 3 -287 29 -3 c15 -2 39 -1 51 2 21 5 22 9
            14 48 -4 24 -8 85 -9 136 l0 92 73 0 c79 0 97 9 97 48 0 25 0 25 -85 19 l-86
            -5 3 87 3 86 74 3 c41 2 94 -1 118 -6 41 -9 44 -8 49 14 10 41 -1 51 -67 57
            -61 7 -98 7 -212 0z"/>
            <path class="logo-path" d="M1002 1034 c-6 -3 -9 -11 -5 -17 4 -7 10 -118 13 -247 l5 -235 40 0
            40 0 -3 125 c-1 69 -5 153 -8 188 -4 34 -2 62 2 62 5 0 50 -60 99 -132 149
            -218 158 -228 208 -228 l42 0 0 236 c-1 256 0 254 -58 254 l-28 0 3 -176 c2
            -145 1 -172 -10 -157 -197 282 -233 323 -287 328 -22 3 -46 2 -53 -1z"/>
            <path class="logo-path" d="M1759 1033 c-177 -3 -197 -5 -201 -21 -10 -36 0 -40 83 -35 l79 6 0
            -201 c0 -194 1 -202 21 -213 12 -6 35 -8 53 -5 l31 6 -3 205 -3 204 78 3 c77
            3 78 3 81 31 2 18 -1 26 -10 25 -7 -2 -101 -4 -209 -5z"/>
            <path class="logo-path" d="M2231 1028 c-30 -36 -223 -440 -215 -448 5 -5 23 -12 39 -15 29 -6
            31 -4 67 75 l36 80 100 0 99 0 34 -67 c32 -65 36 -68 73 -71 21 -2 43 1 47 5
            5 5 -19 64 -55 133 -35 69 -81 165 -102 213 -27 66 -43 91 -61 98 -33 12 -50
            11 -62 -3z m69 -178 c16 -40 30 -74 30 -76 0 -2 -31 -4 -70 -4 -38 0 -70 2
            -70 4 0 10 72 157 76 154 2 -2 17 -37 34 -78z"/>
            <path class="logo-path" d="M2750 1034 c-90 -30 -120 -60 -120 -121 0 -72 35 -99 171 -134 106
            -26 131 -42 127 -76 -8 -69 -132 -89 -228 -37 -51 28 -51 28 -71 8 -12 -12
            -17 -27 -14 -37 12 -29 93 -52 185 -52 143 1 210 47 210 143 0 59 -35 86 -155
            117 -116 31 -145 47 -145 79 0 41 35 61 104 61 53 0 68 -4 100 -28 35 -27 38
            -28 61 -13 66 43 -16 97 -144 95 -36 0 -73 -3 -81 -5z"/>
            <path class="logo-path" d="M3136 1024 l-28 -16 55 -56 c30 -32 71 -79 91 -105 35 -47 36 -51 36
            -137 0 -116 2 -120 59 -120 l47 0 -4 100 -4 99 62 68 c110 120 129 163 77 177
            -43 11 -53 6 -79 -46 -14 -29 -40 -72 -57 -96 l-32 -43 -39 58 c-86 127 -122
            150 -184 117z"/>
            <path class="logo-path" d="M4085 1022 c-69 -24 -112 -56 -136 -104 -64 -126 -11 -269 124 -328
            91 -40 261 -36 301 8 15 18 26 165 12 179 -3 3 -25 7 -48 7 l-43 1 3 -72 c5
            -84 -4 -93 -88 -93 -93 0 -156 48 -180 139 -18 67 -5 125 37 172 61 68 152 76
            217 18 27 -23 40 -28 60 -23 29 7 41 35 27 62 -27 50 -186 69 -286 34z"/>
            <path class="logo-path" d="M4697 1024 c-165 -51 -213 -278 -87 -405 57 -57 127 -83 225 -83 84
            0 135 16 181 57 56 49 77 100 77 187 0 126 -59 213 -169 246 -60 18 -165 17
            -227 -2z m220 -72 c98 -70 104 -273 9 -331 -95 -59 -220 -20 -267 84 -26 56
            -25 147 2 195 33 58 81 81 159 78 48 -3 74 -9 97 -26z"/>
            <path class="logo-path" d="M5252 785 c2 -208 6 -257 17 -264 17 -11 217 -24 276 -19 37 3 40 5
            40 33 0 29 -1 30 -45 27 -25 -2 -78 0 -117 3 l-73 7 0 228 c0 228 0 228 -22
            234 -13 3 -36 6 -51 6 l-28 0 3 -255z"/>
            <path class="logo-path" d="M5765 1033 l-60 -4 7 -87 c4 -48 7 -167 6 -264 -2 -199 1 -208 62
            -208 l38 0 -6 130 -5 129 84 3 84 3 0 30 0 30 -82 3 -83 3 0 86 0 86 103 -6
            c56 -3 110 -9 120 -13 16 -6 18 -2 15 32 -3 38 -4 39 -48 46 -46 7 -127 7
            -235 1z"/>
            <path class="logo-path" d="M562 963 c-13 -36 -62 -156 -109 -266 -47 -109 -81 -202 -77 -207 5
            -4 23 -11 40 -14 l30 -7 38 96 38 95 69 2 c147 4 129 11 161 -67 16 -38 34
            -73 41 -77 16 -11 55 -10 76 1 15 9 12 20 -35 118 -28 59 -73 167 -100 238
            -26 72 -52 136 -57 143 -5 6 -28 12 -51 12 l-41 0 -23 -67z m104 -149 c23 -62
            28 -87 19 -90 -7 -3 -39 -4 -72 -2 l-60 3 35 93 c19 51 37 90 40 87 3 -3 20
            -44 38 -91z"/>
            <path class="logo-path" d="M1719 376 c-2 -2 -3 -59 -1 -125 3 -114 4 -121 24 -121 18 0 20 4 14
            45 l-7 45 38 0 c58 0 83 22 83 75 0 58 -32 85 -100 85 -26 0 -49 -2 -51 -4z
            m95 -32 c11 -4 16 -19 16 -45 0 -43 -25 -65 -60 -54 -16 5 -20 15 -20 56 0 45
            2 49 24 49 13 0 31 -3 40 -6z"/>
            <path class="logo-path" d="M1939 376 c-2 -2 -3 -59 -1 -125 3 -109 5 -121 21 -121 17 0 18 8 13
            111 l-5 110 39 -3 c37 -3 39 -5 42 -41 3 -31 0 -39 -20 -46 -13 -5 -26 -7 -30
            -4 -16 9 -6 -23 21 -72 23 -42 33 -51 59 -53 36 -4 41 7 12 25 -11 7 -26 26
            -35 43 -14 28 -14 31 1 37 9 3 22 20 29 36 16 39 3 80 -31 96 -24 10 -106 16
            -115 7z"/>
            <path class="logo-path" d="M2214 361 c-40 -24 -58 -69 -51 -128 7 -67 48 -103 116 -103 60 0 96
            26 111 81 32 116 -78 210 -176 150z m114 -32 c39 -41 36 -127 -6 -159 -32 -26
            -55 -25 -88 3 -38 33 -45 110 -14 150 27 34 80 37 108 6z"/>
            <path class="logo-path" d="M2470 255 c0 -118 1 -125 20 -125 18 0 20 7 20 54 0 54 0 55 32 58
            46 4 49 28 4 28 -35 0 -36 1 -36 41 l0 41 50 -4 c41 -2 50 0 48 12 -2 11 -20
            16 -71 18 l-67 3 0 -126z"/>
            <path class="logo-path" d="M2670 255 l0 -126 72 3 c49 2 73 7 76 16 3 11 -6 13 -39 12 -69 -4
            -69 -4 -69 45 0 45 0 45 35 45 24 0 35 5 35 15 0 10 -11 15 -35 15 -34 0 -35
            2 -35 36 l0 36 50 -4 c41 -2 50 0 48 12 -2 11 -20 16 -71 18 l-67 3 0 -126z"/>
            <path class="logo-path" d="M2912 368 c-27 -13 -38 -51 -26 -86 6 -18 24 -31 61 -45 28 -10 54
            -25 57 -33 16 -42 -39 -68 -88 -40 -21 12 -32 14 -40 6 -18 -19 22 -40 75 -40
            39 0 51 5 68 26 40 51 21 91 -54 117 -43 15 -61 36 -52 61 8 21 65 21 89 0 16
            -16 18 -15 24 1 15 37 -60 59 -114 33z"/>
            <path class="logo-path" d="M3142 368 c-27 -13 -38 -51 -26 -86 6 -18 24 -31 61 -45 28 -10 54
            -25 57 -33 16 -42 -39 -68 -88 -40 -21 12 -32 14 -40 6 -18 -19 22 -40 75 -40
            39 0 51 5 68 26 40 51 21 91 -54 117 -43 15 -61 36 -52 61 8 21 65 21 89 0 16
            -16 18 -15 24 1 15 37 -60 59 -114 33z"/>
            <path class="logo-path" d="M3340 256 c0 -113 2 -126 18 -126 15 0 17 12 17 122 0 109 -2 123
            -17 126 -16 3 -18 -8 -18 -122z"/>
            <path class="logo-path" d="M3504 361 c-40 -24 -58 -69 -51 -128 7 -67 48 -103 116 -103 60 0 96
            26 111 81 32 116 -78 210 -176 150z m114 -32 c39 -41 36 -127 -6 -159 -32 -26
            -55 -25 -88 3 -38 33 -45 110 -14 150 27 34 80 37 108 6z"/>
            <path class="logo-path" d="M3760 255 c0 -117 1 -125 19 -125 18 0 19 6 15 98 -5 109 -8 110 64
            -13 26 -44 55 -81 65 -83 15 -3 17 8 17 122 0 105 -2 126 -15 126 -12 0 -15
            -17 -15 -92 l0 -93 -41 70 c-45 78 -76 115 -96 115 -10 0 -13 -28 -13 -125z"/>
            <path class="logo-path" d="M4047 260 c-44 -111 -50 -140 -28 -140 4 0 15 18 24 40 15 38 17 40
            61 40 42 0 45 -2 57 -35 8 -22 20 -35 31 -35 10 0 18 1 18 3 0 2 -18 53 -41
            113 -22 60 -42 115 -44 122 -12 34 -34 2 -78 -108z m76 15 l16 -45 -34 0 -34
            0 16 45 c8 25 16 45 18 45 2 0 10 -20 18 -45z"/>
            <path class="logo-path" d="M4268 373 c-2 -4 -4 -60 -4 -123 l1 -115 68 -3 c56 -2 67 0 67 13 0
            13 -9 16 -37 14 -67 -4 -64 -9 -62 109 1 89 -1 108 -14 110 -8 2 -17 -1 -19
            -5z"/>
            </g>
        </svg> -->

    
    </div>
    <div @click="myAccountClick">
        <svg v-if="accountShow !== false" class="w-5 pt-0 m-auto"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g data-name="Layer 2">
                <g data-name="person">
                    <rect width="24" height="24" opacity="0"/>
                    <path fill="url(#table-gradient)" d="M12 11a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm0-6a2 2 0 1 1-2 2 2 2 0 0 1 2-2z"/>
                    <path fill="url(#table-gradient)" d="M12 13a7 7 0 0 0-7 7 1 1 0 0 0 2 0 5 5 0 0 1 10 0 1 1 0 0 0 2 0 7 7 0 0 0-7-7z"/>
                </g>
            </g>
        </svg>
    </div>
</div>
  
</template>

<script>
export default {
    name: "Header",
    props: {
        backView: String,
        backShow: {
            default: false,
        },  
        accountShow: {
            default: false,
        },
    },
    emits: ["backEvent", "myAccountEvent"],
    methods: {
        backClick: function() {
            this.$emit('backEvent', 'backView');
        },
        myAccountClick: function() {
            this.$emit('myAccountEvent');
        }
    }
}

</script>

<style scoped>
    #Logo{
        margin-top: -5px;
        margin: auto;
        width: 160px;
    }

</style>