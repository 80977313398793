<template>
    <div class="cell bg-white border-solid border-white border "><p class="text-xs">{{ hole }}</p></div>
    <div class="cell bg-white border-solid border-white border "><p class="text-xs">{{ par }}</p></div>
    <div class="cell bg-white border-solid border-white border ">
        <p :class="{'double_digit_text': doubleDigitScore}" class="text-xs">{{ score }}</p>
        <svg class="z-10 absolute" height="40" width="80" xmlns="http://www.w3.org/2000/svg">
            <circle v-if="birdie || eagle" class="circle" cx="40px" cy="12px" r="9" stroke="url(#table-gradient)" stroke-width="0.5" fill="none"/>
            <circle v-if="eagle" class="second_circle" cx="40px" cy="12px" r="11" stroke="url(#table-gradient)" stroke-width="0.5" fill="none"/>
            <rect v-if="bogey || doubleBogey" class="square" stroke="url(#table-gradient)" stroke-width="0.5" fill="none"/>
            <rect v-if="doubleBogey"  class="second_square" stroke="url(#table-gradient)" stroke-width="0.5" fill="none"/>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'PlayerPerformanceScore',
        props: {
            hole: Number,
            par: Number,
            score: Number
        },
        computed: {
            doubleDigitScore: function() {
                if(this.score > 9){
                    return true;
                }
                else{
                    return false;
                }
            },

            birdie: function() {
                if(this.score == this.par -1){ return true; }
                else { return false; }
               
            },

            eagle: function() {
                if(this.score == this.par -2){ return true; }
                else { return false; }
                
            },

            bogey: function() {
                if(this.score == this.par +1){ return true; }
                else { return false; }
                 
            },

            doubleBogey: function() {
                if(this.score == this.par +2){ return true; }
                else { return false; }
                
            },
        }
}
</script>

<style scoped>
    .cell {
        position: relative;
        height: 25px;
    }
    
    .cell p.double_digit_text {
        left: 33px;
    }

    .cell p{
        position: absolute;
        left: 36px;
        top: 4px;
    }

    .square {
        x: 31px;
        y: 3px;
        width: 18px;
        height: 18px;
    }

    .second_square {
        x: 29px;
        y: 1px;
        width: 22px;
        height: 22px;
    }

</style>