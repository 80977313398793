<template>
<div>
  <div class="container text-center min-h-screen">
      <div class="linear-gradient-holder">
        <svg class="" xmlns="http://www.w3.org/2000/svg" width="0" height="0" viewBox="0 0 0 0">
            <linearGradient x1="0" y1="0" x2="100%" y2="100%" id="table-gradient">
                <stop class="primary--gradient-stop1" offset="0"/>
                <stop class="primary--gradient-stop1" offset="0.2"/>
                <stop class="primary--gradient-stop2" offset="0.5"/>
                <stop class="primary--gradient-stop3" offset="1"/>
            </linearGradient>     
        </svg>
      </div>

      <table-event v-if="eventInfoActive" :event="focusedEvent" @myAccountEvent="myAccountView"/>
      <table-leader-board v-if="leaderBoardActive" :event="focusedEvent" @playerEvent="playerPerformanceShow" @myAccountEvent="myAccountView"/>
      <table-team-board v-if="teamBoardActive" @claimSeatEvent="claimSeatView" :event="focusedEvent" @editTeamEvent="editTeam" @playerEvent="playerPerformanceShow" @myAccountEvent="myAccountView" />
      <player-performance-card v-if="playerPerformanceCardActive" @backEvent="backView" @myAccountEvent="myAccountView"
        :backItem="backItem" 
        :playerId="playerId" 
        :player_name="player_name" 
        :player_photo="player_photo" 
        :player_owgr="player_owgr" 
        :player_birth_date="player_birth_date" 
        :player_country="player_country" 
        :player_position="player_position" 
        :player_sup="player_sup" 
        :player_total_score="player_total_score" 
        :player_total_thru="player_total_thru"
      />

      <my-account v-if="myAccountActive" @backEvent="backView"/>


  </div>

  <table-bar :teamBoardActive="teamBoardActive" :leaderBoardActive="leaderBoardActive" :eventInfoActive="eventInfoActive" @teamBoardEvent="teamBoardView" @leaderBoardEvent="leaderBoardView" @eventInfoEvent="eventInfoView"/>
</div>
</template>

<script>

import PlayerPerformanceCard from '@/components/Table/PlayerPerformanceCard.vue'
import TableEvent from '@/components/Table/TableEvent.vue'
import TableLeaderBoard from '@/components/Table/TableLeaderBoard.vue'
import TableTeamBoard from '@/components/Table/TableTeamBoard.vue'
import TableBar from '@/components/Table/Elements/TableBar.vue'
import MyAccount from '../components/Table/MyAccount.vue'

import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  components: {
    PlayerPerformanceCard,
    TableEvent,
    TableLeaderBoard,
    TableTeamBoard,
    TableBar,
    MyAccount
  },

  created() {
    this.init()
  },

  computed: {
    ...mapState({
      focusedEvent: state => state.event.name,
      eventStatus: state => state.eventStatus,
      currentTableId: state => state.currentTableId,
      tables: state => state.tables,
    }),
    
  },

  data () {
    return {
      // UI
      teamBoardActive: true,
      leaderBoardActive: false,
      eventInfoActive: false,
      playerPerformanceCardActive: false,
      myAccountActive: false,
      backItem: null,
      scrollPositionY: null,
      
      // selected player details
      playerId: null,
      player_name: null,
      player_photo: null,
      player_birth_date: null,
      player_country: null,
      player_owgr: null,
      player_position: null,
      player_sup: null,
      player_total_score: null,
      player_total_thru: null,

    }
  },

  methods: {

    ...mapGetters([
        'GET_LEADERBOARD_BY_PLAYER'
    ]),

    ...mapActions({
        metricAction: 'metric',
        writeCurrentTableIdAction: 'writeCurrentTableId'
    }),

    init: function(){
      
      // Set first visbile board
      if (this.eventStatus == 'over'){
        this.teamBoardActive = false;
        this.eventInfoActive = true;
      }

      // Get Main Table
      let mainTableId = '';
      this.tables.forEach(item => {
        if(item.starting_round == 1){
          mainTableId = item.id;
        }
      });

      // Write Main Table Id as Current Table Id
      this.writeCurrentTableIdAction(mainTableId);

      console.log('*** Current table Id *** - '+this.currentTableId);
      
      
    },

    leaderBoardView: function() {
      this.metricAction('leaderBoard press');

      this.teamBoardActive = false;
      this.leaderBoardActive = true;
      this.eventInfoActive = false;
      this.playerPerformanceCardActive = false;
      this.myAccountActive = false;

      this.setScroll();

    },

    teamBoardView: function() {
      this.metricAction('teamBoard press');
      
      this.teamBoardActive = true;
      this.leaderBoardActive = false;
      this.eventInfoActive = false;
      this.playerPerformanceCardActive = false;
      this.myAccountActive = false;

      this.setScroll();
    },

    eventInfoView: function() {
      this.metricAction('eventInfo press');

      this.teamBoardActive = false;
      this.leaderBoardActive = false;
      this.eventInfoActive = true;
      this.playerPerformanceCardActive = false;
      this.myAccountActive = false;

      this.setScroll();
    },

    myAccountView: function(backItem) {
      this.backItem = backItem;
      this.teamBoardActive = false;
      this.leaderBoardActive = false;
      this.eventInfoActive = false;
      this.playerPerformanceCardActive = false;
      this.myAccountActive = true;
      this.metricAction('myAccount press');

      this.setScroll();
    },

    backView: function(){
      this.teamBoardActive = false;
      this.leaderBoardActive = false;
      this.eventInfoActive = false;
      this.playerPerformanceCardActive = false;
      this.myAccountActive = false;

      let backItem = this.backItem;
      if(backItem === 'teamBoard') {
        this.teamBoardActive = true;
        this.metricAction('teamBoard back press');
        this.setScroll();
      }
      if(backItem === 'leaderBoard') {
        this.leaderBoardActive = true;
        this.metricAction('leaderBoard back press');
        setTimeout(() => { this.setScroll(this.scrollPositionY); }, 100); // delay to ensure component hieght renders first
        
      }
      if(backItem === 'eventInfo') {
        this.eventInfoActive = true;
        this.metricAction('eventInfo back press');
        this.setScroll();
      }

      
    },

    playerPerformanceShow: function(data){
      console.log(data.playerId);
      this.playerId = data.playerId;

      //get additional VueX data from Leaderboard
      let player = this.GET_LEADERBOARD_BY_PLAYER()(data.playerId);
      
      this.player_name = player[0]['name'];
      this.player_photo = player[0]['player_img'];
      this.player_birth_date = player[0]['birth_date'];
      this.player_country = player[0]['country'];
      this.player_owgr = player[0]['owgr'];
      this.player_position = player[0]['position'];
      this.player_sup = player[0]['sup'];
      this.player_total_score = player[0]['total_score'];
      this.player_total_thru = player[0]['total_thru'];
      if(player[0]['total_thru'] == null){
        this.player_total_thru = 0;
      }

      this.backItem = data.originator;

      this.teamBoardActive = false;
      this.leaderBoardActive = false;
      this.eventInfoActive = false;
      this.playerPerformanceCardActive = true;
      this.myAccountActive = false;

      const metricText = data.originator + ' player press';
      this.metricAction(metricText);

      this.setScroll();
    },

    claimSeatView: function(){
      this.setScroll();
      this.metricAction('claimSeat press');
      this.$router.push('claimSeat');
    },

    editTeam: function (){
      this.setScroll();
      this.metricAction('editTeam press');
      this.$router.push('editTeam');
    },

    setScroll(y) {
      if(y){
          window.scrollTo(0,y);
      }
      else {
          window.scrollTo(0,0);
      }
    }

  }
}
</script>
