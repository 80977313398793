<template>
  <img class="w-full rounded border PlayerListCardImg relative" alt="" src="@/assets/images/playingCardBack.png">
</template>

<script>
export default {
    name: "PlayerListCardBack",
}
</script>

<style scoped>
    img {
        opacity: 0.8;
        object-fit: cover;
        margin-top: 0.3rem;
    }

</style>