<template>
  <p class="mt-20" >No Teams</p>
  <p>No seats have been claimed on this table, which means we don't have any live teams to show.</p>
  <p>This page will be updated the moment a team has been entered on this table for this event.</p>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>