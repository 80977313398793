<template>
    <main-header />
    <div class="container">
        <div class="text-center mb-5">
                <h1>{{ event }}</h1>
                <p class="text-xs"></p>
        </div>  
        <div class="bg-white rounded-md w-full shadow-md text-blue-4 p-3 text-center" >
        <p class="mb-10">Your payment was successful.</p>
        <p>Enjoy the golf!</p>    
        <Button btn-text="VIEW TABLE" @click="viewTable"/>

        </div>


    </div>
        


  
</template>

<script>
import Button from '../Base/Button.vue'
import MainHeader from '../Base/MainHeader.vue'
export default {
    name: "PaymentConfirmed",
    components: { 
        Button, 
        MainHeader 
    },
    emits: ["viewTableEvent"],
    methods: {
        
        viewTable: function(){
            this.$emit("viewTableEvent");
        }
    }

}
</script>

<style>

</style>