import { createStore } from 'vuex'
import axios from 'axios'
import auth from './auth'
import Vue from 'vue'

export default createStore({
  state: {
    // user - auth.js
    // authenticated - auth.js
    leaderBoard:[],
    teetimes:[],
    performances:[],
    teams:[],
    tables:[],
    event:[],
    eventStatus: false,
    hasSeat: false,
    eventOver:[],
    entrylist:[],
    currentTableId: false,
    running_leaderBoard: false,
    running_teetimes: false,
    running_performances: false,
    running_teams: false,
    running_tables: false,
    running_event: false,
    running_pay: false,
  },

  mutations: {

    SET_CURRENT_TABLE_ID(state, tableId){
      state.currentTableId = tableId
    },

    SET_LEADERBOARD(state, leaderBoard){
      state.leaderBoard = leaderBoard
    },

    SET_TEETIMES(state, teetimes){
      state.teetimes = teetimes
    },

    SET_PERFORMANCES(state, performances){
      state.performances = performances
    },

    SET_TEAMS(state, teams){
      state.teams = teams
    },

    CLEAR_TEAM_PLAYERS(state, team_id){
      let team_table = state.teams.find(item => item.table_id == state.currentTableId);
      let users_team = team_table.teams.find(item => item.team_id == team_id);
      
      users_team.player1Id = null;
      users_team.player2Id = null;
      users_team.player3Id = null;
      users_team.player4Id = null;
      users_team.player1img = null;
      users_team.player2img = null;
      users_team.player3img = null;
      users_team.player4img = null;
      
    },

    // Called after CLEAR_TEAM_PLAYERS has been called
    WRITE_TEAM_PLAYER(state, payload){
      let team_table = state.teams.find(item => item.table_id == state.currentTableId);
      let users_team = team_table.teams.find(item => item.team_id == payload.team_id);

      // iterate through each player slot
      if ( users_team.player1Id == null ){ users_team.player1Id = payload.player_id; users_team.player1img = payload.player_img;}
      else if ( users_team.player2Id == null ){ users_team.player2Id = payload.player_id; users_team.player2img = payload.player_img;}
      else if ( users_team.player3Id == null ){ users_team.player3Id = payload.player_id; users_team.player3img = payload.player_img;}
      else if ( users_team.player4Id == null ){ users_team.player4Id = payload.player_id; users_team.player4img = payload.player_img;}
    },

    SET_TABLES(state, tables){
      state.tables = tables
    },

    SET_EVENT(state, event){
      state.event = event
    },

    SET_EVENTSTATUS(state, event){
      state.eventStatus = event
    },

    SET_EVENTOVER(state, event){
      state.eventOver = event
    },

    SET_HASSEAT(state, event){
      let table = state.tables.filter(item => item.id == state.currentTableId);
      table[0].hasSeat = event;
      console.log('SEAT STATUS - ' +JSON.stringify(table[0]));
      
    },

    SET_ENTRYLIST(state, event){
      state.entrylist = event
    },

    

    // running - to avoid concurrent API requests for same resource on multiple click events

    SET_RUNNING_LEADERBOARD(state, event){
      state.running_leaderBoard = event
    },

    SET_RUNNING_TEETIMES(state, event){
      state.running_teetimes = event
    },

    SET_RUNNING_PERFORMANCES(state, event){
      state.running_performances = event
    },

    SET_RUNNING_TEAMS(state, event){
      state.running_teams = event
    },

    SET_RUNNING_TABLES(state, event){
      state.running_tables = event
    },

    SET_RUNNING_EVENT(state, event){
      state.running_event = event
    },

    SET_RUNNING_PAY(state, event){
      state.running_pay = event
    },

  },

  getters: {


    GET_EVENTSTATUS: state => {
      return state.eventStatus
    },

    GET_ENTRYLIST: state => {
      return state.entrylist
    },

    GET_EVENTOVER: state => {
      return state.eventOver
    },

    GET_HASSEAT_BY_TABLE: state => _currentTableId =>{
      let table = state.tables.filter(item => item.id == _currentTableId);
      return table[0].hasSeat;
    },

    GET_LEADERBOARD: state => {
      return state.leaderBoard
    },

    GET_TEETIMES: state => {
      return state.teetmes
    },

    GET_PERFORMANCES: state => {
      return state.performances
    },

    GET_TEAMS: state => {
      return state.teams
    },

    GET_TABLES: state => {
      return state.tables
    },

    

    GET_PERFORMANCES_BY_PLAYER_AND_ROUND: state => _player_id => _round => {
      return state.performances.filter(item => item.player_id == _player_id && item.round == _round);
    },

    GET_TEETIME_BY_PLAYER_AND_ROUND: state => _player_id => _round => {
      return state.teetimes.filter(item => item.player_id == _player_id && item.round == _round);
    },

    GET_LEADERBOARD_BY_PLAYER: state => _player_id =>{
      return state.leaderBoard.filter(item => item.player_id == _player_id);
    },

    GET_ENTRYLIST_BY_SELECTED: state => {
      return state.entrylist.filter(item => item.selected == true && item.tableId == state.currentTableId);
    },

    // running

    GET_RUNNING_LEADERBOARD: state => {
      return state.running_leaderBoard
    },

    GET_RUNNING_TEETIMES: state => {
      return state.running_teetimes
    },

    GET_RUNNING_PERFORMANCES: state => {
      return state.running_performances
    },

    GET_RUNNING_TEAMS: state => {
      return state.running_teams
    },

    GET_RUNNING_TABLES: state => {
      return state.running_tables
    },

    GET_RUNNING_EVENT: state => {
      return state.running_event
    },

    GET_RUNNING_PAY: state => {
      return state.running_pay
    },
  },

  actions: {

    writeCurrentTableId({ commit }, tableId){
      commit('SET_CURRENT_TABLE_ID', tableId);
    },

    async eventStatus ({ commit }){
      await axios.post('/api/eventStatus')
      .then((response) => {

        commit('SET_EVENTSTATUS', response.data.eventStatus)

      }, (error) => {
        console.log(error);
      }); 
    },

    async hasSeat ({ commit, getters }){
      let userId = getters['auth/user']['id'];
      await axios.post('/api/hasSeat', {'id': userId })
      .then((response) => {
        if(response.data.hasSeat == 'true'){
          commit('SET_HASSEAT', true);
        }
        else {
          commit('SET_HASSEAT', false);
        }
        let seat = getters.GET_HASSEAT;
        console.log('from VueX state - hasSeat : '+seat);
      }, (error) => {
        console.log(error);
      }); 
    },

    async entrylist ({ commit, getters }){
      await axios.post('/api/entrylist')
      .then((response) => {
        
        commit('SET_ENTRYLIST', response.data.entrylist);

        let players  = getters.GET_ENTRYLIST;
        console.log('entrylist - '+ JSON.stringify(players));
        

      }, (error) => {
        console.log(error);
      }); 
    },

    async eventOver ({ commit, getters, state }){
      let userId = getters['auth/user']['id'];
      await axios.post('/api/eventOver', {'id': userId })
      .then((response) => {
        commit('SET_EVENTOVER', response.data.eventOver);
        console.log('eventOver set');
        console.log(response.data.eventOver);
        
      }, (error) => {
        console.log(error);
      }); 
    },

    async leaderBoard ({ commit, getters }){
      let isAlreadyRunning = getters.GET_RUNNING_LEADERBOARD;
      if( isAlreadyRunning == false ) {
        commit('SET_RUNNING_LEADERBOARD', true);
        await axios.post('/api/leaderBoard')
        .then((response) => {
          commit('SET_LEADERBOARD', response.data.leaderBoard);
          commit('SET_RUNNING_LEADERBOARD', false);
          console.log('LeaderBoard should be set');
          console.log(response.data.leaderBoard);
          
        }, (error) => {
          console.log(error);
        }); 
      }
    },

    async teetimes ({ commit, getters }){
      let isAlreadyRunning = getters.GET_RUNNING_TEETIMES;
      if( isAlreadyRunning == false ) {
        commit('SET_RUNNING_TEETIMES', true);
        await axios.post('/api/teetimes')
        .then((response) => {
          commit('SET_TEETIMES', response.data.teetimes);
          commit('SET_RUNNING_TEETIMES', false);
          console.log('Teetimes should be set');
          console.log(response.data.teetimes);
          
        }, (error) => {
          console.log("teetimes API error -"+error);
        }); 
      }
    },

    async performances ({ commit, getters }){
      
      let isAlreadyRunning = getters.GET_RUNNING_PERFORMANCES;
      if( isAlreadyRunning == false ) {
        commit('SET_RUNNING_PERFORMANCES', true);
        await axios.post('/api/performances')
        .then((response) => {
          commit('SET_PERFORMANCES', response.data.performances);
          commit('SET_RUNNING_PERFORMANCES', false);
          
          console.log('performances should be set');
          console.log(response.data.performances);
        }, (error) => {
          console.log(error);
        }); 
      }
      
    },

    async teams ({ commit, getters }){
      let isAlreadyRunning = getters.GET_RUNNING_TEAMS;
      if( isAlreadyRunning == false ) {
        commit('SET_RUNNING_TEAMS', true);
        let userId = getters['auth/user']['id'];

        //Cal API
        await axios.post('/api/teams', {'id': userId})
        .then((response) => {
          console.log(response.data.teams);
          commit('SET_TEAMS', response.data.teams);
          commit('SET_RUNNING_TEAMS', false);
          console.log('Teams should be set');

        }, (error) => {
          console.log(error);
        }); 
      }
    },

    async tables ({ commit, getters }){
      let isAlreadyRunning = getters.GET_RUNNING_TABLES;
      if( isAlreadyRunning == false ) {
        commit('SET_RUNNING_TABLES', true);
        let userId = getters['auth/user']['id'];
        await axios.post('/api/tables', {'id': userId })
        .then((response) => {
          console.log(response.data.tables);
          commit('SET_TABLES', response.data.tables);
          commit('SET_RUNNING_TABLES', false);

        }, (error) => {
          console.log(error);
        }); 
      }
    },

    async event ({ commit, getters}){
      let isAlreadyRunning = getters.GET_RUNNING_EVENT;
      if( isAlreadyRunning == false ) {
        commit('SET_RUNNING_EVENT', true);
        await axios.post('/api/event')
        .then((response) => {
          console.log(response.data.event);
          commit('SET_EVENT', response.data.event);
          commit('SET_RUNNING_EVENT', false);
          console.log('Event should be set');

        }, (error) => {
          console.log(error);
          commit('SET_RUNNING_EVENT', false);
        }); 
      }
    },

    async pay ({ commit, getters }, details){ // async - so we can use await
      /*
      *   returns a promise
      */

      let promise = await new Promise((resolve, reject) => { // wait for promise to resolve 
        let isAlreadyRunning = getters.GET_RUNNING_PAY;
        if( isAlreadyRunning == false ) {
          commit('SET_RUNNING_PAY', true);

          axios.post('/api/pay', details)
          .then((response) => {
            commit('SET_RUNNING_PAY', false);
            resolve(response.data.pay);
            

          }, (error) => {
            console.log(error);
            commit('SET_RUNNING_PAY', false);
            reject(error);
          }); 
        }
      })
      .catch(err => {throw err});
  
      return promise // specifically return promise resolution
    },

    async metric ({ commit, getters }, activity){ 
      let user_id = getters['auth/user']['id'];
      let details = {
        'user_id': user_id,
        'activity': activity 
      }

      axios.post('/api/metric', details); // No response required
    },

    async newTeam ({ state, commit, getters }){ 
      let user_id = getters['auth/user']['id'];

      // teams is now table specific
      let table_teams = state.teams.find(item => item.table_id == state.currentTableId);
      let users_team = table_teams.teams.find(item => item.user_id == user_id);
      
      let team_id = users_team.team_id;
      let p1 = users_team.player1Id;
      let p2 = users_team.player2Id;
      let p3 = users_team.player3Id;
      let p4 = users_team.player4Id;
      
      let details = {
        'team_id': team_id,
        'player1_id': p1,
        'player2_id': p2,
        'player3_id': p3,
        'player4_id': p4,
      }

      axios.post('/api/newTeam', details); // No response required
    },

    

  },
  modules: {
    auth
  }
})
