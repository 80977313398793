<template>
<!-- <div class="p-5 absolute items-center justify-center h-screen z-300"> -->
<div class="toast p-5 w-full">
   <div class="toast-content w-full text-center mt-6 rounded text-white p-4 text-sm focus:outline-none ">{{ toastText }}</div>
</div>
</template>

<script>
    export default {
        name: 'Toast',
        props: {
            toastText: String,
        },
}
</script>

<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .toast {
        margin: auto;
        position: fixed;
        top: 40%; left: 0; bottom: 0; right: 0;
    }

    .toast-content{
        background: rgba(255,140,79,0.7);
        background: linear-gradient(132deg, rgba(255,140,79,0.7) 15%, rgba(255,86,245,0.7) 100%);
    }


</style>