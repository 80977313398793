<template>
<div>
    <main-header />
    <div class="text-center mb-5">
            <h1>{{ event }}</h1>
            <p class="text-xs"></p>
    </div>  
    <div class="container">
        <summary-team-card/>
        <p></p>   
        <Button btn-text="CONFIRM TEAM" @click="confirmTeamClick"/>
    </div>
</div>
</template>

<script>

import Button from '@/components/Base/Button.vue';
import MainHeader from '@/components/Base/MainHeader.vue';
import SummaryTeamCard from '@/components/ClaimSeat/SummaryTeamCard.vue';

export default {
    components: { 
        MainHeader, 
        Button,
        SummaryTeamCard
    },
    name: "SummaryTeamConfirm",
    props: {
        event: String,
    },
    emits: ["confirmTeamEvent"],
    methods: {

        confirmTeamClick: function(){
            this.$emit("confirmTeamEvent");
        }
    }
}
</script>

<style scoped >
    p {
        margin-top: 10px;
        margin-bottom: 20px;
    }


</style>