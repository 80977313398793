<template>
<div>
    <main-header accountShow="true" @myAccountEvent="myAccountClick"/>
    <h1>{{ event }}</h1>
    <event-progress />

    <div class="mt-3">
        <span v-if="countTables >=2" :class="TableBtn1Active ? 'm-3 primary-gradient clip-text' : 'm-3'" @click="tableClick(1)" >R1 Table</span>
        <span v-if="countTables >=2">|</span>
        <span v-if="countTables >=2" :class="TableBtn2Active ? 'm-3 primary-gradient clip-text' : 'm-3'" @click="tableClick(2)" >R2 Table</span>
        <span v-if="countTables >=3">|</span>
        <span v-if="countTables >=3" :class="TableBtn3Active ? 'm-3 primary-gradient clip-text' : 'm-3'" @click="tableClick(3)" >R3 Table</span>
        <span v-if="countTables >=4">|</span>
        <span v-if="countTables >=4" :class="TableBtn4Active ? 'm-3 primary-gradient clip-text' : 'm-3'" @click="tableClick(4)" >R4 Table</span>
    </div>
    
    <table-event-content :starting_round="TableStartingRound" :pot="TablePot" :entry_fee="TableEntryFee" :buster="TableBuster" :winner="TableWinner"/>
   
    
</div>
</template>

<script>
import MainHeader from '../Base/MainHeader.vue'
import { mapState, mapActions } from 'vuex'
import TableEventContent from './Elements/TableEventContent.vue'
import EventProgress from '../Base/EventProgress.vue';

export default {
    components: { 
      MainHeader,
      TableEventContent, 
      EventProgress
    },

    name: "TableEventInfo",
    props:{
      event: String
    },
    emits: ["myAccountEvent"],

    mounted () {
      this.updateEventDetails();
    },
    
    computed: {
      ...mapState({
        eventStatus: state => state.eventStatus,
        tables: state => state.tables,
        currentTableId: state => state.currentTableId
      }),

      countTables(){
        return Object.keys(this.tables).length;
      },

      currentTableStartingRound(){
        let tableStartingRound = 1;
        this.tables.forEach(item => {
          if(item.id == this.currentTableId){
            tableStartingRound = item.starting_round;
          }
        });
        return tableStartingRound;
      },

       TableBtn1Active(){
        if(this.currentTableStartingRound == 1){
          return true;
        }
        else {
          return false;
        } 
      },

      TableBtn2Active(){
        if(this.currentTableStartingRound == 2){
           return true;
        }
        else {
          return false;
        } 
      },

      TableBtn3Active(){
        if(this.currentTableStartingRound == 3){
           return true;
        }
        else {
          return false;
        } 
      },

      TableBtn4Active(){
        if(this.currentTableStartingRound == 4){
           return true;
        }
        else {
          return false;
        } 
      },


      eventOver () {
          if(this.eventStatus == 'over'){
              return true;
          }
          else {
              return false;
          } 
      },
    },

    
    methods: {
      ...mapActions({
          writeCurrentTableIdAction: 'writeCurrentTableId'
        }),

      tableClick: function(round){
        // Get selected Table
        let selectedTableId = '';
        this.tables.forEach(item => {
          if(item.starting_round == round){
            selectedTableId = item.id;
          }
        });
        
        this.writeCurrentTableIdAction(selectedTableId);
        this.updateEventDetails();
        
      },

      updateEventDetails: function(){
        this.tables.forEach(item => {
          if(item.id == this.currentTableId){
            this.TableStartingRound = item.starting_round;
            this.TableEntryFee = item.entry_fee;
            this.TablePot = item.pot;
            this.TableBuster = item.buster;
            this.TableWinner = item.winner;
          }
        });
      },

      myAccountClick: function(){
        this.$emit("myAccountEvent", "eventInfo");
      }
    },

    data () {
      return {
        TableWinner: '',
        TableStartingRound: 1,
        TableEntryFee: 0,
        TableBuster: 0,
        TablePot: 0
      }
    },
}
</script>

<style scoped>



  
</style>