import axios from 'axios'

export default {

  namespaced: true,

  state: {
      authenticated: false,
      user: null,

      credentials_phone: null,
      credentials_code_1: null,
      credentials_code_2: null,
      credentials_code_3: null,
      credentials_code_4: null,
  },

  mutations: {
      SET_AUTHENTICATED (state, value){
          state.authenticated = value
      },
      SET_USER (state, data){
        state.user = data
      },
      SET_CREDENTIALS_PHONE (state, data){
        state.credentials_phone = data
      },

      SET_CREDENTIALS_CODE_1 (state, data){
        state.credentials_code_1 = data
      },

      SET_CREDENTIALS_CODE_2 (state, data){
        state.credentials_code_2 = data
      },

      SET_CREDENTIALS_CODE_3 (state, data){
        state.credentials_code_3 = data
      },

      SET_CREDENTIALS_CODE_4 (state, data){
        state.credentials_code_4 = data
      },
  },

  getters: {    
    authenticated (state) {
        return state.authenticated
    },
    user (state) {
        return state.user
    },

    credentials_phone (state){
        return state.credentials_phone
    },

    credentials_code_1 (state){
        return state.credentials_code_1
    },

    credentials_code_2 (state){
        return state.credentials_code_2
    },

    credentials_code_3 (state){
        return state.credentials_code_3
    },

    credentials_code_4 (state){
        return state.credentials_code_4
    },
  },

  actions: {

    async manualLogin ({ dispatch }, credentials){
        
        // handle breaking 401 unauthorised error - 
        // CORS affecting error status
        // const UNAUTHORIZED = 401;
        axios.interceptors.response.use(
            response => response,
            error => {
                location.reload();
                // const {status} = error.response;
                // if (status === UNAUTHORIZED) {
                //     location.reload();
                // }
                return Promise.reject(error);
            }
        );
        
        await axios.get('/sanctum/csrf-cookie');
        await axios.post('/api/login', credentials);
        location.reload();

    },

    async login ({ dispatch }, credentials){
        
        await axios.get('/sanctum/csrf-cookie');
        await axios.post('/api/login', credentials);

        return dispatch('me')

    },

    me ({ commit }) { 
        return axios.get('/api/user').then( response => {  
            commit('SET_AUTHENTICATED', true)
            commit('SET_USER', response.data)
            // console.log('authenticated: true');
            // console.log('user: '+ JSON.stringify(response.data));
           
        }).catch(() => {
            commit('SET_AUTHENTICATED', false)
            commit('SET_USER', null)
        })
    },

    set_credentials_phone({ commit }, newValue){
        commit("SET_CREDENTIALS_PHONE", newValue);
    },
    
    set_credentials_code_1({ commit }, newValue){
        commit("SET_CREDENTIALS_CODE_1", newValue);
    },

    set_credentials_code_2({ commit }, newValue){
        commit("SET_CREDENTIALS_CODE_2", newValue);
    },

    set_credentials_code_3({ commit }, newValue){
        commit("SET_CREDENTIALS_CODE_3", newValue);
    },

    set_credentials_code_4({ commit }, newValue){
        commit("SET_CREDENTIALS_CODE_4", newValue);
    },

  }

}
