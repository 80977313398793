<template>
<div>
    <main-header />
    <div class="text-center mb-5">
            <h1>{{ event }}</h1>
            <p class="text-xs"></p>
    </div>  
    <div class="container">
            <summary-team-card/>
            <Button v-if="!hasSeat" btn-text="SECURE YOUR SEAT" @click="secureSeat"/>
            <Button v-if="hasSeat" btn-text="UPDATE YOUR TEAM" @click="updateTeam"/>
    </div>
</div>
</template>

<script>

import Button from '../Base/Button.vue';
import MainHeader from '../Base/MainHeader.vue';
import SummaryTeamCard from '@/components/ClaimSeat/SummaryTeamCard.vue';
import { mapState } from 'vuex'

export default {
    components: { MainHeader, Button, SummaryTeamCard },
    name: "TeamConfirmed",
    props: {
        event: String,
        hasSeat: Boolean
    },
    emits: ["secureSeatEvent", "updateTeamEvent"],
    methods: {
        secureSeat: function(){
            this.$emit("secureSeatEvent");
        },

        updateTeam: function(){
            this.$emit("updateTeamEvent");
        },
    },
    computed: {
        ...mapState([
            'tables',
            'currentTableId'
        ]),

        entry_fee (){
            // Get Table by currentTableId
            let entry_fee = '';
            this.tables.forEach(item => {
            if(item.id == this.currentTableId){
                entry_fee = item.entry_fee;
            }
            });
            return entry_fee;
        }
    }
    
}
</script>

<style scoped >
    p {
        margin-top: 10px;
        margin-bottom: 20px;
    }


</style>