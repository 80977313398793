<template>
<div>
    <main-header backShow="true" accountShow="false" @backEvent="backClick"/>
    <h1>My Account</h1>
    <div class="p-12">
        <input-field id="myAccountFirstName" placeholder="First Name" :value=name />
        <!-- <input-field id="myAccountLastName" placeholder="Last Name" /> -->
        <input-field id="myAccountMobile" placeholder="Mobile" :value=phone />
        <!-- <toggle />
        <Button btnText="UPDATE"/> -->
    </div>
</div>
</template>

<script>

import InputField from '../Base/InputField.vue'
import Button from '../Base/Button.vue'
import Toggle from '../Base/Toggle.vue'
import MainHeader from '../Base/MainHeader.vue'
import { mapState } from 'vuex'

export default {
    name: 'MyAccount',
    components: {
        InputField,
        Button,
        Toggle,
        MainHeader
    },
    emits: ["backEvent"],
    computed: mapState({
        name: state => state.auth.user.name,
        phone: state => state.auth.user.phone
    }),
    methods: {
        backClick: function(){
            this.$emit("backEvent");
        }
    }
}
</script>

,
        TableHeader<style>

</style>