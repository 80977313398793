<template>
<div>
  <main-header accountShow="true" @myAccountEvent="myAccountClick"/>
  <div class="mb-20">
      <h1>{{ event }}</h1>
      <event-progress />

      <div class="mt-3">
        <span v-if="countTables >=2" :class="TableBtn1Active ? 'm-3 primary-gradient clip-text' : 'm-3'" @click="tableClick(1)" >R1 Table</span>
        <span v-if="countTables >=2">|</span>
        <span v-if="countTables >=2" :class="TableBtn2Active ? 'm-3 primary-gradient clip-text' : 'm-3'" @click="tableClick(2)" >R2 Table</span>
        <span v-if="countTables >=3">|</span>
        <span v-if="countTables >=3" :class="TableBtn3Active ? 'm-3 primary-gradient clip-text' : 'm-3'" @click="tableClick(3)" >R3 Table</span>
        <span v-if="countTables >=4">|</span>
        <span v-if="countTables >=4" :class="TableBtn4Active ? 'm-3 primary-gradient clip-text' : 'm-3'" @click="tableClick(4)" >R4 Table</span>
      </div>

      <template v-if="teamsShow && !emptyTeams"> 
        <TeamListCard v-for="team in liveTableTeams" 
          :key="team.id" 
          :name="team.name" 
          :userId="team.user_id"
          :position="team.position" 
          :shield="team.shield" 
          :owgr="team.owgrBusted" 
          :sup="team.sup " 
          :total="team.total" 
          :player1img="team.player1img" 
          :player1Id="team.player1Id" 
          :player2img="team.player2img" 
          :player2Id="team.player2Id" 
          :player3img="team.player3img" 
          :player3Id="team.player3Id" 
          :player4img="team.player4img" 
          :player4Id="team.player4Id" 
          :live="true"
          :notStarted="notStarted"

          @playerClick="playerClick"
          @editTeamClick="editTeamClick"
        />
        <div class="spacer w-full h-5"></div>

        <div v-if="withdrawnTableTeams.length || cutTableTeams.length" class="w-full mt-10 grid grid-cols-9 p-4">
          <div class="col-span-1"></div>
          <div class="col-span-7 text-center" >
            <h2>CUT TEAMS</h2>
          </div>
          <div @click="withdrawnInfoClick" class="icon col-span-1">
              <svg class="staticIcon w-6 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g data-name="Layer 2">
                  <g data-name="alert-circle">
                    <rect width="24" height="24" opacity="0"/>
                    <path fill="url(#table-gradient)" d="M12 2a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"/>
                    <circle fill="url(#table-gradient)" cx="12" cy="16" r="1"/>
                    <path fill="url(#table-gradient)" d="M12 7a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0V8a1 1 0 0 0-1-1z"/></g></g></svg>
          </div>
        </div>

        <transition name="fade">
            <div v-if="showWithdrawnInfoText" class="transition w-full text-center mb-4" >
              <p class="text-xs">The teams below cannot now win because they include players that have either withdrawn or missed the cut.</p>
            </div>
        </transition>

        <TeamListCard v-for="team in withdrawnTableTeams" 
          :key="team.id" 
          :name="team.name" 
          :userId="team.user_id"
          :position="team.position" 
          :shield="team.shield" 
          :owgr="team.owgrBusted" 
          :sup="team.sup " 
          :total="team.total" 
          :player1img="team.player1img" 
          :player1Id="team.player1Id" 
          :player2img="team.player2img" 
          :player2Id="team.player2Id" 
          :player3img="team.player3img" 
          :player3Id="team.player3Id" 
          :player4img="team.player4img" 
          :player4Id="team.player4Id" 
          :live="false"
          :notStarted="notStarted"
          teamStatus="withdrawn"

          @playerClick="playerClick"
          @editTeamClick="editTeamClick"
        />

        <TeamListCard v-for="team in cutTableTeams" 
          :key="team.id" 
          :name="team.name" 
          :userId="team.user_id"
          :position="team.position" 
          :shield="team.shield" 
          :owgr="team.owgrBusted" 
          :sup="team.sup " 
          :total="team.total" 
          :player1img="team.player1img" 
          :player1Id="team.player1Id" 
          :player2img="team.player2img" 
          :player2Id="team.player2Id" 
          :player3img="team.player3img" 
          :player3Id="team.player3Id" 
          :player4img="team.player4img" 
          :player4Id="team.player4Id" 
          :live="false"
          :notStarted="notStarted"
          teamStatus="missedCut"

          @playerClick="playerClick"
          @editTeamClick="editTeamClick"
        />

        


      </template>

      
      
      <waiting-for-teams v-if="teamsShow && emptyTeams"/>
      <Button  class="mt-10 mb-10" :key="claimSeatReRenderKey" v-if="!hasSeat && notStarted" @click="claimSeatClick" btn-text="SEATS AVAILABLE - CLAIM SEAT"/>
      
  </div>
</div>
</template>

<script>

import TeamListCard from '@/components/Table/Elements/TeamListCard.vue'
import WaitingForTeams from './Elements/WaitingForTeams.vue';
import MainHeader from '../Base/MainHeader.vue';
import Button from '../Base/Button.vue';
import EventProgress from '../Base/EventProgress.vue';

import { mapActions, mapState, mapGetters} from 'vuex'


export default {
    name: "TableTeamBoard",
    components: {
        TeamListCard,
        WaitingForTeams,
        MainHeader,
        Button,
        EventProgress
    },
    props:{
      event: String,
    },

    mounted (){
      console.log('HAS SEAT - '+this.hasSeat);
      console.log('HAS SEAT - '+this.hasSeat);
      
      this.claimSeatReRenderKey += 1;
      
    },
    computed: {
      ...mapState({
        eventDetails: state => state.event,
        teams: state => state.teams,
        tables: state => state.tables,
        eventStatus: state => state.eventStatus,
        currentTableId: state => state.currentTableId,
      }),

      ...mapGetters([
        'GET_HASSEAT_BY_TABLE'
      ]),

      hasSeat(){
       return this.GET_HASSEAT_BY_TABLE(this.currentTableId);
      },

      currentTableStartingRound(){
        let tableStartingRound = 1;
        this.tables.forEach(item => {
          if(item.id == this.currentTableId){
            tableStartingRound = item.starting_round;
          }
        });
        return tableStartingRound;
      },

      notStarted(){

        if(this.eventStatus == 'ready'){ // by event status
          return true;
        }
        else { // by start time of next round
          // Get this tables starting round
          let tableStartingRound = this.currentTableStartingRound;

          // Get this events start time for that round
          // let table_round_start = this.eventDetails.round1_start;
          let table_round_start = eval("this.eventDetails.round"+tableStartingRound+"_start");
          console.log('table_round_start - '+table_round_start);
          

          // if we dont have a start time yet - because teetimes have not been provided - return true
          if (!table_round_start || table_round_start.length < 1){
            return true;
          }

          let now = new Date();
          let tableStart = this.makeNewDate(table_round_start);

          console.log('TableTeamBoard - tableStart time - '+ tableStart);
          


          if ( now < tableStart ){
              return true;
          }
          else {
              return false;
          }
        }
        

      },

      countTables(){
        return Object.keys(this.tables).length;
      },

      TableBtn1Active(){
        if(this.currentTableStartingRound == 1){
          return true;
        }
        else {
          return false;
        } 
      },

      TableBtn2Active(){
        if(this.currentTableStartingRound == 2){
           return true;
        }
        else {
          return false;
        } 
      },

      TableBtn3Active(){
        if(this.currentTableStartingRound == 3){
           return true;
        }
        else {
          return false;
        } 
      },

      TableBtn4Active(){
        if(this.currentTableStartingRound == 4){
           return true;
        }
        else {
          return false;
        } 
      },

      TableTeams(){
        // filter by current Table Id
        let currentTable = this.teams.filter((item) => {
            if(item.table_id == this.currentTableId){
              return true;
            } 
            else {
              return false;
            }
         });

        // Get first of filtered
         return currentTable[0].teams;
      },

      liveTableTeams(){
        return this.TableTeams.filter(i => (i.status != 'withdrawn' && i.status != 'cut' )); 
      },

      cutTableTeams(){
        return this.TableTeams.filter(i => i.status == 'cut' ); 
      },

      withdrawnTableTeams(){
        return this.TableTeams.filter(i => i.status == 'withdrawn' ); 
      },

      emptyTeams() {
          let teams = this.teams;
          if( !teams || Object.keys(teams).length === 0){
            return true;
          }
          else {
            return false;
          }
      }

    },

    emits: ["myAccountEvent", "playerEvent", "claimSeatEvent", "editTeamEvent"],

    methods: {
        ...mapActions({
          writeCurrentTableIdAction: 'writeCurrentTableId'
        }),

        withdrawnInfoClick: function(){
          this.showWithdrawnInfoText = !this.showWithdrawnInfoText;
        },

        cutInfoClick: function(){
          this.showCutInfoText = !this.showCutInfoText;
        },

        tableClick: function(round){
          // Get selected Table
          let selectedTableId = '';
          this.tables.forEach(item => {
            if(item.starting_round == round){
              selectedTableId = item.id;
            }
          });
          
          this.writeCurrentTableIdAction(selectedTableId);
          
        },

        playerClick: function(playerId){
          let originator = "teamBoard";
          this.$emit("playerEvent", { playerId, originator });
        },

        editTeamClick: function(playerId){
          this.$emit("editTeamEvent");
        },

        myAccountClick: function(){
          let originator = "teamBoard";
          this.$emit("myAccountEvent", originator);
        },

        claimSeatClick: function(){
          this.$emit("claimSeatEvent");
        },

        makeNewDate: function(dateTimeString) {
            // *** Fix Safari Date() compatability ***
            // recieved format 2021-11-18 14:30:00
            // this.makeNewDate format yyyy, mm-1, dd, hh, mm, ss
            // returns newDate from string
            if(dateTimeString){
                if (typeof dateTimeString === 'string' || dateTimeString instanceof String){
                    if(dateTimeString.includes("-")){
                        // process date
                        console.log('dateTimeString sent to makeNewDate - '+dateTimeString);
                    
                        const dateArray = dateTimeString.split("-");
                        const timeDateArray = dateArray[2].split(" ");
                        const timeArray = timeDateArray[1].split(":");
                        
                        let yyyy = dateArray[0];
                        let month = dateArray[1];
                        let monthLessOne = parseInt(month) -1;
                        let dd = timeDateArray[0];
                        let hh = timeArray[0];
                        let mm = timeArray[1];
                        let ss = timeArray[2];

                        console.log('Sent to new Date - ln 364 - '+yyyy+','+monthLessOne+','+dd+','+hh+','+mm+','+ss);
                        
                        return new Date(yyyy, monthLessOne, dd, hh, mm, ss);
                    }
                    else {
                        // return now
                        return new Date();
                    }
                }
                else {
                    // return now
                    return new Date();
                }
            }
            else {
                // return now
                return new Date();
            }

        },

    },
    data () {
      return {
        headerBackShow: false,
        teamsShow: true,
        playerPerformanceCardShow: false,
        showCutInfoText: false,
        showWithdrawnInfoText: false,
        claimSeatReRenderKey: 0
      }
    },

}
</script>

<style scoped>
svg {
  transform: rotate(180deg);
}
</style>
