<template>
<div>
    <main-header />
    <div class="text-center mb-5">
            <h1>{{ event }}</h1>
            <p class="text-sm mb-10">PAYMENT</p>
            <h2 class="fee">£{{ entry_fee }}.00</h2>
    </div>  
    <div class="p-5">
            <div class="mb-6">
                <label for="name" class="inline-block text-xs mb-2">YOUR NAME</label>
                <input type="text" id="name" class="w-full border-2 border-gray-200 h-10 px-3 rounded-md" >
            </div>
            <div class="mb-6">
                <label for="card" class="inline-block text-xs mb-2">CARD DETAILS</label>
                <div id="card" ref="card"></div>
            </div>
            <div class="mb-6 text-red-400" id="card-errors" ref="card-errors"></div>
            <!-- <button type="submit">Pay £{{ entry_fee }}</button> -->
            <Button btn-text="PAY NOW" @click="pay"></Button>

    </div>
    
</div>
</template>

<script>

import Button from '../Base/Button.vue';
import MainHeader from '../Base/MainHeader.vue';
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
    name: "PaymentTake",
    components: {
        Button,
        MainHeader
    },
    props: {
        event: String
    },
    emits: ["payEvent"],
    computed: {
        ...mapState({
            user_id: state => state.auth.user.id,
            tables: state => state.tables,
            currentTableId: state => state.currentTableId
        }),

        entry_fee(){
            // Get Table by currentTableId
            let entry_fee = '';
            this.tables.forEach(item => {
                if(item.id == this.currentTableId){
                    entry_fee = item.entry_fee;
                }
            });
            return entry_fee;
        },

    },
    data () {
        return {
            stripe: '',
            cardElement: '',
            stripeToken: '',
            payBtnActive: true
        }
    },

    methods:{

        ...mapActions({
            payAction: 'pay'
        }),

        ...mapGetters({
            teamGetter: 'GET_ENTRYLIST_BY_SELECTED',
            entrylist: 'GET_ENTRYLIST'
        }),

        pay: function(){
            

            if(this.payBtnActive) {
                // de-activate payment btn to prevent duplicate processing
                this.payBtnActive = false;

                // create Stripe Token with options
                let self = this;

                let options = {
                    name: document.getElementById('name').value,
                    // user_id: this.user_id
                }
                this.stripe.createToken(this.cardElement, options).then(function(result) {
                    if (result.error){
                        //Inform the user if there was an error
                        const errorElement = document.getElementById('card-errors');
                        errorElement.textContent = result.error.message;

                        // re-activate payment btn to so user can retry
                        this.payBtnActive = true;
                    }
                    else {
                        
                        self.stripeToken = result.token.id;
                        self.takePayment();
                        console.log('Stripe Token from card element - '+ JSON.stringify(result.token));

                    }
                    
                    
                });
            }
        },

        takePayment: function (){

            let that = this;
            // get team
            let team = this.teamGetter();

            // just ids from players in selected team
            let team_ids = new Array;
            team.forEach(element => {
                team_ids.push(element.id);
            });
            console.log('teamGetter - '+team_ids);
            
            // Send details with stripeToken to Laravel
            let details = {
                stripeToken: this.stripeToken,
                entry_fee: this.entry_fee,
                user_id: this.user_id,
                table_id: this.currentTableId,
                team: team_ids
            }
            this.payAction(details).then(function(result) {
                that.$emit("payEvent", result);
            });
            
            
        }
    },

    mounted() {
        this.stripe = Stripe(process.env.VUE_APP_STRIPE_KEY);
        
        const elements = this.stripe.elements();
        var style = {
            base: {
                // Add your base input styles here. For example:
                fontSize: '16px',
                color: '#ff588d'
            },
        };
        this.cardElement = elements.create('card', {style: style});
        this.cardElement.mount(this.$refs.card)
    }
}
</script>

<style scoped>
    h2.fee {
        display: block;
        font-size: 50px;
    }

</style>