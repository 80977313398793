<template>
<div>
        <main-header />
        <div class="text-center mb-5">
            <h1>{{ event }}</h1>
            <p v-if="!isScoreBusterTable" class="text-xs">Select 4 players with a combined OWGR above {{ tableBuster }}.</p>
            <p v-if="isScoreBusterTable && starting_round < 4" class="text-xs">Select 4 players before the start of round {{ starting_round }} with a combined score no better than {{ tableBuster +1 }}.</p>
            <p v-if="isScoreBusterTable && starting_round == 4" class="text-xs">Select 4 players before the start of the final round with a combined score no better than {{ tableBuster +1 }}.</p>

        </div>  
        <div class="bg-white rounded-md w-full shadow-md  p-5" >
            <div class="players grid grid-cols-4 gap-3 mt-3">
                <player-list-card v-for="player in players" class="unselected"
                    :key="player.id"
                    :image="player.photo" 
                    :playerId="player.id"
                    :selected="player.selected"
                    :owgr="player.owgr"
                    badge="true"
                    :isScoreBusterTable="isScoreBusterTable"
                    
                    @playerClick="playerClick"    
                />
            </div>
            <div v-if="!isScoreBusterTable" >
                <Button v-if="teamSlotsLeft == 0 && BusterLeftSoFar < 0" btn-text="VIEW TEAM" @click="confirmTeamClick" />
            </div>
            <div v-if="isScoreBusterTable" >
                <Button v-if="teamSlotsLeft == 0 && BusterLeftSoFar > 0" btn-text="VIEW TEAM" @click="confirmTeamClick" />
            </div>
                <!-- <h2 class="text-gray-400 mt-6 mb-2 text-lg">Testing</h2>
                <h2 class="text-gray-400">Buster : {{ buster }}</h2>
                <h2 class="text-gray-400">BusterLeftSoFar : {{ BusterLeftSoFar }}</h2>
                <h2 class="text-gray-400">teamSlotsLeft : {{ teamSlotsLeft }}</h2> -->
        </div>
</div>
</template>

<script>
import Button from '../Base/Button.vue';
import MainHeader from '../Base/MainHeader.vue';

import PlayerListCard from '../Base/PlayerListCard.vue'
import { mapState, mapGetters } from 'vuex'


export default {
    name: 'PlayerSelector',
    components: {
        PlayerListCard,
        Button,
        MainHeader
    },
    props: {
        event: String,
        players: Array,
        isScoreBusterTable: Boolean,
        tableBuster: String,
        hasSeat: Boolean
    },
    emits: ["playerEvent","confirmTeamEvent"],
    computed: {

        ...mapGetters([
            'GET_ENTRYLIST_BY_SELECTED'
        ]),

        ...mapState({
            tables: state => state.tables,
            leaderBoard: state => state.leaderBoard,
            currentTableId: state => state.currentTableId
        }),


        BusterLeftSoFar: function() {
            let teamRankOrScoreTotal = 0;
            let isScoreBuster = this.isScoreBusterTable;
            let leaderBoard = this.leaderBoard;
            let selected = this.GET_ENTRYLIST_BY_SELECTED;
            if(selected.length > 0){
                selected.forEach(item => {
                    if(isScoreBuster){
                        let playerLeaderBoard = leaderBoard.find(x => x.player_id === item.id);
                        if(playerLeaderBoard){
                            let score = playerLeaderBoard.total_score;
                            teamRankOrScoreTotal += parseInt(score);
                        }
                    }
                    else {
                        teamRankOrScoreTotal += parseInt(item.owgr);
                    }
                });
            }

            if(isScoreBuster){
                return (teamRankOrScoreTotal - this.buster);
            }
            else {
                return -1*(teamRankOrScoreTotal - this.buster);
            }
            
            
            // return total;
        },

        teamSlotsLeft: function() {
            let selected = this.GET_ENTRYLIST_BY_SELECTED;
            let slotsTaken = selected.length;
            let totalSlots = 4;
            
            return totalSlots - slotsTaken;
        },

        buster (){
            // filter by current Table Id
            let currentTable = this.tables.filter((item) => {
                if(item.id == this.currentTableId){
                return true;
                } 
                else {
                return false;
                }
            });

            // Get first of filtered
            return currentTable[0].buster;
        },

        starting_round(){
            // Get Table by currentTableId
            let round = 0;
            this.tables.forEach(item => {
                if(item.id == this.currentTableId){
                    console.log("Table found - "+item.starting_round);
                    round = item.starting_round;
                }
            });

            return round;
        },

        isScoreBusterTable(){
            // Get Table by currentTableId
            console.log("THIS TABLE STARTING ROUND = "+ this.starting_round);
            let tableStartingRound = '';
            this.tables.forEach(item => {
            if(item.id == this.currentTableId){
                tableStartingRound = item.starting_round;
            }
            });
            if(tableStartingRound == 1){
            return false;
            }
            else{
            return true;
            }
        },
    },

    methods: {
        playerClick: function(playerId){
          this.$emit("playerEvent", playerId);
        },
        confirmTeamClick: function(){
            this.$emit("confirmTeamEvent");
        }
    },

    

}
</script>

<style>

</style>