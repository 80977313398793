<template>
<div class="relative p-0">
  <Badge v-if="isScoreBusterTable" :badgeValue=score />  
  <Badge v-if="!isScoreBusterTable" :badgeValue=owgr />
  <img @Click="playerClick" class="w-10 rounded PlayerListCardImg" :class="{'selectedPlayer': selected }" :src="image" :id="'playerListCardId'+ playerId + ''" alt="">
</div> 
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Badge from '../Base/Badge.vue';

export default {
    name: "PlayerSummaryCard",
    emits: ["playerClick"],
    components: {
        Badge
    },
    props: {
        image: String,
        playerId: Number,
        owgr: String,
        isScoreBusterTable: Boolean,
        selected: Boolean
    },
    computed: {
        ...mapState([
            'leaderBoard'
        ]),

        ...mapGetters([
            'GET_LEADERBOARD_BY_PLAYER'
        ]),

        score(){
            let board = this.GET_LEADERBOARD_BY_PLAYER(this.playerId);
            if(!board[0]){
                return "E";
            }
            let score =  board[0].total_score;
            if(score > 0) { return "+" + score;}
            if(score == 0 ) { return "E";}
            if(score < 0) { return score;}
            
        },

        thruProcessed(){

            let board = this.GET_LEADERBOARD_BY_PLAYER(this.playerId);
            if(!board[0]){
                return false;
            }
            let thru = board[0].total_thru;
            
            // If finished round - performances exist with NULL scores if not happend 
            let performedThisRound = false;
            this.performances.forEach(item => {
                if (item['score']){
                    performedThisRound = true;
                }
            });
            if(performedThisRound && thru < 1){
                return 'F';
            }
            else if (thru > 0 ){
                return thru;
            }
            else {
                return 0;
            }
        },

        performances(){
            return this.GET_PERFORMANCES_BY_PLAYER_AND_ROUND()(this.playerId)(this.lastRoundBegun);
        },
    },

    methods: {
        ...mapGetters([
            'GET_PERFORMANCES_BY_PLAYER_AND_ROUND',
            'GET_TEETIME_BY_PLAYER_AND_ROUND'
        ]),

        playerClick: function(){
            this.$emit('playerClick', this.playerId);
        }
    }
    
}
</script>

<style scoped>
    img {

        /* heavy blue  */
        /* filter: grayscale(1.0) brightness(1) sepia(1) hue-rotate(170deg) saturate(2); */

        /* gentle blue  */
        /* filter: grayscale(1.0) brightness(1.3) sepia(1) hue-rotate(170deg) saturate(.8); */

        /* grey */
        filter: brightness(1) ;

        opacity: 0.7;
        object-fit: cover;
    }

    img.unselected{
        filter: grayscale(1.0) brightness(1) ;
        opacity: .5;
    }

    img.selectedPlayer{
              /* gentle blue  */
        /* filter: grayscale(1.0) brightness(1) sepia(1) hue-rotate(170deg) saturate(3); */
        filter: brightness(1);
        opacity: 0.7;

    }

</style>