<template>
<div>
    <main-header backShow="true" backView="PlayerSelector" @backEvent="back"/>
    <div class="text-center mb-5">
            <h1>{{ event }}</h1>
            <p class="text-xs"></p>
    </div>  

    <div class="container">
        <div class="bg-white rounded-md w-full shadow-md p-3" >
            <div class="grid grid-cols-2 p-0">
                    <div class="w-full p-2 pr-5 pb-5">
                        <img class="rounded-md w-20" :src="playerData.photo" alt="">
                    </div>
                    <div class="w-full text-left p-3 pl-1">
                        <h2 class="font-bold mb-3 primary-gradient clip-text">{{ playerData.name }}</h2>
                        <p class="mb-2 text-xs"><strong>Born: </strong> {{ player_born }}</p>
                        <p class="mb-2 text-xs"><strong>Age: </strong> {{ player_age }}</p>
                        <p class="mb-2 text-xs"><strong>Nationality: </strong>{{ playerData.country }}</p>
                        <p class="mb-2 text-xs"><strong>OWGR: </strong> {{playerData.owgr}} </p>
                        <p v-if="isScoreBusterTable" class="mb-2 text-xs"><strong>Total Score: </strong> {{ player_score }} </p>
                    </div>
            </div>
            
            <!-- slots left -->
            <div v-if="!playerData.selected && teamSlotsLeft > 0">
                <div v-if="isScoreBusterTable">
                    <!-- If last slot - slow only if within buster -->
                    <Button v-if="teamSlotsLeft > 1 || (teamSlotsLeft == 1 && BusterLeftAfterAdded <= 0)" btn-text="ADD PLAYER TO YOUR TEAM" @click="playerToTeamClick"/>
                </div>
                <div v-if="!isScoreBusterTable">
                    <!-- If last slot - slow only if within buster -->
                    <Button v-if="teamSlotsLeft > 1 || (teamSlotsLeft == 1 && BusterLeftAfterAdded <= 0)" btn-text="ADD PLAYER TO YOUR TEAM" @click="playerToTeamClick"/>
                </div>
                
            </div>
            
            <Button v-if="playerData.selected" btn-text="REMOVE PLAYER FROM YOUR TEAM" @click="playerFromTeamClick"/>

        </div>

        <div v-if="!playerData.selected" class="bg-white rounded-md w-full p-3" >
            <div class="w-full text-left p-3 pl-1">
                <h2 class="font-bold text-lg mb-3">Your team so far...</h2>
                        <h2 v-if="teamSlotsLeft > 1 ">    
                            You have
                            <span class="font-bold primary-gradient clip-text">{{ teamSlotsLeft }}</span>
                            <span v-if="teamSlotsLeft == 1"> slot left </span>
                            <span v-if="teamSlotsLeft > 1 && teamSlotsLeft < 4"> slots left </span>
                            <span v-if="teamSlotsLeft == 4"> slots </span>
                            to fill. 
                        </h2>

                        <h2 v-if="teamSlotsLeft == 0 ">
                            You already have a full team. Go back and remove a player if you want to add <span class="font-bold primary-gradient clip-text">{{ playerData.name }}</span> to your team. 
                        </h2>

                        <div v-if="isScoreBusterTable">
                            <h2 v-if="teamSlotsLeft == 1 && BusterLeftAfterAdded <= 0 && !playerData.selected">    
                                You have <span class="font-bold primary-gradient clip-text">1</span> slot left 
                                to fill and you have enough room for <span class="font-bold primary-gradient clip-text">{{ playerData.name }}</span>.
                            </h2>

                            <h2 v-if="teamSlotsLeft == 1 && BusterLeftAfterAdded > 0 && !playerData.selected">    
                                You have <span class="font-bold primary-gradient clip-text">1</span> slot left 
                                to fill but there's not enough room for <span class="font-bold primary-gradient clip-text">{{ playerData.name }}</span>. Your final player must have 
                                <span>a score no better than </span>
                                <span v-if="BusterLeftSoFar < 0">{{ BusterLeftSoFar }}.</span>
                                <span v-if="BusterLeftSoFar > 0">+{{ BusterLeftSoFar }}.</span>
                                <span v-if="BusterLeftSoFar == 0"> E.</span>
                            </h2>
                        </div>
                        <div v-if="!isScoreBusterTable">
                            <h2 v-if="teamSlotsLeft == 1 && BusterLeftAfterAdded <= 0 && !playerData.selected">    
                                You have <span class="font-bold primary-gradient clip-text">1</span> slot left 
                                to fill and you have enough room for <span class="font-bold primary-gradient clip-text">{{ playerData.name }}</span>.
                            </h2>

                            <h2 v-if="teamSlotsLeft == 1 && BusterLeftAfterAdded > 0 && !playerData.selected">    
                                You have <span class="font-bold primary-gradient clip-text">1</span> slot left 
                                to fill but there's not enough room for <span class="font-bold primary-gradient clip-text">{{ playerData.name }}</span> 
                                . Your final player must have 
                                <span> an OWGR above </span>
                                <span v-if="BusterLeftSoFar != 0">{{ BusterLeftSoFar - 1 }}.</span>
                            </h2>
                        </div>
                        
<!-- 
                        <h2 class="text-gray-400 mt-6 mb-2 text-lg">Testing</h2>
                        <h2 class="text-gray-400">Buster : {{ buster }}</h2>
                        <h2 class="text-gray-400">BusterLeftSoFar : {{ BusterLeftSoFar }}</h2>
                        <h2 class="text-gray-400">BusterLeftAfterAdded : {{ BusterLeftAfterAdded }}</h2>
                        <h2 class="text-gray-400">BusterLeftAfterRemoved : {{ BusterLeftAfterRemoved }}</h2>
                        <h2 class="text-gray-400">teamSlotsLeftAfterRemoved : {{ teamSlotsLeftAfterRemoved }}</h2> -->
                        
                        
            </div>
        </div>
    </div>
</div>
</template>

<script>

import Button from '../Base/Button.vue';
import MainHeader from '../Base/MainHeader.vue';
import { mapState, mapGetters } from 'vuex'

export default {
    components: { MainHeader, Button },
    name: "PlayerDetailsCard",
    props: {
        playerData: Object,
        event: String,
    },
    computed: {
        ...mapState({
            players: state => state.entrylist,
            teams: state => state.teams,
            currentEvent: state => state.event.name,
            tables: state => state.tables,
            leaderBoard: state => state.leaderBoard,
            currentTableId: state => state.currentTableId
        }),

        ...mapGetters([
            'GET_ENTRYLIST_BY_SELECTED'
        ]),

        player_score(){
            var score = this.playerData.total_score;
            if(score == 0){
                return "E";
            }
            else {
                return score;
            }
        },

        player_born(){
            var birthDate = new Date(this.playerData.birth_date);
            var month = birthDate.toLocaleString('default', { month: 'short' })
            var year = birthDate.getFullYear();
            
            return month+' '+year;
        },

        player_age(){
            var today = new Date();
            var birthDate = new Date(this.playerData.birth_date);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            return age;
        },

        buster (){
            // filter by current Table Id
            let currentTable = this.tables.filter((item) => {
                if(item.id == this.currentTableId){
                return true;
                } 
                else {
                return false;
                }
            });

            // Get first of filtered
            return currentTable[0].buster;
        },

        isScoreBusterTable(){
            // Get Table by currentTableId
            let tableStartingRound = '';
            this.tables.forEach(item => {
            if(item.id == this.currentTableId){
                tableStartingRound = item.starting_round;
            }
            });
            if(tableStartingRound == 1){
            return false;
            }
            else{
            return true;
            }
        },

        BusterLeftSoFar: function() {
            let teamRankOrScoreTotal = 0;
            let isScoreBuster = this.isScoreBusterTable;
            let leaderBoard = this.leaderBoard;
            let selected = this.GET_ENTRYLIST_BY_SELECTED;
            if(selected.length > 0){
                selected.forEach(item => {
                    if(isScoreBuster){
                        let playerLeaderBoard = leaderBoard.find(x => x.player_id === item.id);
                        if(playerLeaderBoard){
                            let score = playerLeaderBoard.total_score;
                            teamRankOrScoreTotal += parseInt(score);
                        }
                        
                    }
                    else {
                        teamRankOrScoreTotal += parseInt(item.owgr);
                    }
                });
            }
            
            return (-1*(teamRankOrScoreTotal - this.buster))+1; // +1 so below and not equal to buster
            // return total;
        },

        BusterLeftAfterAdded: function(){
            if(this.isScoreBusterTable){
                return this.BusterLeftSoFar - parseInt(this.playerData.total_score);
            } else {
                return this.BusterLeftSoFar - parseInt(this.playerData.owgr);
            }
        },

        BusterLeftAfterRemoved: function(){
            if(this.isScoreBusterTable){
                return this.BusterLeftSoFar + parseInt(this.playerData.total_score);
            } else {
                return this.BusterLeftSoFar + parseInt(this.playerData.owgr);
            }
        },

        

        teamSlotsLeft: function() {
            let selected = this.GET_ENTRYLIST_BY_SELECTED;
            let slotsTaken = selected.length;
            let totalSlots = 4;
            
            return totalSlots - slotsTaken;
        },

        teamSlotsLeftAfterAdded: function() {
            return this.teamSlotsLeft -1;
        },

        teamSlotsLeftAfterRemoved: function() {
            return this.teamSlotsLeft +1;
        }
    
    },
    emits: ["playerToTeamEvent", "playerFromTeamEvent", "back"],
    methods: {
        playerToTeamClick: function(){
            this.$emit("playerToTeamEvent", this.playerData.id );
        },

        playerFromTeamClick: function(){
            this.$emit("playerFromTeamEvent", this.playerData.id );
        },

        back: function(){
            this.$emit("back");
            console.log('currently selected - '+JSON.stringify(this.GET_ENTRYLIST_BY_SELECTED));
            
        }
    }
}
</script>

<style scoped >

img {

            /* heavy blue  */
            /* filter: grayscale(1.0) brightness(1) sepia(1) hue-rotate(170deg) saturate(2); */

            /* gentle blue  */
            /* filter: grayscale(1.0) brightness(1.1) sepia(1) hue-rotate(170deg) saturate(.8); */

            /* grey */
            /* filter: grayscale(1.0) brightness(1) ; */

            /* colour faded */
            filter: brightness(1) ;

            /* Low saturation */
            /* filter: saturate(.8);  */

            opacity: .5;
        }

</style>