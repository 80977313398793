<template>
<div class="rounded-full text-gray-600 w-full mt-6 p-2 text-sm border border-white border-solid bg-white grid grid-cols-6" >
    <div class="col-span-5 text-left p-2 text-xs" >Next Event</div>
    <div class="flex items-center justify-center w-full pr-4">
        <label for="toggle" class="flex items-center cursor-pointer">
            <!-- toggle -->
            <div class="relative">
            <!-- input -->
            <input type="checkbox" id="toggle" class="sr-only" checked>
            <!-- line -->
            <div class="block bg-gray-300 w-12 h-7 rounded-full"></div>
            <!-- dot -->
            <div class="dot absolute left-1 top-1 bg-white w-5 h-5 rounded-full transition"></div>
            </div>
        </label>
    </div>
</div>



</template>

<script>
export default {
    name: 'Toggle',
}
</script>

<style scoped>

input:checked ~ .dot {
  transform: translateX(100%);
}

input:checked ~ .block {
  background: rgb(255,140,79);
        background: linear-gradient(132deg, rgba(255,140,79,1) 15%, rgba(255,86,245,1) 100%);
}

</style>