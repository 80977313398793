<template>
    <button class=" primary-gradient mt-6 w-full rounded-full  text-white px-6 py-3 text-xs focus:outline-none; ">{{ btnText }}</button>
</template>

<script>
export default {
    name: "Button",
    props: ['btnText']
}
</script>

<style>
    

</style>