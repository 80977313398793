<template>
   <div v-if="badgeValue" class="badgeContainer  absolute top-0 right-0 z-10 rounded-full" > 
    <div class="badge font-bold leading-none text-gray-800 bg-gray-100 rounded-full">
        <span class="badgeText">{{ badgeValue }}</span>
    </div>
   </div> 
  
</template>

<script>
export default {
    name: "Badge",
    props: ['badgeValue']
}
</script>

<style scoped>

.badgeContainer{
    padding: 1px;
    opacity: 0.8;
}

.badge{
    font-size: 9px;
    padding: 3px 4px 3px 4px;

    }

</style>