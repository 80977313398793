<template>
  <h1>Register</h1>
    <p>Register to recieve an alert in time to participate in the next event.</p>
    <InputField id="" placeholder="First name"/>
    <InputField id="" placeholder="Last name"/>
    <InputField id="" placeholder="Mobile"/>
    <p class="text-xs mt-5">By registering you agree to our Terms of service and privacy policy.</p>
    <Button @click="registerSubmitEvent" btn-text="SUBMIT" class="mb-10"/>
</template>

<script>
import InputField from '@/components/Base/InputField.vue'
import Button from '@/components/Base/Button.vue'

export default {
    name: 'Register',
    components: {
        InputField,
        Button
    },
    emits: ['registerSubmitEvent'],
    methods: {
        registerSubmitEvent: function (){
            this.$emit('registerSubmitEvent');
        }
    }
}
</script>

<style>

</style>