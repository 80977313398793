<template>
<div>
    <h1 class="text-4xl" >Welcome</h1>
    <!-- <p>Participation is by invitation only at the moment but you can register to receive an alert in time to participate at the next event. </p> -->
    <input type="text" id="Mobile" placeholder="Mobile" v-model="phone" class="mb-10"/>
    <Button @click="loginClickEvent" btn-text="LOGIN" class="mb-0"/>
    <!-- <Button @click="registerClickEvent" btn-text="REGISTER" class="mb-10"/> -->
</div>
</template>

<script>

import InputField from '@/components/Base/InputField.vue'
import Button from '@/components/Base/Button.vue'

export default {
    name: 'Main',
    components: {
        InputField,
        Button
    },
    computed: {
        phone:{
            set(newValue){
                return this.$store.dispatch("auth/set_credentials_phone", newValue);
            },
            get(){
                return this.$store.getters["auth/credentials_phone"];
            }
        }
    },
    emits: ['loginClickEvent','registerClickEvent'],
    methods: {
        loginClickEvent: function (){
            this.$emit('loginClickEvent');
            console.log('VueX phone - '+this.$store.getters["auth/credentials_phone"]);
            
        },
        registerClickEvent: function (){
            this.$emit('registerClickEvent');
        }
    }
}



</script>

<style>

</style>