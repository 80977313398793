<template>
  <h1>Thank You</h1>
  <p>We will send you an alert in time for you to participate at the next event.</p>
</template>

<script>
export default {

}
</script>

<style>

</style>